import { styled, Switch } from '@mui/material'
import theme from '../../theme'

export const BlendSwitch = styled(Switch)({
  width: 20,
  height: 10,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    '&.Mui-checked': {
      transform: 'translateX(10px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 10,
    height: 10,
    borderRadius: 5,
    transition: theme.transitions.create(['width'], {
      duration: 100,
    }),
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.background.default,
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.primary.main}`,
  },
})

export const BlendLargeSwitch = styled(Switch)({
  width: 52,
  height: 26,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 30,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(18px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    '&.Mui-checked': {
      transform: 'translateX(26px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 26,
    height: 26,
    borderRadius: 13,
    transition: theme.transitions.create(['width'], {
      duration: 100,
    }),
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  '& .MuiSwitch-track': {
    borderRadius: 70 / 2,
    opacity: 1,
    backgroundColor: theme.palette.background.default,
    boxSizing: 'border-box',
    border: `2px solid ${theme.palette.primary.main}`,
  },
})
