import { OptionExt } from '../components/Questionnaire/multipleChoice'

export interface MultipleChoiceAction {
  responseOptionId: string
}

export const multipleChoiceReducer = (state: OptionExt[], action: MultipleChoiceAction) => {
  const responseOptions = [...state]
  const option: OptionExt | undefined = responseOptions.find(
    (option: OptionExt) => action.responseOptionId === option.id,
  )
  const index: number = option ? state.indexOf(option) : 0
  if (option && !option.isActive) {
    option.isActive = true
    responseOptions[index] = option
  } else if (option && option.isActive) {
    option.isActive = false
    responseOptions[index] = option
  }

  return responseOptions
}
