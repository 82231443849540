import { Box, Typography, Grid, Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { EditCoach } from './pagination/edit'
import { ViewCoach } from './pagination/view'
import { useUserContext } from '../../../providers/User'
import { useDialogContext } from '../../../providers/Dialog'
import { Coach, Gender, Ethnicity, GetCoachRes, CoachReq } from '../../../types/coach_types'
import { createCoach, deleteCoach, getCoach, putRequest, updateCoach } from '../../../queries'
import { BlendDestructiveButton, BlendPrimaryButton, BlendSecondaryButton } from '../../../components/Common/Buttons'
import theme from '../../../theme'
import { BlendTextField } from '../../../components/Common/TextField'
import { BlendTab, BlendTabsGroup } from '../../../components/Common/Tabs'
import { BlendBreadcrumb, BlendBreadcrumbs } from '../../../components/Common/Breadcrumbs'
import { BlendDivider } from '../../../components/Common/Divider'
import { v4 as uuid } from 'uuid'
import { BlendFullpageSpinner } from '../../../components/Common/Spinner'
import { coachValidator } from '../../../validators/coachValidator'

import { BlobServiceClient } from '@azure/storage-blob'
import { ImageSearchTwoTone } from '@mui/icons-material'
import { useAvatarContext } from '../../../providers/Avatars'

export interface CoachViewProps extends Coach {
  tab: number
}

export interface CoachEditProps extends CoachViewProps {
  callBack: (coach: Coach) => void
}

const initCoachState: Coach = {
  id: uuid(),
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  address: '',
  gender: null,
  ethnicity: null,
  ageRange: null,
  disabled: null,
  caringResponsibilities: null,
  dateOfBirth: null,
  avatarFilename: '',
  isOneToOne: true,
  isGroup: false,
  bio: '',
  availability: '',

  inactiveFrom: null,
  inactiveTo: null,
  isActive: true,

  associateContractSigned: null,
  associateContractExpiry: null,

  dataProtectionAgreementSigned: null,
  dataProtectionAgreementExpiry: null,

  identityCheckSigned: null,

  insuranceProvider: '',
  insuranceValue: 0,
  insuranceExpiry: null,

  supervisorFirstName: '',
  supervisorLastName: '',
  supervisorEmail: '',

  attributes: [],
  sessions: [],
  coachees: [],
}

export const Profile: React.FC = () => {
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  const id: string | null = urlParams.get('id')
  const isEdit: string | null = urlParams.get('edit')

  const [editState, setEditState] = useState(!!isEdit)
  const [tabState, setTabState] = useState(0)

  const context = useUserContext()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const dialog = useDialogContext()

  const { isLoading, data, isFetching } = useQuery<GetCoachRes>({
    queryFn: () => getCoach(context, id),
    queryKey: ['getCoachData', id],
    enabled: !!context.token && !!id,
    onError: (error) => {
      console.error(error)
      alert('Coach ID is invalid')
      navigate('/coaches')
    },
    onSuccess: (data) => {
      setCoachState({
        ...data.coach,
        attributes: data.attributes ?? [],
      })
      setEditCoachState({
        ...data.coach,
        attributes: data.attributes ?? [],
      })
      setFirstNameState(data.coach.firstName)
      setLastNameState(data.coach.lastName)
    },
  })

  const avatar = useAvatarContext(data?.coach.avatarFilename)

  const [coachState, setCoachState] = useState<Coach | undefined>(
    data
      ? {
          ...data.coach,
          attributes: data.attributes ?? [],
        }
      : initCoachState,
  )
  const [editCoachState, setEditCoachState] = useState<Coach>(
    data
      ? {
          ...data.coach,
          attributes: data.attributes ?? [],
        }
      : initCoachState,
  )
  const [firstNameState, setFirstNameState] = useState<string>(data ? data.coach.firstName : '')
  const [lastNameState, setLastNameState] = useState<string>(data ? data.coach.lastName : '')

  const mutation = useMutation<GetCoachRes, Error>({
    mutationFn: () => (id ? updateCoach(context, getRequestObject()) : createCoach(context, getRequestObject())),
    onError: async (error) => await dialog.fetchErrorDialog(JSON.parse(error.message)),
    onSuccess: async (data) => {
      await queryClient.invalidateQueries('getAllCoachesData')
      if (id) {
        await queryClient.setQueryData(['getCoachData', id], data as GetCoachRes)
        setEditState(false)
      } else {
        navigate('/coaches')
      }
    },
  })

  const handleEditButton = () => {
    if (editState && editCoachState) {
      const coach = { ...editCoachState, firstName: firstNameState, lastName: lastNameState }
      const coachValidation = coachValidator(coach)
      if (coachValidation.length > 0) {
        dialog.openDialog(coachValidation, 3)
      } else {
        dialog
          .openConfirmDialog('Are you sure you want to save these changes?', async () => {
            mutation.mutate()
          })
          .then(() => setCoachState(coach))
      }
    } else setEditState(true)
  }

  const deleteMutation = useMutation({
    mutationFn: () => deleteCoach(context, coachState?.id ?? null),
    onError: (error) => console.error(error),
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllCoachesData')
      await navigate('/coaches')
    },
  })

  const handleDelete = async () => {
    dialog.openDeleteDialog(`${firstNameState} ${lastNameState}`, () => deleteMutation.mutate())
  }

  const getRequestObject = (): CoachReq => {
    return {
      ...editCoachState,
      firstName: firstNameState,
      lastName: lastNameState,
      gender: !!editCoachState.gender ? Object.keys(Gender).indexOf(editCoachState.gender) : Gender.Other,
      ethnicity: !!editCoachState.ethnicity
        ? Object.keys(Ethnicity).indexOf(editCoachState.ethnicity)
        : Ethnicity.Other,
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabState(newValue)
  }

  const handleViewHistoryButton = () => {
    navigate(`/session-history?coachId=${id}`)
  }

  const setActiveMutation = useMutation<GetCoachRes>(
    () =>
      putRequest(context, '/coach', JSON.stringify({ ...getRequestObject(), isActive: !coachState?.isActive ?? true })),
    {
      onError: (error) => console.error(error),
      onSuccess: async (data) => {
        await queryClient.invalidateQueries('getAllCoachesData')
        await queryClient.setQueryData(['getCoachData', id], data as GetCoachRes)
      },
    },
  )

  const handleIsActiveToggle = () => {
    dialog.openConfirmDialog(
      `Are you sure you want to set the coach to ${coachState?.isActive ? 'inactive' : 'active'}?`,
      async () => {
        setActiveMutation.mutate()
      },
    )
  }

  const handleCancelClick = () => {
    setEditState(false)
    if (!coachState?.firstName) navigate(-1)
  }

  const breadcrumbs: BlendBreadcrumb[] = [
    { label: 'Coaches', link: '/coaches' },
    { label: coachState?.firstName ? `${coachState.firstName} ${coachState.lastName}` : 'New Coach' },
  ]

  return !isLoading && !isFetching && coachState && editCoachState ? (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      sx={{ minHeight: '100%', width: '100%' }}
    >
      <Box>
        <BlendBreadcrumbs breadcrumbs={breadcrumbs} />
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" padding="24px 0">
          <Grid container item md={8}>
            <Grid item>
              <Avatar src={avatar} sx={{ height: '100px', width: '100px' }} />
            </Grid>
            <Grid item marginLeft={3}>
              <Grid container direction="column" justifyContent="space-evenly" alignItems="flex-start">
                {!editState && (
                  <>
                    <Typography variant="h2">
                      {coachState.firstName} {coachState.lastName}
                    </Typography>
                    {coachState.isActive ? (
                      <Typography color={theme.blend.teal}>Active</Typography>
                    ) : (
                      <Typography color={theme.blend.midGrey}>Inactive</Typography>
                    )}
                  </>
                )}
                {editState && (
                  <Box sx={{ display: 'inline-flex', maxWidth: '600px' }}>
                    <BlendTextField
                      required
                      value={firstNameState}
                      label="First Name"
                      placeholder="First Name"
                      name="name"
                      onChange={(event) => setFirstNameState(event.target.value)}
                    />
                    <BlendTextField
                      required
                      sx={{ marginLeft: '40px' }}
                      value={lastNameState}
                      label="Last Name"
                      placeholder="Last Name"
                      name="name"
                      onChange={(event) => setLastNameState(event.target.value)}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}>
            <Box sx={{ float: 'right' }}>
              {editState && (
                <BlendSecondaryButton variant="contained" sx={{ mr: '11px' }} onClick={handleCancelClick}>
                  Cancel
                </BlendSecondaryButton>
              )}
              <BlendPrimaryButton variant="contained" onClick={handleEditButton}>
                {editState ? 'Save' : 'Edit'}
              </BlendPrimaryButton>
              {!editState && (
                <BlendSecondaryButton variant="contained" sx={{ ml: '11px' }} onClick={handleIsActiveToggle}>
                  {coachState.isActive ? 'Make Inactive' : 'Make Active'}
                </BlendSecondaryButton>
              )}
            </Box>
          </Grid>
        </Grid>
        <BlendTabsGroup
          sx={{ marginBottom: '20px' }}
          value={tabState}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <BlendTab label="Contact Details" />
          <BlendTab label="Characteristics" />
          <BlendTab label="About" />
          <BlendTab label="Legal & Insurance" />
        </BlendTabsGroup>
        {!editState ? (
          <ViewCoach {...coachState} tab={tabState} />
        ) : (
          <EditCoach {...editCoachState} tab={tabState} callBack={setEditCoachState} />
        )}
      </Box>
      <Box>
        <BlendDivider sx={{ paddingTop: '20px' }} />
        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ paddingTop: '20px' }}>
          <Box>
            <BlendDestructiveButton variant={'contained'} sx={{ marginLeft: '20px' }} onClick={handleDelete}>
              Delete coach profile
            </BlendDestructiveButton>
          </Box>
          <Box>
            {/* <PrimaryButton variant={'contained'}>View calendar</PrimaryButton> */}
            <BlendPrimaryButton variant={'contained'} sx={{ marginLeft: '20px' }} onClick={handleViewHistoryButton}>
              View history
            </BlendPrimaryButton>
          </Box>
        </Grid>
      </Box>
    </Grid>
  ) : (
    <BlendFullpageSpinner />
  )
}
