import { Grid, styled, FormControl, RadioGroup, FormControlLabel, Radio, MenuItem, Autocomplete } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { QuestionProps, ResponseExt } from '../../pages/questionnaire'
import { QuestionComponent } from '.'
import { Question, ResponseOption } from '../../types/questionnaire_types'
import { BlendTextField } from '../Common/TextField'

const FormRadio = styled(Radio)({
  color: '#1763AF',
  '&.Mui-checked': {
    color: '#1763AF',
  },
})

interface CoachOption {
  label: string
  value: string
}

interface CoachQuestionProps extends QuestionProps {
  defaultCoachId: string | null
  coaches: { id: string; name: string }[] | null
}

export const SelectChoice: React.FC<CoachQuestionProps> = (props: CoachQuestionProps) => {
  const defaultSelection = useRef(props.currentResponse ? props.currentResponse[0].text : props.defaultCoachId ?? '')

  const options = useMemo(
    () =>
      props.coaches?.map((coach) => {
        return { label: coach.name, value: coach.id }
      }),
    [props.coaches],
  )

  const [selection, setSelection] = useState(
    options?.some((coach) => coach.value === defaultSelection.current)
      ? {
          label: options.find((coach) => coach.value === defaultSelection.current)?.label ?? '',
          value: defaultSelection.current,
        }
      : null,
  )

  const handleSelection = (coach: CoachOption | null) => {
    setSelection(coach)
    const updatedResponse: ResponseExt = { ...props.responses[0], text: coach?.value ?? '' }
    props.callBack(props, [updatedResponse])
  }

  useEffect(() => {
    if (!!props.defaultCoachId) {
      props.callBack(props, [{ ...props.responses[0], text: props.defaultCoachId }])
    }
  }, [props.defaultCoachId])

  return (
    <QuestionComponent {...props}>
      <FormControl sx={{ width: '100%' }}>
        <Autocomplete
          disablePortal
          onChange={(
            event: React.SyntheticEvent<Element, Event>,
            value: {
              label: string
              value: string
            } | null,
          ) => {
            handleSelection(value)
          }}
          value={selection}
          options={options ?? []}
          renderInput={(props) => <BlendTextField {...props} defaultValue={undefined} />}
        />
      </FormControl>
    </QuestionComponent>
  )
}
