import { Contract } from '../types/contract_type'
import {
  isEmail,
  isGreaterThanZero,
  isPhoneNumber,
  isRequired,
  isGreaterThan,
  hasPrimaryContact,
  isAfterYear,
} from './validations'

export const contractValidator = (contract: Contract) => {
  const errors: string[] = []
  errors.push(isRequired('Contract name', contract.contractName))
  errors.push(isRequired('Client', contract.clientId))
  errors.push(isRequired('Contract handle', contract.contractHandle))

  errors.push(isRequired('Start date', contract.startDate))
  errors.push(isRequired('Expiration date', contract.expirationDate))
  errors.push(isAfterYear('Start date', contract.startDate, 2010))
  errors.push(isGreaterThan('Start and End date', contract.startDate, contract.expirationDate))

  errors.push(isGreaterThanZero('Session length', contract.sessionLength))

  errors.push(isRequired('Blend contract manager', contract.officeUserId))

  contract.contacts?.map((contact, index) => {
    errors.push(isRequired(`Contact ${index}: First name`, contact.firstName))
    errors.push(isRequired(`Contact ${index}: Last name`, contact.lastName))
    errors.push(isEmail(`Contact ${index}`, contact.email))
    errors.push(isPhoneNumber(`Contact ${index}`, contact.mobile))
  })

  errors.push(hasPrimaryContact(contract.contacts))

  return errors.filter((error) => error.length > 1)
}
