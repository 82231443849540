import { Box, Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { BlendAttributeButton, BlendSecondaryAttributeButton } from '../../../../../components/Coach'
import { BlendDivider } from '../../../../../components/Common/Divider'
import theme from '../../../../../theme'
import { AttributeBody, AttributeType, Coach, Ethnicity } from '../../../../../types/coach_types'

const colors = [
  theme.multiColor.blue,
  theme.multiColor.teal,
  theme.multiColor.lightGreen,
  theme.multiColor.darkTeal,
  theme.multiColor.dullBlue,
  theme.multiColor.lightBlue,
  theme.multiColor.green,
]

export const ViewCharacteristics: React.FC<Coach> = (props) => {
  return (
    <Box>
      <Box sx={{ dislpay: 'flex', flexDirection: 'row', paddingTop: '20px', margin: '0' }}>
        {Object.values(AttributeType).map((title, index) => {
          return (
            <>
              {index ? <BlendDivider sx={{ paddingTop: '20px', marginBottom: '20px' }} /> : null}
              <Grid container key={title} direction="row" sx={{ width: '100%' }}>
                <Grid item md={3} sm={12}>
                  <Typography>{title}</Typography>
                </Grid>
                <Grid item md={9} sm={12} spacing="10px">
                  <Grid container spacing="10px">
                    {props?.attributes
                      ?.filter(
                        (attribute) =>
                          Object.keys(AttributeType).indexOf(attribute.type) === index && !!!attribute.isCustom,
                      )
                      .sort((a, b) => a.text.localeCompare(b.text))
                      .map((attribute, attrindex) => (
                        <Grid item key={`${title}-${attrindex}`}>
                          <BlendAttributeButton disabled primaryColor={colors[index]}>
                            {attribute.text}
                          </BlendAttributeButton>
                        </Grid>
                      ))}
                    {props?.attributes
                      ?.filter(
                        (attribute) =>
                          Object.keys(AttributeType).indexOf(attribute.type) === index && !!attribute.isCustom,
                      )
                      .sort((a, b) => a.text.localeCompare(b.text))
                      .map((attribute, attrindex) => (
                        <Grid item key={`${title}-${attrindex}`}>
                          <BlendSecondaryAttributeButton disabled primaryColor={colors[index]}>
                            {attribute.text}
                          </BlendSecondaryAttributeButton>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )
        })}
      </Box>
    </Box>
  )
}
