import { Box, Card, CardActionArea, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import theme from '../../theme'
import { Event } from './calendar'
import moment from 'moment'
import { CalendarCoach } from './coachFilter'
import { GetEventsForResObject } from '../../types/calendar_types'
import { useDateString } from '../../hooks/useDateTime'
import TouchRipple from '@mui/material/ButtonBase/TouchRipple'
import { useNavigate } from 'react-router-dom'

export interface AgendaProps {
  events: GetEventsForResObject[]
  coaches: CalendarCoach[]
  selectedDate: Date
}

interface EventCardProps {
  event: Event
  coachName: string
}

const AgendaContainer = styled(Box)({
  boxShadow: '0px 2px 7px rgba(20, 89, 171, 0.33), 0px 1px 9px rgba(0, 0, 0, 0.09)',
  width: '100%',
  height: '800px',
  padding: '20px',
  borderRadius: '8px',
  position: 'relative',
})

const EventsContainer = styled(Box)({
  marginTop: '20px',
  padding: '20px',
  height: '600px',
  overFlowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: 5,
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    outline: `1px solid slategrey`,
  },
})

const BookingInfo = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const EventCard: React.FC<EventCardProps> = (props) => {
  const { event, coachName } = props
  const navigate = useNavigate()

  const handleEventClick = () => {
    if (event.transactionId) navigate(`/session/edit?id=${event.sessionId}`)
    else navigate(`/session/editPast?id=${event.sessionId}`)
  }

  return (
    <Card
      sx={{
        boxShadow: '0px 1px 9px rgba(0, 0, 0, 0.09)',
        marginBottom: '20px',
        width: '100%',
        borderRadius: '4px',
        borderLeft: `12px solid ${event.color}`,
      }}
    >
      <CardActionArea onClick={handleEventClick}>
        <Grid container>
          <Grid item sx={{ width: '80px', margin: '12px 0', borderRight: `1px solid ${theme.blend.midGrey}` }}>
            <Box p="3px 0 0 9px">
              <Typography fontSize="13pt">{moment(event.start).format('HH:mm')}</Typography>
              <Typography fontSize="12pt" color={theme.multiColor.midGrey}>
                {Math.floor(event.end.getTime() - event.start.getTime()) / 60000} min
              </Typography>
            </Box>
          </Grid>
          <Grid item m="12px 0" pl="8px">
            <BookingInfo fontSize="13pt">{event.contractHandle}</BookingInfo>
            <BookingInfo fontSize="12pt">{coachName}</BookingInfo>
            <BookingInfo fontSize="12pt">{event.coacheeName}</BookingInfo>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  )
}

export const Agenda: React.FC<AgendaProps> = (props) => {
  const getCoachName = (index: number) => {
    const coach = props.coaches.find((c) => c.id == props.events[index].coachId)
    return `${coach?.firstName} ${coach?.lastName}`
  }

  return (
    <AgendaContainer>
      <Box width="100%" borderBottom="1px solid #ededed" pb="20px">
        <Typography
          textAlign="center"
          variant="caption"
          whiteSpace="nowrap"
          height="24px"
          fontSize="20px"
          lineHeight="1"
        >
          {useDateString(props.selectedDate)}
        </Typography>
      </Box>
      <EventsContainer>
        {props.events.map((event, index) => (
          <EventCard key={`event-${index}`} event={event as Event} coachName={getCoachName(index)} />
        ))}
      </EventsContainer>
    </AgendaContainer>
  )
}
