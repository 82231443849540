import { AccountInfo, InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../authConfig'
import { UserContext } from '../providers/User'

export const useHandleLogin = async (instance: IPublicClientApplication, context: UserContext | undefined) => {
  await instance
    .loginPopup(loginRequest)
    .then((result) => {
      if (result.account != null && result.accessToken) {
        context?.setAccountInfo(result.account)
        context?.setToken(result.accessToken)
      } else throw new Error('Account was not valid')
    })
    .catch((e) => {
      console.error(e)
    })
}

export const useGetSilentToken = async (context: UserContext | undefined) => {
  const { instance, accounts } = useMsal()

  const accountInfo: AccountInfo | null = accounts[0]

  const request = {
    scopes: [process.env.REACT_APP_API_SCOPE ?? ''],
    account: accountInfo,
  }

  if (accountInfo != null)
    await instance
      .acquireTokenSilent(request)
      .then((response) => {
        context?.setToken(response.accessToken)
        if (accountInfo != null) context?.setAccountInfo(accountInfo)
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(request)
            .then((response) => {
              context?.setToken(response.accessToken)
              if (accountInfo != null) context?.setAccountInfo(accountInfo)
            })
            .catch((error) => console.error(error))
        }
        console.error('No token', error)
      })
}
