import { Box, Typography } from '@mui/material'
import React from 'react'

interface BlendFullPageSpinnerProps {
  isBigLoad?: boolean
}

export const BlendFullpageSpinner = ({ isBigLoad }: BlendFullPageSpinnerProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: '100%',
      }}
    >
      <BlendInterwindSpinner />
      <Typography>{isBigLoad ? `Loading, this may take a few seconds . . .` : `Loading . . .`}</Typography>
    </Box>
  )
}

export const BlendComponentSpinner = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: '100%',
        position: 'absolute',
        borderRadius: '5px',
      }}
    >
      <BlendInterwindSpinner />
    </Box>
  )
}

export const BlendInterwindSpinner = () => {
  /* generated by https://loading.io/ */
  return (
    <>
      <div className="loadingio-spinner-interwind-k43g2v45cy8">
        <div className="ldio-rmebai39yza">
          <div>
            <div>
              <div>
                <div></div>
              </div>
            </div>
            <div>
              <div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style type="text/css">
        {`
            @keyframes ldio-rmebai39yza-r {
              0%, 100% { animation-timing-function: cubic-bezier(0.2 0 0.8 0.8) }
              50% { animation-timing-function: cubic-bezier(0.2 0.2 0.8 1) }
              0% { transform: rotate(0deg) }
              50% { transform: rotate(180deg) }
              100% { transform: rotate(360deg) }
            }
            @keyframes ldio-rmebai39yza-s {
              0%, 100% { animation-timing-function: cubic-bezier(0.2 0 0.8 0.8) }
              50% { animation-timing-function: cubic-bezier(0.2 0.2 0.8 1) }
              0% { transform: translate(-38.59px,-38.59px) scale(0) }
              50% { transform: translate(-38.59px,-38.59px) scale(1) }
              100% { transform: translate(-38.59px,-38.59px) scale(0) }
            }
            .ldio-rmebai39yza > div { transform: translate(0px,-19.295px) }
            .ldio-rmebai39yza > div > div {
              animation: ldio-rmebai39yza-r 1.1111111111111112s linear infinite;
              transform-origin: 113.5px 113.5px;
            }
            .ldio-rmebai39yza > div > div > div {
              position: absolute;
              transform: translate(113.5px, 90.34599999999999px);
            }
            .ldio-rmebai39yza > div > div > div > div {
              width: 77.18px;
              height: 77.18px;
              border-radius: 50%;
              background: #1763af;
              animation: ldio-rmebai39yza-s 1.1111111111111112s linear infinite;
            }
            .ldio-rmebai39yza > div > div:last-child {
              animation-delay: -0.5555555555555556s;
            }
            .ldio-rmebai39yza > div > div:last-child > div > div {
              animation-delay: -0.5555555555555556s;
              background: #9ccb3b;
            }
            .loadingio-spinner-interwind-k43g2v45cy8 {
              width: 227px;
              height: 227px;
              display: inline-block;
              overflow: hidden;
              background: none;
            }
            .ldio-rmebai39yza {
              width: 100%;
              height: 100%;
              position: relative;
              transform: translateZ(0) scale(1);
              backface-visibility: hidden;
              transform-origin: 0 0; /* see note above */
            }
            .ldio-rmebai39yza div { box-sizing: content-box; }
        `}
      </style>
    </>
  )
}
