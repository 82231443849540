import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { Box, ButtonProps, Grid, ListSubheader, SelectChangeEvent, styled, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { BlendInvertedButton } from '../../components/Common/Buttons'
import { BlendSearchTextField } from '../../components/Common/SearchTextField'
import theme from '../../theme'
import { reportsData } from './reportsData'
import { getBlobRequest, getClients, getCoachees, getCoaches, getContracts } from '../../queries'
import { useUserContext } from '../../providers/User'
import { BlendSelect, BlendMenuItem } from '../../components/Common/Select'
import { useQuery } from 'react-query'
import { FilterParameterProps, FilterParameterType, ReportProps, SelectOptionProps } from '../../types/report_types'
import { reportReducer } from '../../reducers/reportReducer'
import moment from 'moment'
import { BlendDateRangePicker } from '../../components/Common/DateRangePicker'
import { GetAllClientsRes, GetAllClientsResBody } from '../../types/client_type'
import { GetAllContractsRes, GetAllContractsResBody } from '../../types/contract_type'
import { GetAllCoachesRes, ShortCoachResBody } from '../../types/coach_types'
import { GetAllCoacheesRes, GetAllCoacheesResBody } from '../../types/coachee_type'
import { getBigTimeSpan } from '../../hooks/useDateTime'
import { BlendCalendarTooltip } from '../../components/Common/Icons'
import { BlendFullpageSpinner } from '../../components/Common/Spinner'
import { useDialogContext } from '../../providers/Dialog'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

const formatter = 'YYYY-MM-DD[T]HH:mm:ss'

const DownloadButton = styled((props: LoadingButtonProps) => <LoadingButton {...props} />)({
  color: theme.text.white,
  textTransform: 'none',
  fontSize: '12pt',
  fontWeight: 600,
  padding: '13px 20px',
  boxShadow: `${theme.blend.boxShadow} ${theme.blend.white}`,
  '&:disabled': {
    backgroundColor: theme.blend.lightGrey,
    border: `2px solid ${theme.blend.lightGrey}`,
    color: theme.text.white,
  },
  backgroundColor: theme.blend.teal,
  border: `2px solid ${theme.blend.teal}`,
  '&:hover': {
    backgroundColor: theme.blend.blue,
    border: `2px solid ${theme.blend.blue}`,
    boxShadow: `${theme.blend.boxShadow} ${theme.blend.lightBlue}`,
  },
  height: '38px',
  minWidth: '38px',
  width: '38px',
  borderRadius: '4px',
  '& .MuiButton-startIcon': {
    marginRight: '-4px',
  },
  '& .MuiLoadingButton-loadingIndicatorStart': {
    position: '0',
  },
})

export const Reports: React.FC = () => {
  const [searchState, setSearchState] = useState<string>('')
  const [dateRange, setDateRange] = useState<(Date | null)[]>(getBigTimeSpan)
  const [reportsState, setReportsState] = useReducer(reportReducer, reportsData)

  const userContext = useUserContext()
  const dialogContext = useDialogContext()

  const { data: clientsData, isFetching: isFetchingClientsData } = useQuery<GetAllClientsRes>({
    queryFn: () => getClients(userContext),
    queryKey: 'getAllClientsData',
    enabled: !!userContext.token,
  })

  const { data: contractsData, isFetching: isFetchingContractsData } = useQuery<GetAllContractsRes>({
    queryFn: () => getContracts(userContext),
    queryKey: 'getAllContractsData',
    enabled: !!userContext.token,
  })

  const { data: coachesData, isFetching: isFetchingCoachesData } = useQuery<GetAllCoachesRes>({
    queryFn: () => getCoaches(userContext),
    queryKey: 'getAllCoachesData',
    enabled: !!userContext.token,
  })

  const { data: coacheesData, isFetching: isFetchingCoacheesData } = useQuery<GetAllCoacheesRes>({
    queryFn: () => getCoachees(userContext),
    queryKey: 'getAllCoacheesData',
    enabled: !!userContext.token,
  })

  const getClientOptions = (options: GetAllClientsResBody[]): FilterParameterProps => {
    return {
      type: FilterParameterType.Client,
      options: options.map((o) => ({ ...o, type: FilterParameterType.Client })),
    }
  }

  const getParentClientOptions = (options: GetAllClientsResBody[]): FilterParameterProps => {
    return {
      type: FilterParameterType.ParentClient,
      options: options
        .filter((c) => c.isParentClient)
        .map((o) => ({
          ...o,
          type: FilterParameterType.ParentClient,
        })),
    }
  }

  const getContractOptions = (options: GetAllContractsResBody[]): FilterParameterProps => {
    return {
      type: FilterParameterType.Contract,
      options: options.map<SelectOptionProps>((c) => ({
        id: c.id,
        name: c.contractHandle,
        type: FilterParameterType.Contract,
      })),
    }
  }

  const getCoachesOptions = (options: ShortCoachResBody[]): FilterParameterProps => {
    return {
      type: FilterParameterType.Coach,
      options: options.map((c) => ({
        id: c.id,
        name: `${c.firstName} ${c.lastName}`,
        type: FilterParameterType.Coach,
      })) as SelectOptionProps[],
    }
  }

  const getCoacheesOptions = (options: GetAllCoacheesResBody[]): FilterParameterProps => {
    return {
      type: FilterParameterType.Coachee,
      options: options.map((o) => ({ ...o, type: FilterParameterType.Coachee })),
    }
  }

  const getAllOptions = (
    coaches: ShortCoachResBody[],
    coachees: GetAllCoacheesResBody[],
    contracts: GetAllContractsResBody[],
  ): FilterParameterProps => {
    let options: SelectOptionProps[] = []

    const parameters = [getContractOptions(contracts), getCoachesOptions(coaches), getCoacheesOptions(coachees)]
    for (let index = 0; index < parameters.length; index++) {
      const item = parameters[index]
      options.push({ id: 'type', name: FilterParameterType[item.type], type: item.type, isOptGroup: true })
      options = [...options, ...item.options]
    }
    return {
      type: FilterParameterType.All,
      options: options,
    }
  }

  const filterParameters = useMemo<FilterParameterProps[]>(
    () =>
      clientsData && contractsData && coachesData && coacheesData
        ? [
            getClientOptions(clientsData.clients),
            getParentClientOptions(clientsData.clients),
            getContractOptions(contractsData.contracts),
            getCoachesOptions(coachesData.coaches),
            getCoacheesOptions(coacheesData.coachees),
            getAllOptions(coachesData.coaches, coacheesData.coachees, contractsData.contracts),
          ]
        : [],
    [clientsData, contractsData, coachesData, coacheesData],
  )

  const handleSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const res = evt.target.value
    setSearchState(() => res)
    setReportsState({
      type: 'SET_FILTERED',
      payload: [...reportsData.filter((r) => r.name.includes(res) || r.description.includes(res))],
    })
  }

  const getQuery = (report: ReportProps) => {
    if (report.filterParameterRequired && !report.filterParameterId) {
      dialogContext.errorDialog('Please select a filter option')
      return null
    }
    let query = report.route
    if (!report.noFilterParameter)
      query += `/${
        report.filterParameterId == 'All' ? '00000000-0000-0000-0000-000000000000' : report.filterParameterId
      }`
    if (report.filterParameterTypeRequired) {
      const allOptions = filterParameters.map((p) => p.options)
      const type = allOptions.flat().find((o) => o.id == report.filterParameterId)?.type ?? FilterParameterType.All
      query += `/${type}`
    }
    if (!report.noDateFilters && dateRange && dateRange[0] && dateRange[1])
      query += `/${moment(dateRange[0]).format(formatter)}/${moment(dateRange[1]).format(formatter)}`
    if (report.additionalParameter) query += report.additionalParameter
    return query
  }

  const handleDownload = (report: ReportProps, index: number) => {
    const query = getQuery(report)
    if (!!userContext && query) {
      setReportsState({
        type: 'SET_IS_LOADING',
        index,
        payload: '',
      })
      getBlobRequest(userContext, query, report.fileName, report.format).then(() =>
        setReportsState({
          type: 'SET_IS_LOADING',
          index,
          payload: '',
        }),
      )
    }
  }

  const handleFilterParameterSelect = (e: SelectChangeEvent<unknown>, index: number, type: FilterParameterType) => {
    setReportsState({
      type: 'SET_FILTER_PARAMETER',
      index,
      parameterType: type,
      payload: e.target.value as string | ReportProps[],
    })
  }

  const getOptions = (type: FilterParameterType, hasAllOption: boolean | undefined) => {
    const options: SelectOptionProps[] = filterParameters.find((p) => p.type == type)?.options ?? []
    const allOption: SelectOptionProps = { id: 'All', name: 'All', type, isOptGroup: false }
    return hasAllOption ? [allOption].concat(options) : options
  }

  return (
    <Box>
      <Grid container direction="row">
        <Grid item md={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingTop: '20px',
              paddingLeft: '20px',
              paddingBottom: '10px',
            }}
          >
            <Typography variant="h2">Reports</Typography>
          </Box>
        </Grid>
        <Grid item md={6} display="flex" justifyContent="flex-end" sx={{ paddingTop: '20px' }}>
          <BlendSearchTextField placeholder="Search" value={searchState} onChange={handleSearch} />
        </Grid>
      </Grid>
      {!isFetchingClientsData && !isFetchingCoacheesData && !isFetchingCoachesData && !isFetchingContractsData ? (
        <Grid container direction="column">
          <Grid item sx={{ paddingLeft: '20px', paddingBottom: '20px' }}>
            <BlendDateRangePicker
              dateFrom={dateRange[0]}
              dateTo={dateRange[1]}
              onChange={(dateFrom, dateTo) => setDateRange([dateFrom, dateTo])}
            />
          </Grid>
          {reportsState && !reportsState.length && (
            <Grid item>
              <Typography variant="h6">No reports</Typography>
            </Grid>
          )}
          <Grid item container sx={{ borderTop: `1px solid ${theme.palette.divider}` }}>
            {reportsState &&
              reportsState.length > 0 &&
              reportsState.map((report, index) => {
                const options = getOptions(report.filterParameterType, report.hasAllOption)
                return (
                  <Grid
                    key={`report-${index}`}
                    container
                    item
                    direction="row"
                    sx={{
                      paddingTop: '16px',
                      paddingBottom: '20px',
                      borderBottom: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <Grid item md={6}>
                      <Typography variant="h6">{report.name}</Typography>
                      <Typography fontSize="16px">{report.description}</Typography>
                    </Grid>
                    <Grid item md={6} display="flex" justifyContent="flex-end" alignItems="center" pr="21px">
                      {!report.noFilterParameter && (
                        <Box width="268px">
                          <BlendSelect
                            label={
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                {`Filter: ${
                                  report.filterParameterType.valueOf() == 'ParentClient'
                                    ? 'Parent Client'
                                    : report.filterParameterType.valueOf()
                                }`}
                                {!report.noDateFilters && <BlendCalendarTooltip />}
                              </Box>
                            }
                            name="filterParameterId"
                            value={report.filterParameterId || ''}
                            renderValue={(selected: unknown) =>
                              (selected as string).length === 0
                                ? 'Select Option'
                                : options.find((c) => c.id == selected)?.name ?? ''
                            }
                            onChange={(e: SelectChangeEvent<unknown>) =>
                              handleFilterParameterSelect(e, index, report.filterParameterType)
                            }
                          >
                            {options.map((option, index) =>
                              option.isOptGroup ? (
                                <ListSubheader key={option.type}>{option.type}</ListSubheader>
                              ) : (
                                <BlendMenuItem key={`${report.filterParameterType}-${index}`} value={option.id}>
                                  {option.name}
                                </BlendMenuItem>
                              ),
                            )}
                          </BlendSelect>
                        </Box>
                      )}
                      {report.noFilterParameter && !report.noDateFilters && <BlendCalendarTooltip />}
                      <DownloadButton
                        sx={{ marginLeft: '20px' }}
                        onClick={() => handleDownload(report, index)}
                        loadingPosition={'center'}
                        loading={report.isLoading}
                      >
                        {!report.isLoading && <FontAwesomeIcon icon={faFileDownload as IconProp} />}
                      </DownloadButton>
                    </Grid>
                  </Grid>
                )
              })}
          </Grid>
        </Grid>
      ) : (
        <BlendFullpageSpinner />
      )}
    </Box>
  )
}
