import { Breadcrumbs, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import theme from '../../theme'

export interface BlendBreadcrumbsProps {
  breadcrumbs: BlendBreadcrumb[]
}

export interface BlendBreadcrumb {
  label: string
  link?: string
}

export const BlendBreadcrumbs: React.FC<BlendBreadcrumbsProps> = ({ breadcrumbs }) => {
  const navigate = useNavigate()

  return (
    <Breadcrumbs separator="--">
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <Typography
            key={`breadcrumb-${index}`}
            fontSize={'15pt'}
            fontWeight={400}
            sx={{
              cursor: breadcrumb.link ? 'pointer' : 'text',
              color: index == breadcrumbs.length - 1 ? theme.blend.teal : theme.blend.black,
            }}
          >
            {breadcrumb.link ? (
              <a onClick={() => navigate(`${breadcrumb.link}`)}>{breadcrumb.label}</a>
            ) : (
              <>{breadcrumb.label}</>
            )}
          </Typography>
        )
      })}
    </Breadcrumbs>
  )
}
