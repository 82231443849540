import React, { Children, useState } from 'react'
import { Calendar as BigCalendar, momentLocalizer, Views, SlotInfo } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'
import { Box, styled, Typography } from '@mui/material'
import theme from '../../theme'
import { CustomToolbar } from './calendarToolbar'
import { GetEventsForResObject } from '../../types/calendar_types'
import { BlendComponentSpinner } from '../../components/Common/Spinner'
import { HeaderProps } from 'react-table'

export interface Event {
  id: string
  coachId: string
  title: string
  allDay: boolean
  start: Date
  end: Date
  contractHandle: string
  coacheeName: string
  color: string
  transactionId: string
  sessionId: string
}

export interface CalendarInnerProps {
  isLoading: boolean
  start: Date
  end: Date
  events: GetEventsForResObject[]
  onNavigate: (date: Date, view: string) => void
  onSelectSlot: (slotInfo: SlotInfo) => void
  onEventClick: (event: Event) => void
}

const CalendarContainer = styled(Box)({
  position: 'relative',
  height: '800px',
  '& div[role=table]': {
    borderRadius: '5px',
  },
  '& .rbc-month-view, .rbc-header, .rbc-month-row + .rbc-month-row, .rbc-time-view, .rbc-time-header-content, .rbc-time-content, .rbc-timeslot-group, .rbc-events-container':
    {
      borderColor: 'rgba(23, 99, 175, 0.15)',
    },
  '& .rbc-time-view': {
    borderRadius: '5px',
  },
  '& .rbc-off-range': {
    color: 'inherit',
  },
  '& .rbc-off-range-bg': {
    background: '#D3D3D3',
  },
  '& .rbc-event': {
    padding: 0,
    margin: '0 5px',
    width: 'auto',
    borderRadius: '4px',
  },
  '& .rbc-show-more': {
    fontSize: '8px',
    textDecoration: 'none',
    color: theme.multiColor.blue,
    textAlign: 'center',
  },
  '& .rbc-btn-group button.active': {
    backgroundColor: theme.multiColor.midGrey,
  },
  '& .rbc-btn-group button:not(:last-child):hover': {
    borderRightColor: theme.multiColor.midGrey,
  },
  '& .rbc-btn-group button': {
    fontFamily: theme.typography.fontFamily,
    color: theme.text.black,
    borderColor: theme.multiColor.midGrey,
    padding: '5px 15px',
    height: '31px',
    borderRadius: '5px',
    textTransform: 'none',
  },
  '& .rbc-date-cell': {
    fontSize: '14px',
    padding: '6px 12px 9px 0',
  },
  '& .rbc-allday-cell': {
    height: 'fit-content',
  },
  '& .rbc-time-slot .rbc-label': {
    fontSize: '15px',
  },
  '& .rbc-row-segment': {
    height: '23px',
    marginBottom: '5px',
  },
})

//start week with monday
moment.locale('ko', {
  week: {
    dow: 1,
    doy: 1,
  },
})
const localizer = momentLocalizer(moment)
const formats = {
  weekdayFormat: 'dddd',
  eventTimeRangeFormat: () => '',
}

export const CalendarInner: React.FC<CalendarInnerProps> = (props) => {
  const [view, setView] = useState('month')

  const CustomEvent = ({ event }: { event: Event }, view: string) => {
    const isWeekView = view == Views.WORK_WEEK
    return (
      <Box height="23px" p="4px 4.5px" fontSize="10px">
        <Typography
          fontSize="10px"
          whiteSpace={isWeekView ? 'pre-line' : 'nowrap'}
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {!isWeekView && <>{moment(event.start).format('HH:mm')} </>}
          <Box component="span" fontWeight="600">
            {event.title}
          </Box>
        </Typography>
      </Box>
    )
  }

  const EventPropGetter = (event: Event) => {
    return {
      style: {
        backgroundColor: event.color,
        cursor: 'pointer',
      },
    }
  }

  const DateCellWrapper = ({ children }: { children?: any }) =>
    React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        borderColor: theme.multiColor.borderColor,
      },
    })

  const HeaderCellContent = (props: any) => (
    <Box component="span" fontWeight={400} fontSize="15px">
      {props.label}
    </Box>
  )

  return (
    <CalendarContainer>
      {props.isLoading && <BlendComponentSpinner />}
      <BigCalendar
        formats={formats}
        min={new Date(0, 0, 0, 8, 0, 0)}
        max={new Date(0, 0, 0, 19, 0, 0)}
        localizer={localizer}
        selectable={true}
        views={[Views.MONTH, Views.WORK_WEEK, Views.DAY]}
        components={{
          event: CustomEvent,
          dateCellWrapper: DateCellWrapper,
          header: HeaderCellContent,
          toolbar: (toolbar) => CustomToolbar(toolbar, view),
          month: {
            event: (eventProps) => CustomEvent(eventProps, Views.MONTH),
          },
          work_week: {
            event: (eventProps) => CustomEvent(eventProps, Views.WORK_WEEK),
          },
          day: {
            event: (eventProps) => CustomEvent(eventProps, Views.DAY),
          },
        }}
        dayLayoutAlgorithm="no-overlap"
        events={props.events as Event[]}
        timeslots={4}
        eventPropGetter={EventPropGetter}
        messages={{ showMore: () => '● ● ●' }}
        onShowMore={() => setView(Views.DAY)}
        onNavigate={props.onNavigate}
        onSelectSlot={props.onSelectSlot}
        onSelectEvent={(event: Event) => props.onEventClick(event)}
      />
    </CalendarContainer>
  )
}
