export interface AvatarEditAction {
  field: string
  payload?: string
}

export const avatarsReducer = (state: { [filename: string]: string }, action: AvatarEditAction) => {
  const newState = { ...state }

  if (action.payload) newState[action.field] = action.payload

  return newState
}
