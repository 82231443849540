import { CoacheeQuestionnaire } from './coacheeQuestionnaire_type'
import { Coach, Session, ShortCoachResBody } from './coach_types'
import { CoacheeContact } from './contact_types'
import { Contract } from './contract_type'
import { SessionStatus } from './session_type'

export interface GetCoacheesByContractRes {
  coachees: {
    id: string
    name: string
    contacts: {
      id: string
      firstName: string
      lastName: string
      isPrimary: boolean
      isDelegate: boolean
      isActive: boolean
    }[]
    coaches: {
      id: string
      firstName: string
      lastName: string
    }[]
    sessions: {
      sessionId: string
      coachId: string
      coachFirstName: string
      coachLastName: string
    }[]
    coacheeQuestionnaires: {
      sent: boolean
      completed: boolean
      status: string
      questionnaire: {
        name: string
        type: string
      }
    }[]
  }[]
}

export interface GetAllCoacheesResBody {
  id: string
  name: string
  contractId: string
  clientId: string
  clientName: string
  status: string
  progress: number
  lastUpdated: Date
  contacts?: CoacheeContact[]
  coaches?: Partial<Coach>[]
}
export interface GetAllCoacheesRes {
  coachees: GetAllCoacheesResBody[]
}

export interface GetCoacheeRes {
  coachee: {
    id: string
    contractId: string
    contractName: string
    contractHandle: string
    clientId: string
    clientName: string
    statusStep: number
    contractStart: Date
    contractExpiry: Date
  }
  sessions: {
    sessionId: string
    start: Date
    end: Date
    sessionPaid: boolean
    coachId: string
    coachFirstName: string
    coachLastName: string
    lastUpdated: Date
    status: SessionStatus
  }[]
  contacts: CoacheeContact[]
  coacheeQuestionnaires: {
    coacheeQuestionnaireId: string
    sent: Date
    completed: Date
    status: string
    questionnaire: {
      questionnaireId: string
      name: string
      type: string
    }
  }[]
  coaches: ShortCoachResBody[]
}

export interface Coachee {
  id: string
  contractId?: string
  clientId?: string
  status?: CoacheeStatus
  lastUpdated?: Date
  contract?: Contract
  contacts?: CoacheeContact[]
  coaches?: Partial<Coach>[]
  sessions?: Session[]
  coacheeQuestionnaires?: CoacheeQuestionnaire[]
}

export interface CoacheeEditorProps {
  contactDelegate: Partial<CoacheeContact>
  contactPrimary?: Partial<CoacheeContact>
  coachee: Coachee
}

export enum CoacheeStatus {
  Referral = 'Referral',
  Coachee = 'Coachee',
}
