import { Box, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { BlendBreadcrumb, BlendBreadcrumbs } from '../../../components/Common/Breadcrumbs'
import { BlendPrimaryButton, BlendSecondaryButton } from '../../../components/Common/Buttons'
import { BlendFullpageSpinner } from '../../../components/Common/Spinner'
import { BlendTab, BlendTabsGroup } from '../../../components/Common/Tabs'
import { ReferralStepper } from '../../../components/ReferralStepper'
import { ContractStatus, getContractStatus } from '../../../hooks/useDateTime'
import { useDialogContext } from '../../../providers/Dialog'
import { useUserContext } from '../../../providers/User'
import { getCoachee, postCoacheeEvaluation } from '../../../queries'
import theme from '../../../theme'
import { GetCoacheeRes } from '../../../types/coachee_type'
import { CoachTab } from './tabs/coach'
import { DetailsTab } from './tabs/details'
import { SessionsTab } from './tabs/sessions'

export const CoacheeView: React.FC = () => {
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  const id: string | null = urlParams.get('id')
  const context = useUserContext()
  const navigate = useNavigate()
  const dialog = useDialogContext()
  const [tabState, setTabState] = useState(0)

  const { isFetching, data } = useQuery<GetCoacheeRes>({
    queryFn: () => getCoachee(context, id),
    queryKey: ['getCoacheeData', id],
    enabled: !!context.token,
  })

  const contractStatus = useMemo(() => {
    const state = getContractStatus(data?.coachee.contractStart, data?.coachee.contractExpiry)
    return state == ContractStatus.expired || state == ContractStatus.something_wrong ? 'Contract Expired' : ''
  }, [data?.coachee])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabState(newValue)
  }

  const mutation = useMutation<unknown, Error>({
    mutationFn: () => postCoacheeEvaluation(context, id),
    onError: async (error) => {
      await dialog.fetchErrorDialog(JSON.parse(error.message))
    },
  })

  const handleSendEvaluationClick = () => {
    dialog.openConfirmDialog(
      `Are you sure you want to send an evaluation to ${data?.contacts[0].firstName} ${data?.contacts[0].lastName}?`,
      async () => mutation.mutate(),
    )
  }

  const breadcrumbs: BlendBreadcrumb[] = data
    ? [
        { label: 'Coachee Manager', link: '/coachees' },
        { label: data.coachee.clientName, link: `/clients/profile?id=${data.coachee.clientId}` },
        { label: data.coachee.contractHandle, link: `/contracts/profile?id=${data.coachee.contractId}` },
        { label: `${data.contacts[0].firstName} ${data.contacts[0].lastName}` },
      ]
    : []

  return !isFetching && data ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%', width: '100%' }}>
      <Box sx={{ display: 'flex', flex: '0 1 auto' }}>
        <BlendBreadcrumbs breadcrumbs={breadcrumbs} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', margin: '20px 20px 20px 0' }}>
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Typography variant="h2">{`${data.contacts[0].firstName} ${data.contacts[0].lastName}`}</Typography>
            <Typography variant="body1" color={theme.blend.error} ml="40px">
              {data && data.coachee.contractStart && data.coachee.contractExpiry && contractStatus}
            </Typography>
          </Box>
          <Typography variant="h6" color={theme.blend.lightGrey}>{`${data.coachee.contractHandle} | ${
            data.coachee.clientName ?? ''
          }`}</Typography>
        </Box>
        <Box sx={{ display: 'flex', direction: 'row' }}>
          <BlendPrimaryButton onClick={() => navigate(`/coachees/editor?id=${data?.coachee.id}`)}>
            Edit
          </BlendPrimaryButton>
          <BlendSecondaryButton sx={{ ml: '15px' }} onClick={handleSendEvaluationClick}>
            Send evaluation
          </BlendSecondaryButton>
          {/* <BlendSecondaryButton sx={{ marginLeft: '10px' }} onClick={() => alert('Put on hold here somehow')}>
            {data.contacts[0].isActive ? 'Put on hold' : 'Activate'}
          </BlendSecondaryButton> */}
        </Box>
      </Box>
      <Box sx={{ marginY: '80px' }}>
        <ReferralStepper activeStep={data?.coachee.statusStep ?? 0} />
      </Box>
      <BlendTabsGroup
        value={tabState}
        onChange={handleTabChange}
        aria-label="basic tabs example"
        variant="fullWidth"
        sx={{ marginBottom: '20px' }}
      >
        <BlendTab label="Coach" />
        <BlendTab label="Sessions" />
        <BlendTab label="Coachee Details" />
      </BlendTabsGroup>
      <Box sx={{ display: 'flex', width: '100%', flex: '1 1 auto', alignSelf: 'stretch' }}>
        {
          {
            0: (
              <CoachTab
                coacheeId={data.coachee.id}
                coacheeName={`${data?.contacts[0].firstName} ${data?.contacts[0].lastName}`}
                coacheeQuestionnaires={data.coacheeQuestionnaires}
                coaches={data.coaches}
              />
            ),
            1: (
              <SessionsTab
                coacheeId={data.coachee.id}
                contacts={data.contacts}
                coaches={data.coaches}
                sessions={data.sessions}
                contractExpired={contractStatus.length > 0}
              />
            ),
            2: (
              <DetailsTab
                coachee={data.coachee}
                contacts={data.contacts}
                coaches={data.coaches}
                sessionCount={data.sessions ? data.sessions.length : 0}
              />
            ),
          }[tabState]
        }
      </Box>
    </Box>
  ) : (
    <BlendFullpageSpinner />
  )
}
