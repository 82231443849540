import React, { useMemo } from 'react'
import { Box, Typography, styled, IconButton } from '@mui/material'
import { useUserContext } from '../../providers/User'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Table } from '../../components/Table/table'
import { GetAllClientsRes } from '../../types/client_type'
import { useNavigate } from 'react-router-dom'
import { useDateTimeString } from '../../hooks/useDateTime'
import { BlendPrimaryButton } from '../../components/Common/Buttons'
import { BlendBinIcon, BlendEditIcon, BlendViewIcon } from '../../components/Common/Icons'
import { BlendFullpageSpinner } from '../../components/Common/Spinner'
import { useDialogContext } from '../../providers/Dialog'
import { Cell } from 'react-table'
import { deleteClient, getClients } from '../../queries'

const Title = styled(Typography)({
  margin: '20px',
})

interface TableData {
  id: string
  clientName: string
  parentName?: string
  contractCount?: number
  lastUpdated: Date
}

export const ClientManager: React.FC = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const context = useUserContext()
  const dialog = useDialogContext()

  const { isFetching, error, data } = useQuery<GetAllClientsRes>({
    queryFn: () => getClients(context),
    queryKey: 'getAllClientsData',
    enabled: !!context.token,
    refetchOnMount: true,
  })

  const mutation = useMutation({
    mutationFn: (id: string) => deleteClient(context, id),
    onError: (error) => console.error(error),
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllClientsData')
    },
  })

  const handleDelete = (id: string, name: string) => {
    dialog.openDeleteDialog(name, () => {
      mutation.mutate(id)
    })
  }

  const tableData = data?.clients?.map<TableData>((client) => {
    return {
      id: client.id,
      clientName: client.name,
      parentName: client.parentClientName,
      contractCount: client.contractsCount,
      lastUpdated: new Date(client.lastUpdated),
    }
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Clients',
        columns: [
          {
            Header: 'Client',
            id: 'clientName',
            accessor: 'clientName',
          },
          {
            Header: 'Parent',
            id: 'parentName',
            accessor: 'parentName',
          },
          {
            Header: 'Contracts',
            id: 'contractCount',
            accessor: 'contractCount',
          },
          {
            Header: 'Last Updated',
            id: 'lastUpdated',
            accessor: 'lastUpdated',
            Cell: ({ row }: Cell<TableData>) => useDateTimeString(row.original?.lastUpdated),
          },
          {
            Header: '',
            id: 'actions',
            accessor: 'id',
            Cell: ({ row }: Cell<TableData>) => {
              return (
                <Box sx={{ float: 'right' }}>
                  <IconButton
                    onClick={() => navigate(`/clients/profile?id=${row.values.actions}&edit=true`, { replace: true })}
                  >
                    <BlendEditIcon />
                  </IconButton>
                  <IconButton onClick={() => navigate(`/clients/profile?id=${row.values.actions}`, { replace: true })}>
                    <BlendViewIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(row.values.actions, row.original.clientName)}>
                    <BlendBinIcon />
                  </IconButton>
                </Box>
              )
            },
          },
        ],
      },
    ],
    [],
  )

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Title variant="h2">Client Manager</Title>
        <BlendPrimaryButton onClick={() => navigate(`/clients/create`)}>Create new client</BlendPrimaryButton>
      </Box>
      {error ? <h1>Could not fetch Clients</h1> : null}
      {!error && data && !!tableData && !tableData.length ? <h1>There are no Clients</h1> : null}
      {!isFetching && data && !!tableData && tableData.length ? (
        <Table columns={columns} data={tableData} searchLabel="Search" />
      ) : !error && !data ? (
        <BlendFullpageSpinner />
      ) : null}
    </Box>
  )
}
