import React, { useState } from 'react'
import { useEffect } from 'react'
import { QuestionProps, ResponseExt } from '../../pages/questionnaire'
import { QuestionComponent } from '.'
import { QuestionType } from '../../types/questionnaire_types'
import { BlendTextField } from '../Common/TextField'

export const FreeForm: React.FC<QuestionProps> = (props: QuestionProps) => {
  const [textFieldState, setTextFieldState] = useState(props.currentResponse ? props.currentResponse[0].text : '')

  useEffect(() => {
    const updatedResponse: ResponseExt = { ...props.responses[0], text: textFieldState }
    props.callBack(props, [updatedResponse])
  }, [textFieldState])

  return (
    <QuestionComponent {...props}>
      <BlendTextField
        sx={{ height: props.type == QuestionType.longFreeForm ? '150px' : '60px', width: '100%' }}
        value={textFieldState}
        onChange={(event) => setTextFieldState(event.target.value)}
        multiline={props.type == QuestionType.longFreeForm}
        rows={props.type == QuestionType.longFreeForm ? 4 : 1}
      />
    </QuestionComponent>
  )
}
