import { Box, IconButton, styled, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Cell } from 'react-table'
import { BlendSmallPrimaryButton } from '../../../../../components/Common/Buttons'
import { BlendCheckbox } from '../../../../../components/Common/Checkbox'
import { BlendEditIcon, BlendWarningIcon } from '../../../../../components/Common/Icons'
import { Table } from '../../../../../components/Table/table'
import { useDateTimeString } from '../../../../../hooks/useDateTime'
import { useDialogContext } from '../../../../../providers/Dialog'
import { Contract, ContractCoachee } from '../../../../../types/contract_type'

const Title = styled(Typography)({
  margin: '20px',
})

interface LinkedCoacheesProps {
  contract: Contract
  expired: boolean
}

interface SessionsTableData {
  id: string
  coachName: string
  coacheeName: string
  start: Date
  end: Date
  sessionPaid: boolean
  status: string
  lastUpdated: Date
}

export const LinkedSessions: React.FC<LinkedCoacheesProps> = ({ contract, expired }) => {
  const navigate = useNavigate()
  const dialog = useDialogContext()

  const handleEditSessionClick = (id: string) => {
    navigate(`/session/editPast?id=${id}`)
  }

  const handleNewSessionClick = () => {
    if (expired)
      dialog.openConfirmDialog(
        `You have reached a quota or expiry date, are you sure you want to continue?`,
        async () => await navigate(`/session/create?contractId=${contract.id}`),
        true,
      )
    else navigate(`/session/create?contractId=${contract.id}`)
  }

  const tableData = useMemo<SessionsTableData[]>(() => {
    const sessions: SessionsTableData[] = []
    contract.coachees?.map((coachee) => {
      const contractCoachee = coachee as ContractCoachee
      contractCoachee.sessions?.map((session) => {
        sessions.push({
          id: session.id,
          coacheeName: `${contractCoachee.firstName} ${contractCoachee.lastName}`,
          coachName: `${session.coach.firstName} ${session.coach.lastName}`,
          start: session.start,
          end: session.end,
          sessionPaid: session.sessionPaid,
          status: session.status as string,
          lastUpdated: session.lastUpdated,
        })
      })
    })
    return sessions
  }, [contract.sessions, contract.sessions?.length])

  const columns = useMemo(
    () => [
      {
        Header: 'Sessions',
        columns: [
          {
            Header: 'Name',
            id: 'coacheeName',
            accessor: 'coacheeName',
          },
          {
            Header: 'Coach',
            id: 'coachName',
            accessor: 'coachName',
          },
          {
            Header: 'Start',
            id: 'start',
            accessor: 'start',
            Cell: ({ row }: Cell<SessionsTableData>) => useDateTimeString(row.values.start),
          },
          {
            Header: 'End',
            id: 'end',
            accessor: 'end',
            Cell: ({ row }: Cell<SessionsTableData>) => useDateTimeString(row.values.end),
          },
          {
            Header: 'Paid',
            id: 'sessionPaid',
            accessor: 'sessionPaid',
            Cell: ({ row }: Cell<SessionsTableData>) => <BlendCheckbox checked={row.values.sessionPaid} />,
          },
          {
            Header: 'Status',
            id: 'status',
            accessor: 'status',
          },
          {
            Header: 'Last Updated',
            id: 'lastUpdated',
            accessor: 'lastUpdated',
            Cell: ({ row }: Cell<SessionsTableData>) => useDateTimeString(row.original.lastUpdated),
          },
          {
            Header: '',
            id: 'actions',
            accessor: 'id',
            Cell: ({ row }: Cell<SessionsTableData>) => {
              return (
                <Box sx={{ float: 'right' }}>
                  {
                    <IconButton sx={{ mr: '12px' }} onClick={() => handleEditSessionClick(row.original.id)}>
                      <BlendEditIcon />
                    </IconButton>
                  }
                </Box>
              )
            },
          },
        ],
      },
    ],
    [],
  )

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Title variant="h6">Linked Sessions</Title>
        <Box>
          <BlendSmallPrimaryButton onClick={handleNewSessionClick}>
            {expired && <BlendWarningIcon color="white" style={{ marginRight: '10px' }} />} Add new session
          </BlendSmallPrimaryButton>
        </Box>
      </Box>
      {!contract.coachees ? <Title variant="h4">Could not fetch session history</Title> : null}
      {contract.coachees && contract.coachees.some((coachee) => coachee.sessions && coachee.sessions.length > 0) ? (
        <Table columns={columns} data={tableData} />
      ) : (
        <Title variant="h4">There are no sessions</Title>
      )}
    </Box>
  )
}
