import React, { useEffect, useReducer, useState } from 'react'
import { Views, SlotInfo } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'
import { useQuery } from 'react-query'
import { Event, CalendarInner } from './calendar'
import theme from '../../theme'
import { Box, Grid, styled, Typography } from '@mui/material'
import { CoachFilter, CalendarCoach } from './coachFilter'
import { useUserContext } from '../../providers/User'
import { Agenda } from './agenda'
import { BlendPrimaryButton } from '../../components/Common/Buttons'
import { useNavigate } from 'react-router-dom'
import { getCoaches, getEvents } from '../../queries'
import { GetAllCoachesRes } from '../../types/coach_types'
import { GetEventsForRes, GetEventsForResObject } from '../../types/calendar_types'
import { BlendFullpageSpinner } from '../../components/Common/Spinner'
import { coachFilterReducer } from '../../reducers/coachFilterReducer'

const formatter = 'YYYY-MM-DD[T]HH:mm:ss'
const colors = [
  theme.multiColor.blue,
  theme.multiColor.teal,
  theme.multiColor.lightGreen,
  theme.multiColor.darkTeal,
  theme.multiColor.dullBlue,
  theme.multiColor.lightBlue,
  theme.multiColor.green,
  theme.multiColor.beige,
  theme.multiColor.purple,
  theme.multiColor.yellow,
  theme.multiColor.peach,
  theme.multiColor.darkGrey,
]

interface CoachColor {
  id: string
  color: string
}

const Title = styled(Typography)({
  margin: '20px',
})

export const Calendar: React.FC = () => {
  const context = useUserContext()
  const navigate = useNavigate()
  const [start, setStart] = useState<Date>(moment().startOf('month').toDate())
  const [end, setEnd] = useState<Date>(moment().endOf('month').toDate())
  const [events, setEvents] = useState<GetEventsForResObject[]>([])
  const [agendaEvents, setAgendaEvents] = useState<GetEventsForResObject[]>([])
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment().startOf('day'))

  const getEventsQuery = () => getEvents(context, moment(start).format(formatter), moment(end).format(formatter))
  const {
    isFetching: isLoadingEvents,
    error: errorEvents,
    data: dataEvents,
  } = useQuery<GetEventsForRes>({
    queryFn: getEventsQuery,
    queryKey: ['getEventsForData', start, end],
    enabled: !!context.token,
  })

  const getCoachesQuery = () => getCoaches(context)
  const {
    isFetching: isLoadingCoaches,
    error: errorCoaches,
    data: dataCoaches,
  } = useQuery<GetAllCoachesRes>({
    queryFn: getCoachesQuery,
    onSuccess: (data) => {
      setCoaches({
        type: 'RESET',
        init: data,
      })
    },
    queryKey: 'getAllCoachesData',
    enabled: !!context.token,
  })

  const [coaches, setCoaches] = useReducer(
    coachFilterReducer,
    dataCoaches?.coaches.map<CalendarCoach>((coach) => ({ ...coach, showEvents: true })) ?? [],
  )

  useEffect(() => {
    const events = dataEvents?.events ?? []
    let filteredEvents = events.filter((e) => coaches.some((c) => c.id == e.coachId && c.showEvents))
    const coachIds = filteredEvents.map<string>((event) => event.coachId)
    const uniqueCoachIds = Array.from(new Set(coachIds))
    const coachColors = uniqueCoachIds.map<CoachColor>((id, index) => ({
      id,
      color: getColor(index),
    }))
    filteredEvents = filteredEvents.map<Event>((event) => ({
      ...event,
      start: moment.utc(event.start).local().toDate(),
      end: moment.utc(event.end).local().toDate(),
      color: coachColors.find((c) => c.id == event.coachId)?.color ?? theme.multiColor.midGrey,
    }))
    setEvents(filteredEvents)
    setAgendaEvents(filteredEvents.filter((e) => moment(e.start).startOf('day').isSame(selectedDate)))
  }, [dataEvents, coaches])

  function getColor(index: number) {
    const n = colors.length
    return colors[((index % n) + n) % n]
  }

  const handleNavigate = (date: Date, view: string) => {
    let start, end
    const momentDate = moment(date)
    if (view == Views.MONTH) {
      start = momentDate.startOf('month').toDate()
      end = momentDate.endOf('month').toDate()
    } else {
      start = momentDate.startOf('week').toDate()
      end = momentDate.endOf('week').toDate()
    }
    setStart(start)
    setEnd(end)
  }

  const handleSelectSlot = (slotInfo: SlotInfo) => {
    const startOfSelectedDay = moment(slotInfo.start).startOf('day')
    setSelectedDate(startOfSelectedDay)
    setAgendaEvents(events.filter((e) => moment(e.start).startOf('day').isSame(startOfSelectedDay)))
  }

  const handleShowAllSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setCoaches({ type: 'SET_ALL' })
    } else {
      setCoaches({ type: 'UNSET_ALL' })
    }
  }

  const handleCoachSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, coachId: string) => {
    setCoaches({
      type: 'TOGGLE',
      id: coachId,
      checked,
    })
  }

  const handleCreateBookingClick = () => {
    navigate(`/session/create`)
  }

  const handleEventClick = (event: Event) => {
    if (event.transactionId) navigate(`/session/edit?id=${event.sessionId}`)
    else navigate(`/session/editPast?id=${event.sessionId}`)
  }

  return (
    <>
      {isLoadingCoaches && <BlendFullpageSpinner />}
      {!isLoadingCoaches && (
        <Box minHeight="100%">
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Title variant="h2">Calendar</Title>
            <BlendPrimaryButton onClick={handleCreateBookingClick}>Create new booking</BlendPrimaryButton>
          </Box>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item md={2}>
              <CoachFilter
                coaches={coaches}
                handleShowAllSwitchChange={(event, checked) => {
                  handleShowAllSwitchChange(event, checked)
                }}
                handleCoachSwitchChange={handleCoachSwitchChange}
              />
            </Grid>
            <Grid item md={7} sx={{ paddingX: '20px' }}>
              <CalendarInner
                isLoading={isLoadingEvents}
                start={start}
                end={end}
                events={events}
                onNavigate={handleNavigate}
                onSelectSlot={handleSelectSlot}
                onEventClick={handleEventClick}
              />
            </Grid>
            <Grid item md={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Agenda events={agendaEvents} coaches={coaches} selectedDate={selectedDate.toDate()} />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}
