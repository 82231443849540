import { StringDict } from '@azure/msal-common'
import { BlobServiceClient } from '@azure/storage-blob'
import { CollectionsBookmarkOutlined } from '@mui/icons-material'
import React, { useEffect, useReducer, useState } from 'react'
import { avatarsReducer } from '../reducers/avatarsReducer'

export interface AvatarContext {
  avatars: {
    [filename: string]: string
  }
  getAvatar: (filenameArg?: string | null | undefined) => Promise<string | undefined>
}

export const useAvatar = () => {
  const [avatarsState, setAvatarsState] = useReducer(avatarsReducer, {})

  const blobServiceClient = process.env.REACT_APP_BLOB_URL
    ? new BlobServiceClient(process.env.REACT_APP_BLOB_URL)
    : undefined

  const getAvatar = async (filenameArg?: string | null) => {
    if (filenameArg && avatarsState[filenameArg]) return avatarsState[filenameArg]
    else if (filenameArg)
      try {
        if (!!blobServiceClient) {
          const containerClient = blobServiceClient.getContainerClient('blend-avatars')
          const blobClient = containerClient.getBlobClient(filenameArg)
          const downloadBlobClient = await blobClient.download()

          const blob = await downloadBlobClient.blobBody

          if (blob) {
            const newFilename = URL.createObjectURL(blob)
            setAvatarsState({ field: filenameArg, payload: newFilename })
            return newFilename
          } else throw new Error()
        } else throw new Error()
      } catch {
        return undefined
      }
    else return undefined
  }

  return { avatars: avatarsState, getAvatar }
}

export interface AvatarProviderProps {
  children: React.ReactNode
}

export const AvatarStateContext = React.createContext<AvatarContext | undefined>(undefined)

export function AvatarProvider(props: AvatarProviderProps): JSX.Element {
  const getAvatar = useAvatar()
  return <AvatarStateContext.Provider value={getAvatar}>{props.children}</AvatarStateContext.Provider>
}

export const useAvatarContext = (filenameArg?: string | null): string | undefined => {
  const filename = filenameArg == undefined || filenameArg == null ? undefined : filenameArg

  const context = React.useContext(AvatarStateContext)
  if (context === undefined) {
    throw new Error('Ensure AvatarContext is used within the Provider')
  }

  const [avatarState, setAvatarState] = useState<string | undefined>(filename ? context.avatars[filename] : undefined)

  useEffect(() => {
    const getAvatarAsync = async () => {
      const newUrl = await context.getAvatar(filename)
      setAvatarState(newUrl)
    }
    if (filename && !!!context.avatars[filename]) getAvatarAsync()
    else if (filename && !!context.avatars[filename]) setAvatarState(context.avatars[filename])
  }, [filename])

  return avatarState
}
