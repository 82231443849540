import React, { useEffect, useState } from 'react'
import { InputAdornment, styled } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { useDebounce } from '../../hooks/useDebounce'
import { BlendStyledTextField } from './TextField'

const BlendStyledSearchTextField = styled(BlendStyledTextField)({
  width: 'auto',
  '& .MuiOutlinedInput-root': {
    '& input': {
      paddingLeft: '0px',
    },
  },
})

interface BlendSearchTextFieldProps {
  placeholder: string
  value: string
  onChange?: (e: any) => void
}

export const BlendSearchTextField: React.FC<BlendSearchTextFieldProps> = (props) => {
  const [valueState, setValueState] = useState<React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>>()
  const debounceValue = useDebounce(valueState, 50)
  useEffect(() => {
    props.onChange && valueState && props.onChange(valueState)
  }, [debounceValue])

  return (
    <BlendStyledSearchTextField
      type="text"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        ),
      }}
      placeholder={props.placeholder}
      value={valueState ? valueState.target.value : props.value}
      onChange={(event) => setValueState(event)}
    />
  )
}
