export enum FilterParameterType {
  None = 'None',
  All = 'All',
  Client = 'Client',
  ParentClient = 'ParentClient',
  Contract = 'Contract',
  Coach = 'Coach',
  Coachee = 'Coachee',
}

export interface SelectOptionProps {
  id: string
  name: string
  type: FilterParameterType
  isOptGroup?: boolean
}

export interface FilterParameterProps {
  type: FilterParameterType
  options: SelectOptionProps[]
}

export interface ReportProps {
  route: string
  name: string
  description: string
  filterParameterType: FilterParameterType
  filterParameterId?: string
  hasAllOption?: boolean
  noFilterParameter?: boolean
  filterParameterTypeRequired?: boolean
  filterParameterRequired?: boolean
  noDateFilters?: boolean
  fileName: string
  additionalParameter?: string
  isLoading?: boolean
  format?: string
}
