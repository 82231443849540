import { GetAllCoachesRes } from '../types/coach_types'

interface CalendarCoach {
  id: string
  firstName: string
  lastName: string
  showEvents: boolean
}

interface CoachFilterEditAction {
  type: 'SET_ALL' | 'UNSET_ALL' | 'TOGGLE' | 'RESET'
  id?: string
  checked?: boolean
  init?: GetAllCoachesRes
}

export const coachFilterReducer = (state: CalendarCoach[], payload: CoachFilterEditAction) => {
  switch (payload.type) {
    case 'SET_ALL':
      return state.map<CalendarCoach>((c) => [{ ...c, showEvents: true }][0])
    case 'UNSET_ALL':
      return state.map<CalendarCoach>((c) => [{ ...c, showEvents: false }][0])
    case 'TOGGLE':
      const index = state.findIndex((c) => c.id == payload.id)
      const newState = [...state]
      if (index != -1) newState[index].showEvents = payload.checked ?? false
      return newState
    case 'RESET':
      return payload.init?.coaches.map<CalendarCoach>((coach) => ({ ...coach, showEvents: true })) ?? []
  }
}
