import { ClientContact } from '../types/contact_types'

export const isRequired = (label?: string, arg?: string | Date | number | null) => (arg ? '' : `${label} is required.`)

export const isNotRequired = () => ''

export const isEmail = (label?: string, arg?: string) =>
  !!!arg
    ? ''
    : (arg?.match(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
      )?.length ?? -1) > 0
    ? ''
    : `${label ? `${label}: ` : ''}Email address is incorrect, try again.`

export const isPhoneNumber = (label?: string, arg?: string) =>
  !!!arg
    ? ''
    : (arg?.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)?.length ?? -1) > 0
    ? ''
    : `${label ? `${label}: ` : ''}Phone number is incorrect, try again.`

export const isBeforeToday = (label?: string, arg?: Date | null) =>
  !!!arg ? '' : arg && new Date(arg) <= new Date() ? '' : `${label ? `${label}: ` : ''}Date must be before today`

export const isAfterToday = (label?: string, arg?: Date | null) =>
  !!!arg ? '' : arg && new Date(arg) > new Date() ? '' : `${label ? `${label}: ` : ''}Date must be after today`

export const isAfterYear = (label?: string, arg?: Date | null, year?: number) =>
  !!!arg
    ? ''
    : arg && year && new Date(arg).getFullYear() >= year
    ? ''
    : `${label ? `${label}: ` : ''}Date must be after ${year}, has the full year been entered?`

export const isGreaterThan = (label?: string, arg2?: Date | null, arg1?: Date | null) =>
  !!!arg1 || !!!arg2
    ? ''
    : arg1 && arg2 && new Date(arg1) > new Date(arg2)
    ? ''
    : `${label ? `${label}: ` : ''}Start date must come before the end date`

export const isGreaterThanZero = (label?: string, arg?: number | null) =>
  !!!arg ? '' : arg && arg > 0 ? '' : `${label ? `${label}: ` : ''}Number must be larger than 0`

export const hasPrimaryContact = (contacts?: ClientContact[]) =>
  contacts?.some((contact) => contact.isContractPrimary || contact.isPrimary)
    ? ''
    : 'There must be at last 1 primary contact'
