import { createTheme } from '@mui/material'
import { green, red } from '@mui/material/colors'

// A custom theme for this app
const theme = {
  typography: {
    fontFamily: `'Raleway', sans-serif`,
    h1: { fontSize: '36pt', fontWeight: 'semi-bold' },
    h2: { fontSize: '31pt' },
    h3: { fontSize: '25pt', fontWeight: 'semi-bold' },
    h4: { fontSize: '25pt' },
    h5: { fontSize: '20pt', fontWeight: 'semi-bold' },
    h6: { fontSize: '20pt' },
    bold1: { fontSize: '15pt', fontWeight: 'semi-bold' },
    body1: { fontSize: '15pt' },
  },
  palette: {
    primary: {
      main: '#1763AF',
    },
    secondary: {
      main: '#00A79D',
    },
    error: {
      main: '#EE5D3D',
    },
    success: {
      main: green[500],
    },
    background: {
      default: '#fff',
    },
    divider: '#B4B4B4',
  },
  blend: {
    black: '#000000',
    white: '#ffffff',
    blue: '#1763AF',
    teal: '#00A79D',
    green: '#9CCB3B',
    lightGrey: '#D3D3D3',
    lightestGrey: '#EFEFEF',
    darkGrey: '#343434',
    midGrey: '#B4B4B4',
    error: '#EE5D3D',
    warning: '#FE8A58',
    lightBlue: '#dce8f3',
    lightTeal: '#d9f2f0',
    lightGreen: '#f0f7e2',
    boxShadow: '2px 2px 4px 0px',
  },
  text: {
    white: '#ffffff',
    black: '#000000',
    breadcrumbs: '#00A79D',
    green: '#9CCB3B',
  },
  sidebarWidth: 240,
  multiColor: {
    borderColor: 'rgba(23, 99, 175, 0.15)',
    midGrey: '#B4B4B4',
    blue: '#1763AF',
    teal: '#00A79D',
    lightGreen: '#9CCB3B',
    darkTeal: '#0C6863',
    dullBlue: '#548D9F',
    lightBlue: '#56BDDD',
    green: '#169962',
    beige: '#C6AF77',
    purple: '#DE7EFF',
    yellow: '#FFC15E',
    peach: '#FFB196',
    darkGrey: '#444054',
  },
  input: {
    border: '#343434',
    placeholder: '#B4B4B4',
    text: '#343434',
  },
  table: {
    actionIcon: '#00A79D',
    deleteIcon: '#EE5D3D',
  },
} as const

type CustomTheme = {
  [Key in keyof typeof theme]: typeof theme[Key]
}

declare module '@mui/material/styles' {
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}
export default createTheme(theme)
