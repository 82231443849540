import { UserContext } from './providers/User'
import moment from 'moment'
import { Client } from './types/client_type'
import { Coachee } from './types/coachee_type'
import { CoachReq } from './types/coach_types'
import { Contract } from './types/contract_type'
import { GetSessionRes } from './types/session_type'

const getRequest = async (context: UserContext, query: string) => {
  try {
    if (!context.token) throw new Error('Not Authorized')
    const data = await fetch(`${process.env.REACT_APP_API_BASE_URL}${query}`, {
      headers: !context.token ? {} : { Authorization: `Bearer ${context.token}` },
    })
    const json = await data.json()
    if (data.status >= 400) throw new Error(JSON.stringify(json))
    else return json
  } catch (e) {
    console.error('GET request error', e)
    throw e
  }
}

export const getBlobRequest = async (context: UserContext, query: string, fileName: string, format?: string) => {
  try {
    if (!context.token) throw new Error('Not Authorized')
    await fetch(`${process.env.REACT_APP_API_BASE_URL}${query}`, {
      headers: !context.token ? {} : { Authorization: `Bearer ${context.token}` },
    })
      .then((response) =>
        response.blob().then((blob) => ({
          status: response.status,
          blob,
          response,
        })),
      )
      .then(({ status, blob }) => {
        if (status < 400 && status != 204) {
          if (!format) format = 'xlsx'
          const url = window.URL.createObjectURL(blob)
          const filename = fileName ? `${moment().format('YYYY-MM-DD')}-${fileName}.${format}` : `report.${format}`
          const link = document.createElement('a')
          link.href = url
          link.download = filename
          link.click()
          link.remove()
        } else throw Error(`Error ${status}`)
      })
  } catch (e) {
    console.error('GET file request error', e)
    throw e
  }
}

export const putRequest = async (context: UserContext, query: string, dataJSON: string) => {
  try {
    if (!context.token) throw new Error('Not Authorized')
    const result = await fetch(`${process.env.REACT_APP_API_BASE_URL}${query}`, {
      method: 'PUT',
      headers: !context.token
        ? {}
        : {
            Authorization: `Bearer ${context.token}`,
            'Content-Type': 'application/json',
          },
      body: dataJSON,
    })
    const json = await result.json()
    if (result.status >= 400) throw new Error(JSON.stringify(json))
    else return json
  } catch (e) {
    console.error('PUT request error', e)
    throw e
  }
}

export const putRequestNoBody = async (context: UserContext, query: string) => {
  try {
    if (!context.token) throw new Error('Not Authorized')
    const result = await fetch(`${process.env.REACT_APP_API_BASE_URL}${query}`, {
      method: 'PUT',
      headers: !context.token
        ? {}
        : {
            Authorization: `Bearer ${context.token}`,
            'Content-Type': 'application/json',
          },
    })
    if (result.status >= 400) throw new Error(JSON.stringify(result.json()))
    else return result
  } catch (e) {
    console.error('PUT request error', e)
    throw e
  }
}

export const postRequest = async (context: UserContext, query: string, dataJSON: string) => {
  try {
    if (!context.token) throw new Error('Not Authorized')
    const result = await fetch(`${process.env.REACT_APP_API_BASE_URL}${query}`, {
      method: 'POST',
      headers: !context.token ? {} : { Authorization: `Bearer ${context.token}`, 'Content-Type': 'application/json' },
      body: dataJSON,
    })
    const json = await result.json()
    if (result.status >= 400) throw new Error(JSON.stringify(json))
    else return json
  } catch (e) {
    console.error('POST request error', e)
    throw e
  }
}

export const deleteRequest = async (context: UserContext, query: string) => {
  try {
    if (!context.token) throw new Error('Not Authorized')
    const result = await fetch(`${process.env.REACT_APP_API_BASE_URL}${query}`, {
      method: 'DELETE',
      headers: !context.token ? {} : { Authorization: `Bearer ${context.token}`, 'Content-Type': 'application/json' },
    })
    return result
  } catch (e) {
    console.error('DELETE request error', e)
    throw e
  }
}

///Dashboard

export const getDashboard = (context: UserContext) => getRequest(context, '/dashboard')

///Attributes

export const getAttributes = (context: UserContext) => getRequest(context, '/attribute/all')

///Coach

export const getCoaches = (context: UserContext) => getRequest(context, '/coach/all')

export const getCoachShortInfo = (context: UserContext, coachId: string | null) =>
  getRequest(context, `/coach/shortInfo/${coachId}`)

export const getCoach = (context: UserContext, coachId: string | null) => getRequest(context, `/coach/${coachId}`)

export const createCoach = (context: UserContext, coach: CoachReq) =>
  postRequest(context, '/coach', JSON.stringify(coach))

export const updateCoach = (context: UserContext, coach: CoachReq) =>
  putRequest(context, '/coach', JSON.stringify(coach))

export const deleteCoach = (context: UserContext, coachId: string | null) => deleteRequest(context, `/coach/${coachId}`)

///Client

export const getClients = (context: UserContext) => getRequest(context, '/client/all')

export const getClient = (context: UserContext, clientId: string | null) => getRequest(context, `/client/${clientId}`)

export const createClient = (context: UserContext, client: Client) =>
  postRequest(context, '/client', JSON.stringify(client))

export const updateClient = (context: UserContext, client: Client) =>
  putRequest(context, '/client', JSON.stringify(client))

export const deleteClient = (context: UserContext, clientId: string | null) =>
  deleteRequest(context, `/client/${clientId}`)

export const putIsActiveClient = (context: UserContext, clientId: string | null, isActive: boolean) =>
  putRequestNoBody(context, `/client/${clientId}/${isActive}`)

///Contract

export const getContracts = (context: UserContext) => getRequest(context, '/contract/all')

export const getContract = (context: UserContext, contractId: string | null) =>
  getRequest(context, `/contract/${contractId}`)

export const getActiveContracts = (context: UserContext, clientId?: string | null | undefined) =>
  getRequest(context, `/contract/active/${clientId || ''}`)

export const createContract = (context: UserContext, contract: Contract) =>
  postRequest(context, '/contract', JSON.stringify(contract))

export const updateContract = (context: UserContext, contract: Contract) =>
  putRequest(context, '/contract', JSON.stringify(contract))

export const deleteContract = (context: UserContext, contractId: string | null) =>
  deleteRequest(context, `/contract/${contractId}`)

///Coachee

export const getCoachees = (context: UserContext) => getRequest(context, `/coachee/all`)

export const getCoachee = (context: UserContext, coacheeId: string | null) =>
  getRequest(context, `/coachee/${coacheeId}`)

export const getCoacheesByContract = (context: UserContext, contractId: string | null) =>
  getRequest(context, `/coachee/byContract/${contractId}`)

export const createCoachee = (context: UserContext, coachee: Coachee) =>
  postRequest(context, '/coachee', JSON.stringify(coachee))

export const updateCoachee = (context: UserContext, coachee: Coachee) =>
  putRequest(context, '/coachee', JSON.stringify(coachee))

export const postCoacheeQuestionnaire = (context: UserContext, coacheeId: string | null) =>
  postRequest(context, `/coachee/sendQuestionnaire/${coacheeId}`, '')

export const postCoacheeEvaluation = (context: UserContext, coacheeId: string | null) =>
  postRequest(context, `/coachee/sendEvaluation/${coacheeId}`, '')

export const deleteCoachee = (context: UserContext, coacheeId: string | null) =>
  deleteRequest(context, `/coachee/${coacheeId}`)

///Coachee Contact

export const getCoacheeContact = (context: UserContext, coacheeContactId: string | null) =>
  getRequest(context, `/coacheeContact/${coacheeContactId}`)

///Coachee Matching Table

export const getMatchingTable = (context: UserContext, id: string | null) =>
  getRequest(context, `/coacheeQuestionnaire/matchingTable/${id}`)

export const putCoacheeMatch = (context: UserContext, coacheeId?: string | null, coachId?: string | null) =>
  putRequest(context, `/coachee/match/${coacheeId}/${coachId}`, '')

///Calendar

export const getEvents = (context: UserContext, start: string, end: string) => {
  return getRequest(context, `/calendar/${start}/${end}`)
}

///Session

export const getSession = (context: UserContext, sessionId: string | null) =>
  getRequest(context, `/session/${sessionId}`)

export const getCoachSessionHistory = (context: UserContext, coachId: string | null) =>
  getRequest(context, `/session/coachHistory/${coachId}`)

export const getContractSessionHistory = (context: UserContext, contractId: string | null) =>
  getRequest(context, `/session/contractHistory/${contractId}`)

export const createSession = (context: UserContext, session: GetSessionRes, createOutlookEvent: boolean) =>
  postRequest(context, '/session', JSON.stringify({ ...session, createOutlookEvent }))

export const updateSession = (context: UserContext, session: GetSessionRes, createOutlookEvent: boolean) =>
  putRequest(context, '/session', JSON.stringify({ ...session, createOutlookEvent }))

export const deleteSession = (context: UserContext, sessionId: string | null) =>
  deleteRequest(context, `/session/${sessionId}`)

///Coachee Questionnaire

export const getCoacheeQuestionnaires = (context: UserContext) => getRequest(context, '/coacheeQuestionnaire/all')

export const getCoacheeQuestionnaire = (context: UserContext, coacheeQuestionnaireId: string | null) =>
  getRequest(context, `/coacheequestionnaire/${coacheeQuestionnaireId}`)

export const deleteCoacheeQuestionnaire = (context: UserContext, coacheeQuestionnaireId: string | null) =>
  deleteRequest(context, `/coacheeQuestionnaire/${coacheeQuestionnaireId}`)

///Office User

export const getOfficeUsers = (context: UserContext) => getRequest(context, `/officeUser/all`)
