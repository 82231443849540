import React from 'react'
import { Pagination, PaginationItem, PaginationProps, styled, Typography } from '@mui/material'
import theme from '../../theme'

const PreviousButton = () => <Typography>Previous</Typography>
const NextButton = () => <Typography>Next</Typography>

export const BlendPagination = styled((props: PaginationProps) => (
  <Pagination
    {...props}
    renderItem={(item) => <PaginationItem components={{ previous: PreviousButton, next: NextButton }} {...item} />}
  />
))({
  '& .MuiPagination-ul li button': {
    fontSize: '12pt',
    fontWeight: 600,
    border: `2px solid ${theme.blend.blue}`,
    borderRadius: '8px',
    backgroundColor: theme.blend.white,
    color: theme.blend.blue,
  },
  '& .MuiPagination-ul li button p': {
    fontSize: '12pt',
    fontWeight: 600,
    color: theme.blend.blue,
  },
  '& .MuiPagination-ul li button.Mui-selected': {
    backgroundColor: theme.blend.blue,
    color: theme.blend.white,
  },
})
