import { Box, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { Column, TableState } from 'react-table'
import { BlendFullpageSpinner } from '../../components/Common/Spinner'
import { Table } from '../../components/Table/table'
import { useUserContext } from '../../providers/User'
import { getCoacheeQuestionnaire } from '../../queries'
import { GetCoacheeQuestionnaireRes } from '../../types/coacheeQuestionnaire_type'
import { ResponseResponse } from '../../types/questionnaire_types'

interface TableData {
  question: string
  answer: string
}

export const QuestionnaireView: React.FC = () => {
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  const id: string | null = urlParams.get('id')

  const context = useUserContext()

  const { isFetching, error, data } = useQuery<GetCoacheeQuestionnaireRes>({
    queryFn: () => getCoacheeQuestionnaire(context, id),
    queryKey: ['getCoacheeQuestionnaireData', id],
    enabled: !!context.token,
    refetchOnMount: true,
  })

  const tableData = useMemo(
    () =>
      data?.responses
        .sort((a, b) =>
          b.groupOrder === a.groupOrder ? b.questionOrder - a.questionOrder : b.groupOrder - a.groupOrder,
        )
        .map<TableData>((response: ResponseResponse) => {
          return {
            question: response.groupText.includes('?')
              ? `${response.groupText} → ${response.questionText}`
              : response.questionText,
            answer: response.text ? response.text : 'No answer',
            questionOrder: response.questionOrder,
            groupOrder: response.groupOrder,
          }
        }),
    [data],
  )

  const initialState: Partial<TableState<object>> = {
    pageIndex: 0,
    sortBy: [
      {
        id: 'groupOrder',
        desc: true,
      },
      {
        id: 'questionOrder',
        desc: true,
      },
    ],
  }

  const columns = useMemo<Column<object>[]>(
    () => [
      {
        Header: 'Answers',
        columns: [
          {
            Header: 'Question',
            id: 'question',
            accessor: 'question',
          },
          {
            Header: 'Answer',
            id: 'answer',
            accessor: 'answer',
          },
        ],
      },
    ],
    [],
  )

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h2">
          {data && data.questionnaire.type === 'Matching'
            ? data.contacts
              ? `Questionnaire Answers for ${data?.contacts[0].firstName} ${data?.contacts[0].lastName}`
              : 'Questionnaire Answers'
            : 'Evaluation Answers'}
        </Typography>
      </Box>
      {error ? <h1>Could not fetch answers to questionnaire</h1> : null}
      {!error && tableData && !tableData.length ? <h1>There are no answers</h1> : null}
      {!isFetching && tableData && tableData.length ? (
        <Table columns={columns} initialStateOptions={initialState} data={tableData} searchLabel="Search Questions" />
      ) : !error && !data ? (
        <BlendFullpageSpinner />
      ) : null}
    </>
  )
}
