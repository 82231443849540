import React, { useMemo } from 'react'
import { Typography, Box, IconButton } from '@mui/material'
import { styled } from '@mui/system'
import { useUserContext } from '../../providers/User'
import { Table } from '../../components/Table/table'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { deleteCoachee } from '../../queries'
import { GetAllCoacheesRes } from '../../types/coachee_type'
import { BlendBinIcon, BlendEditIcon, BlendViewIcon } from '../../components/Common/Icons'
import { BlendFullpageSpinner } from '../../components/Common/Spinner'
import { BlendPrimaryButton } from '../../components/Common/Buttons'
import { useDialogContext } from '../../providers/Dialog'
import { Cell } from 'react-table'
import { useDateTimeString } from '../../hooks/useDateTime'

const Title = styled(Typography)({
  margin: '20px',
})

interface CoacheeTableData {
  id: string
  contactId: string
  name: string
  clientName: string
  status: string
  progress: number
  lastUpdated: Date
}

export const Coachees: React.FC = () => {
  const queryClient = useQueryClient()
  const context = useUserContext()
  const navigate = useNavigate()
  const dialog = useDialogContext()

  const getCoachees = async () => {
    try {
      if (!context.token) throw new Error('Not Authorized')
      const data = await fetch(`${process.env.REACT_APP_API_BASE_URL ?? ''}/coachee/all`, {
        headers: !context.token ? {} : { Authorization: `Bearer ${context.token}` },
      })

      return data.json()
    } catch (e) {
      console.error(`Can't fetch coachee data`, e)
      throw e
    }
  }

  const { isFetching, error, data } = useQuery<GetAllCoacheesRes>({
    queryFn: getCoachees,
    queryKey: 'getAllCoacheesData',
    enabled: !!context.token,
  })

  const mutation = useMutation({
    mutationFn: (id: string) => deleteCoachee(context, id),
    onError: (error) => console.error(error),
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllCoacheesData')
    },
  })

  const handleDelete = (id: string, name: string) => {
    dialog.openDeleteDialog(name, () => mutation.mutate(id))
  }

  const progressString = (progress: number) => {
    switch (progress) {
      case 0:
        return 'Questionnaire sent'
      case 1:
        return 'Questionnaire submitted'
      case 2:
        return 'First session'
      case 3:
        return 'Active programme'
      case 4:
        return 'Evaluation sent'
      case 5:
        return 'Evaluation submitted'
      default:
        return 'Something went wrong'
    }
  }

  const tableData = data?.coachees.map<CoacheeTableData>((coachee) => {
    return {
      id: coachee.id,
      contactId: (coachee.contacts && coachee.contacts[0].id) ?? '',
      name: `${coachee.contacts && coachee.contacts[0].firstName} ${coachee.contacts && coachee.contacts[0].lastName}`,
      clientName: coachee.clientName,
      status: coachee.status,
      progress: coachee.progress,
      lastUpdated: coachee.lastUpdated,
    }
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Coachees',
        columns: [
          {
            Header: 'Coachee',
            id: 'name',
            accessor: 'name',
          },
          {
            Header: 'Client',
            id: 'clientName',
            accessor: 'clientName',
          },
          {
            Header: 'Status',
            id: 'status',
            accessor: 'status',
          },
          {
            Header: 'Progress',
            id: 'progress',
            accessor: 'progress',
            Cell: ({ row }: Cell<CoacheeTableData>) => progressString(row.original.progress),
          },
          {
            Header: 'Last Updated',
            id: 'lastUpdated',
            accessor: 'lastUpdated',
            Cell: ({ row }: Cell<CoacheeTableData>) => useDateTimeString(row.original.lastUpdated),
          },
          {
            Header: '',
            id: 'actions',
            accessor: 'id',
            Cell: ({ row }: Cell<CoacheeTableData>) => {
              return (
                <Box sx={{ float: 'right' }}>
                  <IconButton onClick={() => navigate(`/coachees/editor?id=${row.original.id}`)} sx={{ mr: '12px' }}>
                    <BlendEditIcon />
                  </IconButton>
                  <IconButton onClick={() => navigate(`/coachees/profile?id=${row.original.id}`)} sx={{ mr: '12px' }}>
                    <BlendViewIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(row.original.id, row.original.name)}>
                    <BlendBinIcon />
                  </IconButton>
                </Box>
              )
            },
          },
        ],
      },
    ],
    [],
  )

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Title variant="h2">Coachees</Title>
        <BlendPrimaryButton onClick={() => navigate('/coachees/editor')}>Create New Referral</BlendPrimaryButton>
      </Box>
      {/* <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item sm={6} md={4}>
          <DashboardSquare bgcolor="#1763AF">
            <DashboardSquareInner number={0} tagline="Data coming soon" />
          </DashboardSquare>
        </Grid>
        <Grid item sm={6} md={4}>
          <DashboardSquare bgcolor="#1763AF">
            <DashboardSquareInner number={0} tagline="Data coming soon" />
          </DashboardSquare>
        </Grid>
        <Grid item sm={6} md={4}>
          <DashboardSquare bgcolor="#1763AF">
            <DashboardSquareInner number={0} tagline="Data coming soon" />
          </DashboardSquare>
        </Grid>
      </Grid> */}
      {error ? <h1>Could not fetch Coachees</h1> : null}
      {!error && tableData && !tableData.length ? <h1>There are no Coachees</h1> : null}
      {!isFetching && tableData && tableData.length ? (
        <Table
          columns={columns}
          data={tableData}
          searchLabel="Search Coachees"
          initialStateOptions={{
            sortBy: [
              {
                id: 'progress',
                desc: true,
              },
            ],
          }}
        />
      ) : !error && !data ? (
        <BlendFullpageSpinner />
      ) : null}
    </Box>
  )
}
