import { Checkbox, Radio, styled } from '@mui/material'
import theme from '../../theme'

export const BlendCheckbox = styled(Checkbox)({
  color: theme.blend.blue,
  '&.Mui-checked': {
    color: theme.blend.blue,
  },
})

export const BlendRadio = styled(Radio)({
  color: theme.blend.blue,
  '&.Mui-checked': {
    color: theme.blend.blue,
  },
})
