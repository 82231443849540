import { Box, BoxProps, styled, Typography } from '@mui/material'
import React from 'react'
import { useDialogContext } from '../../providers/Dialog'

interface BlendContactCardProps extends BoxProps {
  name: string
  email: string
  mobile: string
}

const StyledBlendContactCard = styled(Box)({
  height: '200px',
  borderRadius: '10px',
  padding: '30px 20px',
  margin: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
  '& .MuiBox-root': {
    width: '100%',
  },
})

export const BlendContactCard: React.FC<BlendContactCardProps> = (props: BlendContactCardProps) => {
  const dialog = useDialogContext()
  return (
    <StyledBlendContactCard {...(props as BoxProps)}>
      <Box>
        <Typography textAlign="center" variant="h4">
          {props.name}
        </Typography>
      </Box>
      <Box
        sx={{ marginY: '10px', cursor: 'pointer' }}
        onClick={() => {
          navigator.clipboard.writeText(props.email)
          dialog.openDialog(['Email copied!'], 2)
        }}
      >
        <Typography noWrap textAlign="center" variant="body1">
          {props.email}
        </Typography>
      </Box>
      <Box>
        <Typography textAlign="center" variant="body1">
          {props.mobile}
        </Typography>
      </Box>
    </StyledBlendContactCard>
  )
}
