import React, { useEffect, useState } from 'react'
import { Views } from 'react-big-calendar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { Box, Button, ButtonGroup } from '@mui/material'
import theme from '../../theme'

export const CustomToolbar = (toolbar: any, view: string) => {
  const [currentView, setCurrentView] = useState('month')

  useEffect(() => {
    if (currentView != view) setCurrentView(view)
  }, [view])

  const goToBack = () => {
    let newDate = new Date()
    const year = toolbar.date.getFullYear()
    const month = toolbar.date.getMonth()
    const date = toolbar.date.getDate()
    switch (currentView) {
      case Views.MONTH:
        newDate = new Date(year, month - 1, date)
        break
      case Views.WORK_WEEK:
        newDate = new Date(year, month, date - 7)
        break
      case Views.DAY:
        newDate = new Date(year, month, date - 1)
        break
    }
    toolbar.onNavigate('prev', newDate)
  }

  const goToNext = () => {
    let newDate = new Date()
    const year = toolbar.date.getFullYear()
    const month = toolbar.date.getMonth()
    const date = toolbar.date.getDate()
    switch (currentView) {
      case Views.MONTH:
        newDate = new Date(year, month + 1, date)
        break
      case Views.WORK_WEEK:
        newDate = new Date(year, month, date + 7)
        break
      case Views.DAY:
        newDate = new Date(year, month, date + 1)
        break
    }
    toolbar.onNavigate('next', newDate)
  }

  const goToCurrent = () => {
    const now = new Date()
    toolbar.date.setMonth(now.getMonth())
    toolbar.date.setYear(now.getFullYear())
    toolbar.onNavigate('current')
  }

  const MonthLabel = () => {
    const date = moment(toolbar.date)
    return (
      <Box component="span" fontSize="25px" margin="0 14px" onClick={goToCurrent}>
        {currentView == Views.MONTH && <>{date.format('MMMM YYYY')}</>}
        {currentView == Views.WORK_WEEK && (
          <>
            {date.format('MMMM')} {date.startOf('week').format('D')}-{date.endOf('week').add(-2, 'd').format('D')}
          </>
        )}
        {currentView == Views.DAY && <>{date.format('dddd MMM D')}</>}
      </Box>
    )
  }

  const goToMonthView = () => {
    toolbar.onView(Views.MONTH)
    setCurrentView(Views.MONTH)
  }

  const goToWeekView = () => {
    toolbar.onView(Views.WORK_WEEK)
    setCurrentView(Views.WORK_WEEK)
  }

  const goToDayView = () => {
    toolbar.onView(Views.DAY)
    setCurrentView(Views.DAY)
  }

  const monthClass = currentView == Views.MONTH ? 'active' : ''
  const weekClass = currentView == Views.WORK_WEEK ? 'active' : ''
  const dayClass = currentView == Views.DAY ? 'active' : ''

  return (
    <Box marginBottom="32px">
      <Box component="span" onClick={goToBack} fontSize="16px" sx={{ cursor: 'pointer' }}>
        <FontAwesomeIcon icon={faAngleLeft} color={theme.multiColor.midGrey} />
      </Box>
      <MonthLabel />
      <Box component="span" onClick={goToNext} fontSize="16px" sx={{ cursor: 'pointer' }}>
        <FontAwesomeIcon icon={faAngleRight} color={theme.multiColor.midGrey} />
      </Box>
      <Box display="inline-flex" marginTop="5px" sx={{ float: 'right' }}>
        <ButtonGroup variant="outlined" aria-label="outlined button group" className="rbc-btn-group">
          <Button className={monthClass} onClick={goToMonthView}>
            Month
          </Button>
          <Button className={weekClass} onClick={goToWeekView}>
            Week
          </Button>
          <Button className={dayClass} onClick={goToDayView}>
            Day
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  )
}
