import { Grid, FormControl, RadioGroup, styled } from '@mui/material'
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { QuestionProps, ResponseExt } from '../../pages/questionnaire'
import { multipleChoiceReducer } from '../../reducers/multipleChoiceReducer'
import { QuestionComponent } from '.'
import { ResponseOption } from '../../types/questionnaire_types'
import { BlendPrimaryButton } from '../Common/Buttons'
import theme from '../../theme'

const BlendMultipleChoiceButton = styled(BlendPrimaryButton)({
  margin: '20px',
  color: theme.blend.black,
  backgroundColor: '#EFEFEF',
  textTransform: 'none',
  fontSize: '12pt',
  height: 'fit-content',
  width: '200px',
  boxShadow: `${theme.blend.boxShadow} ${theme.blend.white}`,
  border: `2px solid ${theme.blend.midGrey}`,
  padding: '11px',
  '&:hover': {
    color: theme.blend.white,
    border: '2px solid rgba(0, 0, 0, 0)',
    boxShadow: `${theme.blend.boxShadow} ${theme.blend.teal}`,
  },
})

export interface OptionExt extends ResponseOption {
  isActive: boolean
  callBack?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const OptionButton: React.FC<OptionExt> = (response) => {
  const [isActiveState, setIsActiveState] = useState(response.isActive)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsActiveState(!isActiveState)
    response.callBack && response.callBack(event)
  }

  return (
    <BlendMultipleChoiceButton
      value={response.id}
      onClick={(event: any) => handleClick(event)}
      sx={
        isActiveState
          ? {
              color: theme.blend.white,
              background:
                'linear-gradient(.25turn, rgba(23,99,175,1) 0%, rgba(0,167,157,1) 50%, rgba(156,203,59,1) 100%)',
              border: `2px solid ${theme.blend.midGrey}`,
              boxShadow: `none`,
              '&:hover': {
                border: `2px solid ${theme.blend.midGrey}`,
                boxShadow: `${theme.blend.boxShadow} ${theme.blend.midGrey}`,
              },
            }
          : {}
      }
    >
      {response.text}
    </BlendMultipleChoiceButton>
  )
}

export const MultipleChoice: React.FC<QuestionProps> = (props: QuestionProps) => {
  const options = useMemo(
    () => [...props.responseOptions?.sort((a: ResponseOption, b: ResponseOption) => a.value - b.value)],
    [],
  )

  const defaultValues = useRef(
    options.map<OptionExt>((responseOption) => {
      const optionExt: OptionExt = {
        ...responseOption,
        isActive: false,
      }
      if (props.currentResponse) {
        props.currentResponse.map((response) => {
          if (response.responseOptionId == optionExt.id) optionExt.isActive = true
        })
      }
      return optionExt
    }),
  )

  const [responseOptionsState, setResponseState] = useReducer(multipleChoiceReducer, defaultValues.current)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setResponseState({ responseOptionId: event.currentTarget.value })
  }

  useEffect(() => {
    const responses: ResponseExt[] = []
    responseOptionsState
      .filter((option) => option.isActive)
      .map((option) => {
        const propResponses: ResponseExt[] = props.responses.filter(
          (response) => response.responseOptionId == option.id,
        )
        if (propResponses && propResponses.length > 0) responses.push(propResponses[0])
      })
    props.callBack(props, [...responses])
  }, [responseOptionsState])

  return (
    <QuestionComponent {...props}>
      <FormControl sx={{ width: '100%' }}>
        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
          <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
            {responseOptionsState.map((response: OptionExt) => {
              return <OptionButton key={response.id} {...response} callBack={handleClick} />
            })}
          </Grid>
        </RadioGroup>
      </FormControl>
    </QuestionComponent>
  )
}
