import React, { useMemo } from 'react'
import { Box, IconButton, styled, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Table } from '../../../../../components/Table/table'
import { BlendSmallPrimaryButton } from '../../../../../components/Common/Buttons'
import { Client } from '../../../../../types/client_type'
import { useDateTimeString } from '../../../../../hooks/useDateTime'
import { BlendEditIcon, BlendViewIcon } from '../../../../../components/Common/Icons'
import { Cell } from 'react-table'

const Title = styled(Typography)({
  margin: '20px',
})

interface CoacheeTableData {
  id: string
  name: string
  status: string
  progress: string
  lastUpdated: Date
}

export const LinkedCoachees: React.FC<Client> = (props) => {
  const client = props as Client
  const navigate = useNavigate()

  const steps = ['Referral', 'Questionnaire', 'First Session Booked', 'Active programme']

  const coachees = client.contracts
    ?.flatMap((contract) => {
      if (contract.coachees !== undefined) return contract.coachees
      else return []
    })
    .map<CoacheeTableData>((coachee) => {
      const delegate = coachee.contacts?.filter((contact) => contact.isDelegate)[0]
      const progress: string = steps[coachee.statusStep]
      const status = coachee.statusStep < 2 ? 'Referral' : 'Coachee'
      return {
        id: coachee.id,
        name: `${delegate?.firstName} ${delegate?.lastName}`,
        lastUpdated: coachee.lastUpdated,
        progress: progress,
        status: status,
      }
    })

  const columns = useMemo(
    () => [
      {
        Header: 'Linked Coachees',
        columns: [
          {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
          },
          {
            Header: 'Status',
            id: 'status',
            accessor: 'status',
          },
          {
            Header: 'Progress',
            id: 'progress',
            accessor: 'progress',
          },
          {
            Header: 'Last Updated',
            id: 'lastUpdated',
            accessor: 'lastUpdated',
            Cell: ({ row }: Cell<CoacheeTableData>) => useDateTimeString(row.original.lastUpdated),
          },
          {
            Header: '',
            id: 'actions',
            accessor: 'id',
            Cell: ({ row }: Cell<CoacheeTableData>) => {
              return (
                <Box sx={{ float: 'right' }}>
                  <IconButton onClick={() => navigate(`/coachees/editor?id=${row.original.id}`)} sx={{ mr: '12px' }}>
                    <BlendEditIcon />
                  </IconButton>
                  <IconButton onClick={() => navigate(`/coachees/profile?id=${row.original.id}`)} sx={{ mr: '12px' }}>
                    <BlendViewIcon />
                  </IconButton>
                </Box>
              )
            },
          },
        ],
      },
    ],
    [],
  )

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Title variant="h6">Linked Coachees</Title>
        <Box>
          <BlendSmallPrimaryButton onClick={() => navigate('/coachees/editor')}>
            Add new referral
          </BlendSmallPrimaryButton>
          <BlendSmallPrimaryButton sx={{ ml: '15px' }} onClick={() => navigate('/coachees')}>
            View all coachees
          </BlendSmallPrimaryButton>
        </Box>
      </Box>
      {coachees && coachees.length > 0 && (
        <Table
          columns={columns}
          data={coachees}
          initialStateOptions={{
            sortBy: [
              {
                id: 'progress',
                desc: false,
              },
            ],
          }}
        />
      )}
      {coachees && !coachees.length && <Typography variant="h6">There are no linked coachees</Typography>}
    </Box>
  )
}
