import { AccountInfo } from '@azure/msal-browser'
import React, { useState } from 'react'
import { useGetSilentToken } from '../hooks/useHandleLogin'

export interface UserContext {
  token?: string
  setToken: React.Dispatch<React.SetStateAction<string | undefined>>
  accountInfo?: AccountInfo
  setAccountInfo: React.Dispatch<React.SetStateAction<AccountInfo | undefined>>
}

export interface UserProviderProps {
  children: React.ReactNode
}

export const UserStateContext = React.createContext<UserContext | undefined>(undefined)

export function UserProvider(props: UserProviderProps): JSX.Element {
  const [token, setToken] = useState<string>()
  const [accountInfo, setAccountInfo] = useState<AccountInfo>()
  return (
    <UserStateContext.Provider value={{ token, setToken, accountInfo, setAccountInfo }}>
      {props.children}
    </UserStateContext.Provider>
  )
}

export const useUserContext = (): UserContext => {
  const context = React.useContext(UserStateContext)

  useGetSilentToken(context)

  if (context === undefined) {
    throw new Error('Ensure useUserState is used within the Provider')
  }
  return context
}
