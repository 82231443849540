import React, { useMemo } from 'react'
import { Box, IconButton, styled, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Client } from '../../../../../types/client_type'
import { Table } from '../../../../../components/Table/table'
import { useDateTimeString } from '../../../../../hooks/useDateTime'
import { BlendSmallPrimaryButton } from '../../../../../components/Common/Buttons'
import { BlendEditIcon, BlendViewIcon } from '../../../../../components/Common/Icons'
import { Cell } from 'react-table'

const Title = styled(Typography)({
  margin: '20px',
})

interface CoacheeTableData {
  id: string
  name: string
  handle: string
  status: string
  lastUpdated: Date
}

export const LinkedContracts: React.FC<Client> = (props) => {
  const navigate = useNavigate()

  const client = props as Client

  const contracts = client.contracts?.map<CoacheeTableData>((contract) => {
    return {
      id: contract.id,
      name: contract.contractName,
      handle: contract.contractHandle,
      lastUpdated: contract.lastUpdated,
      status:
        contract.startDate && new Date(contract.startDate) < new Date()
          ? contract.expirationDate && new Date(contract.expirationDate) > new Date()
            ? 'Active'
            : 'Expired'
          : 'Upcoming',
    }
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Clients',
        columns: [
          {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
          },
          {
            Header: 'Handle',
            id: 'handle',
            accessor: 'handle',
          },
          {
            Header: 'Status',
            id: 'status',
            accessor: 'status',
          },
          {
            Header: 'Last Updated',
            id: 'lastUpdated',
            accessor: 'lastUpdated',
            Cell: ({ row }: Cell<CoacheeTableData>) => useDateTimeString(row.original.lastUpdated),
          },
          {
            Header: '',
            id: 'actions',
            accessor: 'id',
            Cell: ({ row }: Cell<CoacheeTableData>) => {
              return (
                <Box sx={{ float: 'right' }}>
                  <IconButton onClick={() => navigate(`/contracts/profile?id=${row.values.actions}&edit=true`)}>
                    <BlendEditIcon />
                  </IconButton>
                  <IconButton onClick={() => navigate(`/contracts/profile?id=${row.values.actions}`)}>
                    <BlendViewIcon />
                  </IconButton>
                </Box>
              )
            },
          },
        ],
      },
    ],
    [],
  )

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Title variant="h6">Linked Contracts</Title>
        <Box>
          <BlendSmallPrimaryButton onClick={() => navigate(`/contracts/create?clientId=${client.id}`)}>
            Add new contract
          </BlendSmallPrimaryButton>
          <BlendSmallPrimaryButton sx={{ ml: '15px' }} onClick={() => navigate(`/contracts`)}>
            View all contracts
          </BlendSmallPrimaryButton>
        </Box>
      </Box>
      {contracts && contracts.length > 0 && <Table columns={columns} data={contracts} />}
      {contracts && !contracts.length && <Typography variant="h6">There are no linked contracts</Typography>}
    </Box>
  )
}
