import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material'
import React, { useReducer } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ClientProps } from '..'
import { BlendBreadcrumb, BlendBreadcrumbs } from '../../../../components/Common/Breadcrumbs'
import { BlendPrimaryButton, BlendSecondaryButton } from '../../../../components/Common/Buttons'
import { BlendDivider } from '../../../../components/Common/Divider'
import { BlendMenuItem, BlendSelect } from '../../../../components/Common/Select'
import { BlendTextField } from '../../../../components/Common/TextField'
import { EditContact, newContact } from '../../../../components/Contact'
import { useUserContext } from '../../../../providers/User'
import { clientReducer } from '../../../../reducers/clientReducer'
import { Client, GetAllClientsRes } from '../../../../types/client_type'
import { ClientContact } from '../../../../types/contact_types'
import { v4 as uuid } from 'uuid'
import { BlendFullpageSpinner } from '../../../../components/Common/Spinner'
import { clientValidator } from '../../../../validators/clientValidator'
import { useDialogContext } from '../../../../providers/Dialog'
import { createClient, getClients, updateClient } from '../../../../queries'
import { useNavigate } from 'react-router-dom'
import { callbackify } from 'util'

const newClient = (): Client => {
  return {
    id: uuid(),
    name: '',
    isActive: true,
    contacts: [{ ...newContact, id: uuid() }],
  }
}

const EditClient: React.FC<ClientProps> = (props) => {
  const dialog = useDialogContext()
  const context = useUserContext()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const [clientState, setClientState] = useReducer(
    clientReducer,
    props.client
      ? {
          ...props.client,
          contacts: props.client.contacts
            ? [...props.client.contacts, { ...newContact, id: uuid() }]
            : [{ ...newContact, id: uuid() }],
        }
      : newClient(),
  )

  const { data } = useQuery<GetAllClientsRes>({
    queryFn: () => getClients(context),
    queryKey: 'getAllClientsData',
    enabled: !!context.token,
  })

  const mutation = useMutation<unknown, Error>({
    mutationFn: async () =>
      !!props.client
        ? updateClient(context, { ...clientState, parentClient: undefined, contacts: filterLastContact() })
        : createClient(context, { ...clientState, parentClient: undefined, contacts: filterLastContact() }),
    onError: async (error) => {
      await dialog.fetchErrorDialog(JSON.parse(error.message))
    },
    onSuccess: async () => {
      await dialog.openDialog(['Submission successful!'], 2)
      await queryClient.invalidateQueries('getAllClientsData')
      await queryClient.invalidateQueries('getActiveClientsData')
      if (!!props.client) {
        await queryClient.invalidateQueries(['getClientData', clientState.id])
        //await queryClient.setQueryData(['getClientData', data.client.id], data as GetClientRes)
      }
      props.callback()
    },
  })

  const handleEditButton = () => {
    const clientValidation = clientValidator({ ...clientState, contacts: filterLastContact() })
    if (clientValidation.length > 0) {
      dialog.openDialog(clientValidation, 3)
    } else mutation.mutate()
  }

  const handleCancelButton = () => {
    if (props.client) props.callback()
    else navigate('/clients')
  }

  const filterLastContact = () => {
    const contactDetails: ClientContact[] = []
    clientState.contacts?.map((contact, index) => {
      if (
        clientState.contacts &&
        (index < clientState.contacts.length - 1 ||
          (index === clientState.contacts.length - 1 &&
            (contact.firstName || contact.lastName || contact.email || contact.mobile)))
      ) {
        contactDetails.push(contact)
      }
    })
    return contactDetails
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setClientState({
      type: 'STRING',
      field: [e.target.name],
      payload: e.target.value,
    })
  }

  const handleParentChange = (e: SelectChangeEvent<unknown>) => {
    setClientState({
      type: 'PARENT',
      field: [e.target.name],
      payload: e.target.value as string,
    })
  }

  const handleContactCallback = (contact: ClientContact) => {
    setClientState({
      type: 'CONTACT',
      field: [contact.id],
      payload: contact,
    })
  }

  const handleContactButtonCallback = (isCreate: boolean, index?: number) => {
    if (isCreate) {
      setClientState({
        type: 'CREATE_CONTACT',
        field: [],
        payload: { ...newContact, id: uuid() },
      })
    } else if (!isCreate && index != undefined && index > -1) {
      setClientState({
        type: 'DELETE_CONTACT',
        field: [],
        payload: index,
      })
    }
  }

  const breadcrumbs: BlendBreadcrumb[] = [
    { label: 'Client Manager', link: '/clients' },
    { label: clientState.name || 'Create New Client' },
  ]

  return clientState ? (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      sx={{ minHeight: '100%', width: '100%' }}
    >
      <Box>
        <BlendBreadcrumbs breadcrumbs={breadcrumbs} />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h2" mt="20px">
            {!!props.client ? 'Edit client' : 'Create new client'}
          </Typography>
          <Box>
            <BlendSecondaryButton variant={'contained'} sx={{ mr: '11px' }} onClick={handleCancelButton}>
              Cancel
            </BlendSecondaryButton>
            <BlendPrimaryButton variant={'contained'} color={'success'} onClick={handleEditButton}>
              Save
            </BlendPrimaryButton>
          </Box>
        </Box>
        <Box mt="50px">
          <form>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={'20px'}
              md={12}
              lg={10}
              xl={8}
            >
              <Grid item sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                <BlendTextField
                  label="Client Name"
                  required
                  placeholder="Client Name"
                  name="name"
                  value={clientState.name}
                  sx={{ paddingTop: 1 }}
                  onChange={handleTextChange}
                />
              </Grid>
              <Grid item sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                <BlendSelect
                  label="Parent Client"
                  required
                  name="parentClient"
                  value={clientState.parentClientId ?? clientState.parentClient?.id ?? 'n/a'}
                  onChange={handleParentChange}
                  sx={{ marginTop: 1 }}
                >
                  <BlendMenuItem key={'n/a'} value={'n/a'}>
                    Select Parent
                  </BlendMenuItem>
                  {data?.clients.map((client) => {
                    if (client.id != clientState.id)
                      return (
                        <BlendMenuItem key={client.id} value={client.id}>
                          {client.name}
                        </BlendMenuItem>
                      )
                  })}
                </BlendSelect>
              </Grid>
            </Grid>
            <BlendDivider sx={{ paddingTop: '20px', marginBottom: '20px' }} />
            <Typography variant={'h4'}>Contact Details</Typography>
            <Box>
              {clientState.contacts?.map((contact: ClientContact, index: number) => {
                return (
                  <EditContact
                    key={contact.id}
                    contact={contact}
                    index={index}
                    title={index == (clientState.contacts?.length ?? 0) - 1 ? 'New Contact' : undefined}
                    handleCallback={handleContactCallback}
                    handleButtonCallback={handleContactButtonCallback}
                  />
                )
              })}
            </Box>
          </form>
        </Box>
      </Box>
    </Grid>
  ) : (
    <BlendFullpageSpinner />
  )
}

export { EditClient }
