import React from 'react'
import { CoachViewProps } from '../..'
import { ViewContactDetails } from './viewContactDetails'
import { ViewLegalInsurance } from './viewLegalInsurance'
import { ViewAbout } from './viewAbout'
import { ViewCharacteristics } from './viewCharacteristics'

export const ViewCoach: React.FC<CoachViewProps> = (props) => {
  switch (props.tab) {
    case 0:
      return <ViewContactDetails {...props} />
    case 1:
      return <ViewCharacteristics {...props} />
    case 2:
      return <ViewAbout {...props} />
    case 3:
      return <ViewLegalInsurance {...props} />
    default:
      return <>Something went wrong!</>
  }
}
