export interface Questionnaire {
  questionnaireId: string
  name: string
  type: 'Matching' | 'Evaluation' | undefined
}

export interface QuestionGroup {
  id?: string
  groupBreadcrumb: string
  groupTitle?: string
  order?: number
  questions: Question[]
}

export interface Question {
  id: string
  questionText: string
  handle?: string
  isMandatory: boolean
  description?: string
  type: QuestionType
  order?: number
  responseOptions: ResponseOption[]
  error?: boolean
}

export enum QuestionType {
  numeric = 0,
  freeForm = 1,
  longFreeForm = 2,
  singleChoice = 3,
  multipleChoice = 4,
  coachSelect = 5,
}

export interface ResponseOption {
  id: string
  text: string
  description?: string
  value: number
}

export interface ResponseResponse {
  responseId: string
  responseOptionId: string
  questionId: string
  questionText: string
  questionType: string
  groupText: string
  groupBreadcrumb: string
  groupId: string
  value: number
  text: string
  groupOrder: number
  questionOrder: number
}

export interface QuestionnaireResponse {
  text: string
  value: number
  id: string
  responseOptionId: string
  coacheeQuestionnaireId: string
}
