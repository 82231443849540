import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useReducer } from 'react'
import { CoachEditProps } from '../..'
import { BlendDivider } from '../../../../../components/Common/Divider'
import { BlendTextField } from '../../../../../components/Common/TextField'
import { coachReducer } from '../../../../../reducers/coachReducer'
import { Coach } from '../../../../../types/coach_types'

export const EditContactDetails: React.FC<CoachEditProps> = (props: CoachEditProps) => {
  const [coachState, setCoachState] = useReducer(coachReducer, props as Coach)

  useEffect(() => {
    props.callBack(coachState)
  }, [coachState])

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCoachState({
      type: 'STRING',
      field: e.target.name,
      payload: e.target.value,
    })
  }

  return (
    <Box>
      <form>
        <Typography variant={'h6'}>Contact Details</Typography>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ pt: 2, mb: '35px' }}>
          <Grid item>
            <BlendTextField
              required
              label="Email"
              name="email"
              value={coachState.email}
              sx={{ paddingTop: 1 }}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item sx={{ paddingLeft: '40px' }}>
            <BlendTextField
              label="Phone Number"
              required
              name="mobile"
              value={coachState.mobile}
              sx={{ paddingTop: 1 }}
              onChange={handleTextChange}
            />
          </Grid>
        </Grid>
        <BlendDivider />
        <Box sx={{ paddingTop: 3, mb: '35px' }}>
          <BlendTextField
            label="Address"
            name="address"
            multiline
            rows={4}
            value={coachState.address}
            sx={{ paddingTop: 1 }}
            onChange={handleTextChange}
          />
        </Box>
        <BlendDivider />
        <Box sx={{ paddingTop: 3 }}>
          <BlendTextField
            label="Availability"
            name="availability"
            multiline
            rows={4}
            value={coachState.availability}
            sx={{ paddingTop: 1 }}
            onChange={handleTextChange}
          />
        </Box>
      </form>
    </Box>
  )
}
