import { FilterParameterType, ReportProps } from '../../types/report_types'

export const reportsData: ReportProps[] = [
  {
    route: '/report/questionnaires',
    name: 'Evaluation answers',
    description: 'Evaluations by contract, by coach, by coachee, total',
    filterParameterType: FilterParameterType.All,
    filterParameterId: 'All',
    hasAllOption: true,
    noDateFilters: true,
    filterParameterTypeRequired: true,
    fileName: 'Evaluation',
    additionalParameter: '/true',
  },
  {
    route: '/report/questionnaires',
    name: 'Matching questionnaire answers',
    description: 'Questionnaires by contract, by coach, by coachee, total',
    filterParameterType: FilterParameterType.All,
    filterParameterId: 'All',
    hasAllOption: true,
    noDateFilters: true,
    filterParameterTypeRequired: true,
    fileName: 'Questionnaires',
  },
  {
    route: '/report/questionnaires',
    name: 'Matching questionnaire answers (Word)',
    description: 'Questionnaires by contract, by coach, by coachee, total',
    filterParameterType: FilterParameterType.All,
    filterParameterId: 'All',
    hasAllOption: true,
    noDateFilters: true,
    filterParameterTypeRequired: true,
    fileName: 'Questionnaires',
    additionalParameter: '/false/docx',
    format: 'docx',
  },
  {
    route: '/report/contractActivity',
    name: 'Contract activity',
    description: 'Summary of contract activity',
    filterParameterType: FilterParameterType.Client,
    filterParameterRequired: true,
    fileName: 'ContractActivity',
  },
  {
    route: '/report/parentContractActivity',
    name: 'Parent contract activity',
    description: 'Summary of contract activity for all child contracts under the selected parent',
    filterParameterType: FilterParameterType.ParentClient,
    filterParameterRequired: true,
    fileName: 'ParentContractActivity',
  },
  {
    route: '/report/contractActivityPerMonth',
    name: 'Activity per month, by contract',
    description: 'Month-by-month overview activity of a contract',
    filterParameterRequired: true,
    filterParameterType: FilterParameterType.Contract,
    fileName: 'ContractActivityPerMonth',
  },
  {
    route: '/report/coachActivityPerMonth',
    name: 'Activity per month, by coach',
    description: 'Month-by-month overview activity of a coach',
    filterParameterRequired: true,
    filterParameterType: FilterParameterType.Coach,
    fileName: 'CoachActivityPerMonth',
  },
  {
    route: '/report/detailActivityByCoach',
    name: 'Detailed activity by coach',
    description: 'Activity listing for a selected coach',
    filterParameterType: FilterParameterType.Coach,
    hasAllOption: false,
    filterParameterRequired: true,
    fileName: 'DetailActivityByCoach',
  },
  {
    route: '/report/coachCompliance',
    name: 'Coach compliance',
    description: 'Summary of compliance for a signed contract, insurance and any memberships',
    filterParameterType: FilterParameterType.None,
    noFilterParameter: true,
    noDateFilters: true,
    fileName: 'CoachCompliance',
  },
  {
    route: '/report/coachCharacteristics',
    name: 'Coach characteristics',
    description: 'Summary of all coaches by their demographics',
    filterParameterType: FilterParameterType.None,
    noFilterParameter: true,
    noDateFilters: true,
    fileName: 'CoachCharacteristics',
  },
  {
    route: '/report/coacheeStatus',
    name: 'Coachee status report',
    description: 'Status report for all coahcees in a contract',
    filterParameterType: FilterParameterType.Contract,
    filterParameterRequired: true,
    noDateFilters: true,
    fileName: 'CoacheeStatus',
  },
  // {
  //   route: '/report/coachActivityByContractType',
  //   name: 'Coach activity by contract type',
  //   description: 'Number of sessions and minutes completed by coach',
  //   filterParameterType: FilterParameterType.None,
  //   noFilterParameter: true,
  //   fileName: 'CoachActivityByContractType',
  // },
  {
    route: '/report/sessionsPerMonthByCoach',
    name: 'Sessions per month, by coach',
    description: 'Number of sessions per coach, by month within the selected date range',
    filterParameterType: FilterParameterType.None,
    noFilterParameter: true,
    fileName: 'SessionsPerMonthByCoach',
  },
]
