import { Box, Grid, IconButton, SelectChangeEvent, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import { GetAttributeRes, Coach, AttributeType, AttributeBody } from '../../../../../types/coach_types'
import { coachReducer } from '../../../../../reducers/coachReducer'
import { CoachEditProps } from '../..'
import { BlendTextField } from '../../../../../components/Common/TextField'
import { BlendPrimaryButton } from '../../../../../components/Common/Buttons'
import { getAttributes } from '../../../../../queries'
import { useUserContext } from '../../../../../providers/User'
import { useQuery } from 'react-query'
import { BlendDivider } from '../../../../../components/Common/Divider'
import { BlendAttributeButton, BlendSecondaryAttributeButton } from '../../../../../components/Coach'
import theme from '../../../../../theme'
import Color from 'color'
import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faPlusCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuid } from 'uuid'

const colors = [
  theme.multiColor.blue,
  theme.multiColor.teal,
  theme.multiColor.lightGreen,
  theme.multiColor.darkTeal,
  theme.multiColor.dullBlue,
  theme.multiColor.lightBlue,
  theme.multiColor.green,
]

interface CustomTagFieldProps {
  callback: (tag: string) => void
}

const CustomTagField = ({ callback }: CustomTagFieldProps) => {
  const [customState, setCustomState] = useState<string>()

  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center', marginTop: '20px' }}>
      <Typography>Custom Tag</Typography>
      <BlendTextField
        sx={{ marginLeft: '20px' }}
        value={customState}
        onChange={(e) => setCustomState(e.target.value)}
      ></BlendTextField>
      <Tooltip arrow placement="right" title="Add">
        <IconButton sx={{ marginLeft: '10px' }} onClick={() => customState && callback(customState)}>
          <FontAwesomeIcon color={theme.blend.green} size="1x" icon={faPlusCircle} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

export const EditCharacteristics: React.FC<CoachEditProps> = (props: CoachEditProps) => {
  const context = useUserContext()

  const [coachState, setCoachState] = useReducer(coachReducer, props as Coach)

  useEffect(() => {
    props.callBack(coachState)
  }, [coachState])

  const { data } = useQuery<GetAttributeRes>({
    queryFn: () => getAttributes(context),
    queryKey: ['attributes'],
    enabled: !!context.token,
  })

  const [openCustom, setOpenCustom] = useState<number>()

  return (
    <Box sx={{ width: '100%' }}>
      <form>
        {Object.values(AttributeType).map((title, index) => {
          return (
            <>
              <BlendDivider sx={{ paddingTop: '20px', marginBottom: '20px' }} />
              <Grid container key={title} direction="row" sx={{ width: '100%' }}>
                <Grid item md={3} sm={12}>
                  <Typography>{title}</Typography>
                </Grid>
                <Grid item md={9} sm={12} spacing="10px">
                  <Grid container spacing="10px">
                    {data?.attributeTags
                      ?.filter((attribute) => Object.keys(AttributeType).indexOf(attribute.type) === index)
                      .sort((a, b) => a.text.localeCompare(b.text))
                      .map((attribute, attrindex) => {
                        const isActive = coachState.attributes?.some((coachAttr) => coachAttr.id === attribute.id)
                        return (
                          <Grid item key={`${title}-${attrindex}`}>
                            <BlendAttributeButton
                              primaryColor={
                                isActive === true ? colors[index] : Color(colors[index]).alpha(0.5).string()
                              }
                              onClick={() => {
                                setCoachState({
                                  type: 'ATTRIBUTE_TAG',
                                  field: '',
                                  payload: { ...attribute },
                                })
                              }}
                              endIcon={
                                isActive ? (
                                  <FontAwesomeIcon icon={faCheckCircle as IconProp} />
                                ) : (
                                  <FontAwesomeIcon icon={faCircle as IconProp} />
                                )
                              }
                            >
                              {attribute.text}
                            </BlendAttributeButton>
                          </Grid>
                        )
                      })}
                    {coachState.attributes
                      ?.filter(
                        (attribute) =>
                          Object.keys(AttributeType).indexOf(attribute.type) === index && attribute.isCustom,
                      )
                      .sort((a, b) => a.text.localeCompare(b.text))
                      .map((attribute, attrindex) => {
                        const isActive = coachState.attributes?.some((coachAttr) => coachAttr.id === attribute.id)
                        return (
                          <Grid item key={`${title}-${attrindex}`}>
                            <BlendSecondaryAttributeButton
                              primaryColor={
                                isActive === true ? colors[index] : Color(colors[index]).alpha(0.5).string()
                              }
                              onClick={() => {
                                setCoachState({
                                  type: 'ATTRIBUTE_TAG',
                                  field: '',
                                  payload: { ...attribute },
                                })
                              }}
                              startIcon={<FontAwesomeIcon icon={faCircleXmark as IconProp} />}
                            >
                              {attribute.text}
                            </BlendSecondaryAttributeButton>
                          </Grid>
                        )
                      })}
                    <Grid item>
                      <BlendSecondaryAttributeButton
                        primaryColor={colors[index]}
                        isCustom
                        onClick={() => setOpenCustom(index)}
                        startIcon={<FontAwesomeIcon color={colors[index]} icon={faPlusCircle} />}
                      >
                        {'Custom tag'}
                      </BlendSecondaryAttributeButton>
                    </Grid>
                  </Grid>
                  {openCustom === index ? (
                    <CustomTagField
                      callback={(tag) => {
                        setCoachState({
                          type: 'ATTRIBUTE_TAG',
                          field: '',
                          payload: {
                            id: uuid(),
                            type: Object.keys(AttributeType)[index],
                            text: tag,
                            isCustom: true,
                          } as AttributeBody,
                        })
                        setOpenCustom(undefined)
                      }}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </>
          )
        })}
      </form>
    </Box>
  )
}
