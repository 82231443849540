import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { BlendPrimaryButton, BlendSecondaryButton } from '../../../../components/Common/Buttons'
import { useDateString } from '../../../../hooks/useDateTime'
import { useDialogContext } from '../../../../providers/Dialog'
import { useUserContext } from '../../../../providers/User'
import { postCoacheeQuestionnaire } from '../../../../queries'
import theme from '../../../../theme'
import { ShortCoachResBody } from '../../../../types/coach_types'
import { CoachCard } from '../../../coaches/coachCard'

interface CoachTabProps {
  coacheeId: string
  coacheeName: string
  coacheeQuestionnaires: {
    coacheeQuestionnaireId: string
    sent: Date
    completed: Date
    status: string
    questionnaire: {
      questionnaireId: string
      name: string
      type: string
    }
  }[]
  coaches: ShortCoachResBody[]
}

export const CoachTab: React.FC<CoachTabProps> = ({ coacheeId, coacheeName, coacheeQuestionnaires, coaches }) => {
  const navigate = useNavigate()
  const context = useUserContext()
  const dialog = useDialogContext()
  const queryClient = useQueryClient()

  const mutation = useMutation<unknown, Error>({
    mutationFn: () => postCoacheeQuestionnaire(context, coacheeId),
    onSuccess: async () => {
      await queryClient.refetchQueries(['getCoacheeData', coacheeId])
      window.location.reload()
    },
    onError: async (error) => await dialog.fetchErrorDialog(JSON.parse(error.message)),
  })

  const assignNewCoachHandler = () =>
    navigate(`/coachees/referral?id=${coacheeQuestionnaires[0].coacheeQuestionnaireId}`)

  const coachCardHandler = () => coaches[0] && navigate(`/coaches/profile?id=${coaches[0].id}`)

  const viewMatchesHandler = () => navigate(`/coachees/referral?id=${coacheeQuestionnaires[0].coacheeQuestionnaireId}`)

  const viewResponsesHandler = () =>
    navigate(`/questionnaire/view?id=${coacheeQuestionnaires[0].coacheeQuestionnaireId}`)

  const sendNewQuestionnaireHandler = async () =>
    dialog.openConfirmDialog(
      `Are you sure you want to send a new matching questionnaire to ${coacheeName}?`,
      async () => await mutation.mutate(),
    )

  const getStatus = () => {
    switch (coacheeQuestionnaires[0].status) {
      case 'Sent':
        return 'Sent'
      case 'Reminder1':
        return 'Reminder 1'
      case 'Reminder2':
        return 'Reminder 2'
      case 'Reminder3':
        return 'Reminder 3'
      case 'Escalation':
        return <span style={{ fontWeight: 'bold', color: theme.blend.warning }}>Escalation</span>
      default:
        return 'Sent'
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      {coaches && coaches.length > 0 ? (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h4">Assigned coaches</Typography>
            <Box sx={{ display: 'flex', direction: 'row' }}>
              <BlendPrimaryButton onClick={assignNewCoachHandler}>Assign new Coach</BlendPrimaryButton>
              <BlendSecondaryButton sx={{ marginLeft: '20px' }} onClick={sendNewQuestionnaireHandler}>
                Send new questionnaire
              </BlendSecondaryButton>
            </Box>
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="stretch"
            margin="20px 20px 20px 0"
            height="auto"
          >
            <Grid item sm={12} md={6} sx={{ height: '100%' }}>
              <CoachCard {...coaches[0]} onClick={coachCardHandler} />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {coacheeQuestionnaires && coacheeQuestionnaires.length > 0 ? (
            !!coacheeQuestionnaires[0].completed ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography>Questionnaire returned, ready to generate matches.</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'center',
                    marginTop: '20px',
                  }}
                >
                  <BlendPrimaryButton onClick={viewMatchesHandler}>View matches</BlendPrimaryButton>
                  <BlendPrimaryButton sx={{ marginLeft: '20px' }} onClick={viewResponsesHandler}>
                    View responses
                  </BlendPrimaryButton>
                  <BlendSecondaryButton sx={{ marginLeft: '20px' }} onClick={sendNewQuestionnaireHandler}>
                    Send new questionnaire
                  </BlendSecondaryButton>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography>
                  The matching questionnaire was sent on {useDateString(coacheeQuestionnaires[0].sent)} and is awaiting
                  completion.
                </Typography>
                <Typography sx={{ marginTop: '20px' }}>Questionnaire status: {getStatus()}</Typography>
                <BlendSecondaryButton sx={{ marginTop: '20px' }} onClick={sendNewQuestionnaireHandler}>
                  Send new questionnaire
                </BlendSecondaryButton>
              </Box>
            )
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography>There are no coaches or questionnaires associated with the coachee.</Typography>
              <BlendSecondaryButton sx={{ marginTop: '20px' }} onClick={sendNewQuestionnaireHandler}>
                Send new questionnaire
              </BlendSecondaryButton>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
