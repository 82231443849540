import React from 'react'
import { CoachEditProps } from '../..'
import { EditContactDetails } from './editContactDetails'
import { EditLegalInsurance } from './editLegalInsurance'
import { EditAbout } from './editAbout'
import { EditCharacteristics } from './editCharacteristics'

export const EditCoach: React.FC<CoachEditProps> = (props) => {
  switch (props.tab) {
    case 0:
      return <EditContactDetails {...props} />
    case 1:
      return <EditCharacteristics {...props} />
    case 2:
      return <EditAbout {...props} />
    case 3:
      return <EditLegalInsurance {...props} />
    default:
      return <>Something went wrong!</>
  }
}
