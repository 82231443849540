import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import {
  AgeRanges,
  AttributeBody,
  CaringResponsibilities,
  Coach,
  Disabled,
  Ethnicity,
  Gender,
} from '../../../../../types/coach_types'
import { coachReducer } from '../../../../../reducers/coachReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid'
import { CoachEditProps } from '../..'
import { BlendSelect, BlendMenuItem } from '../../../../../components/Common/Select'
import { BlendTextField } from '../../../../../components/Common/TextField'
import { BlendDatePicker } from '../../../../../components/Common/DatePicker'
import { BlendDivider } from '../../../../../components/Common/Divider'

// TO-DO: Get all tags from the backend
export const EditAbout: React.FC<CoachEditProps> = (props: CoachEditProps) => {
  const [coachState, setCoachState] = useReducer(coachReducer, props as Coach)

  useEffect(() => {
    props.callBack(coachState)
  }, [coachState])

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCoachState({
      type: 'STRING',
      field: e.target.name,
      payload: e.target.value,
    })
  }

  const handleSelectChange = (e: SelectChangeEvent<unknown>) => {
    setCoachState({
      type: 'SELECT',
      field: e.target.name,
      payload: e.target.value as Gender | Ethnicity,
    })
  }

  const handleDateChange = (date: Date | null) => {
    if (date)
      setCoachState({
        type: 'DATE',
        field: 'dateOfBirth',
        payload: date,
      })
  }

  return (
    <Box sx={{ width: '100%' }}>
      <form>
        <Typography variant={'h6'}>Profile</Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ paddingTop: '20px', margin: '0', maxWidth: '1500px' }}
        >
          <Grid item xs>
            <BlendTextField
              label="Biography"
              type="text"
              name="bio"
              multiline
              rows={6}
              value={coachState.bio}
              sx={{ paddingTop: 1, height: '100%', width: '100%' }}
              onChange={handleTextChange}
            />
          </Grid>
        </Grid>
        <BlendDivider sx={{ paddingTop: '35px' }} />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing="20px"
          sx={{ paddingTop: 2, maxWidth: '1500px' }}
        >
          <Grid item sm={6} md={4}>
            <BlendSelect
              required
              width="100%"
              label="Gender"
              name="gender"
              value={coachState.gender ? Object.keys(Gender)[Object.keys(Gender).indexOf(coachState.gender)] : ''}
              renderValue={() => (coachState.gender ? Gender[coachState.gender as keyof typeof Gender].valueOf() : '')}
              onChange={handleSelectChange}
            >
              {Object.keys(Gender).map((key, index) => (
                <BlendMenuItem key={`gender-${index}`} value={key}>
                  {Gender[key as keyof typeof Gender].valueOf()}
                </BlendMenuItem>
              ))}
            </BlendSelect>
          </Grid>
          <Grid item sm={6} md={4}>
            <BlendSelect
              required
              width="100%"
              label="Ethnicity"
              name="ethnicity"
              value={
                coachState.ethnicity ? Object.keys(Ethnicity)[Object.keys(Ethnicity).indexOf(coachState.ethnicity)] : ''
              }
              renderValue={() =>
                coachState.ethnicity ? Ethnicity[coachState.ethnicity as keyof typeof Ethnicity].valueOf() : ''
              }
              onChange={handleSelectChange}
            >
              {Object.keys(Ethnicity).map((key, index) => (
                <BlendMenuItem key={`ethnicity-${index}`} value={key}>
                  {Ethnicity[key as keyof typeof Ethnicity].valueOf()}
                </BlendMenuItem>
              ))}
            </BlendSelect>
          </Grid>
          <Grid item sm={6} md={4}>
            <BlendSelect
              required
              width="100%"
              label="Age"
              name="ageRange"
              value={
                coachState.ageRange ? Object.keys(AgeRanges)[Object.keys(AgeRanges).indexOf(coachState.ageRange)] : ''
              }
              renderValue={() =>
                coachState.ageRange ? AgeRanges[coachState.ageRange as keyof typeof AgeRanges].valueOf() : ''
              }
              onChange={handleSelectChange}
            >
              {Object.keys(AgeRanges).map((key, index) => (
                <BlendMenuItem key={`ageRange-${index}`} value={key}>
                  {AgeRanges[key as keyof typeof AgeRanges].valueOf()}
                </BlendMenuItem>
              ))}
            </BlendSelect>
          </Grid>
          <Grid item sm={6} md={4}>
            <BlendSelect
              required
              width="100%"
              label="Disability"
              name="disabled"
              value={
                coachState.disabled ? Object.keys(Disabled)[Object.keys(Disabled).indexOf(coachState.disabled)] : ''
              }
              renderValue={() =>
                coachState.disabled ? Disabled[coachState.disabled as keyof typeof Disabled].valueOf() : ''
              }
              onChange={handleSelectChange}
            >
              {Object.keys(Disabled).map((key, index) => (
                <BlendMenuItem key={`disabled-${index}`} value={key}>
                  {Disabled[key as keyof typeof Disabled].valueOf()}
                </BlendMenuItem>
              ))}
            </BlendSelect>
          </Grid>
          <Grid item sm={6} md={4}>
            <BlendSelect
              required
              width="100%"
              label="Caring responsibilities"
              name="caringResponsibilities"
              value={
                coachState.caringResponsibilities
                  ? Object.keys(CaringResponsibilities)[
                      Object.keys(CaringResponsibilities).indexOf(coachState.caringResponsibilities)
                    ]
                  : ''
              }
              renderValue={() =>
                coachState.caringResponsibilities
                  ? CaringResponsibilities[
                      coachState.caringResponsibilities as keyof typeof CaringResponsibilities
                    ].valueOf()
                  : ''
              }
              onChange={handleSelectChange}
            >
              {Object.keys(CaringResponsibilities).map((key, index) => (
                <BlendMenuItem key={`caringResponsibilities-${index}`} value={key}>
                  {CaringResponsibilities[key as keyof typeof CaringResponsibilities].valueOf()}
                </BlendMenuItem>
              ))}
            </BlendSelect>
          </Grid>
          <Grid item sm={6} md={4}>
            <BlendTextField disabled label="Coach Type" name="type" value={'One-to-one'} />
          </Grid>
        </Grid>
        <BlendDivider sx={{ paddingTop: '35px' }} />
      </form>
    </Box>
  )
}
