import React, { useMemo } from 'react'
import { Box, Typography, styled, IconButton, Grid } from '@mui/material'
import { useUserContext } from '../../providers/User'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Table } from '../../components/Table/table'
import { useNavigate } from 'react-router-dom'
import { GetAllContractsRes } from '../../types/contract_type'
import { BlendBinIcon, BlendEditIcon, BlendViewIcon } from '../../components/Common/Icons'
import { BlendPrimaryButton } from '../../components/Common/Buttons'
import { BlendFullpageSpinner } from '../../components/Common/Spinner'
import theme from '../../theme'
import { deleteContract, getContracts } from '../../queries'
import { MetricSquare } from '../../components/Common/MetricSquare'
import { getContractStatus, useDateTimeString } from '../../hooks/useDateTime'
import { useDialogContext } from '../../providers/Dialog'
import { Cell } from 'react-table'

const Title = styled(Typography)({
  margin: '20px',
})

interface ContractTableData {
  id: string
  contractHandle: string
  contractName: string
  clientId: string
  clientName: string
  status: string
  lastUpdated: Date
  coacheesCount: number
}

export const ContractManager: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const context = useUserContext()
  const dialog = useDialogContext()

  const { isFetching, error, data } = useQuery<GetAllContractsRes>({
    queryFn: () => getContracts(context),
    queryKey: 'getAllContractsData',
    enabled: !!context.token,
    refetchOnMount: 'always',
  })

  const mutation = useMutation({
    mutationFn: (id: string) => deleteContract(context, id),
    onError: (error) => console.error(error),
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllContractsData')
    },
  })

  const handleDelete = (id: string, name: string) => {
    dialog.openDeleteDialog(name, () => {
      mutation.mutate(id)
    })
  }

  const tableData = data?.contracts.map<ContractTableData>((contract) => {
    return {
      id: contract.id,
      contractHandle: contract.contractHandle,
      contractName: contract.contractName,
      clientId: contract.clientId,
      clientName: contract.clientName,
      status: getContractStatus(contract.startDate, contract.expirationDate).valueOf(),
      lastUpdated: contract.lastUpdated,
      coacheesCount: contract.coacheesCount,
    }
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Contracts',
        columns: [
          {
            Header: 'Contract Name',
            id: 'contractName',
            accessor: 'contractName',
          },
          {
            Header: 'Contract Handle',
            id: 'contractHandle',
            accessor: 'contractHandle',
          },
          {
            Header: 'Client',
            id: 'clientName',
            accessor: 'clientName',
          },
          {
            Header: 'Coachees',
            id: 'coacheesCount',
            accessor: 'coacheesCount',
          },
          {
            Header: 'Status',
            id: 'status',
            accessor: 'status',
          },
          {
            Header: 'Last Updated',
            id: 'lastUpdated',
            accessor: 'lastUpdated',
            Cell: ({ row }: Cell<ContractTableData>) => useDateTimeString(row.original.lastUpdated),
          },
          {
            Header: '',
            id: 'actions',
            accessor: 'id',
            Cell: ({ row }: Cell<ContractTableData>) => {
              return (
                <Box sx={{ float: 'right' }}>
                  <IconButton onClick={() => navigate(`/contracts/profile?id=${row.values.actions}&edit=true`)}>
                    <BlendEditIcon />
                  </IconButton>
                  <IconButton onClick={() => navigate(`/contracts/profile?id=${row.values.actions}`)}>
                    <BlendViewIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(row.values.actions, row.original.contractName)}>
                    <BlendBinIcon />
                  </IconButton>
                </Box>
              )
            },
          },
        ],
      },
    ],
    [],
  )

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Title variant="h2">Contract Manager</Title>
        <BlendPrimaryButton onClick={() => navigate(`/contracts/create`)}>Create new contract</BlendPrimaryButton>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing="20px"
        sx={{ marginBottom: '40px', marginTop: '20px' }}
      >
        <Grid item sm={6} md={3}>
          <MetricSquare
            bgcolor={theme.blend.blue}
            number={data?.fiftyPercentLeft ?? 0}
            tagline="contracts quotas with 50% remaining"
            notDashboard
          />
        </Grid>
        <Grid item sm={6} md={3}>
          <MetricSquare
            bgcolor={theme.blend.blue}
            number={data?.twentyFivePercentLeft ?? 0}
            tagline="contracts quotas with 25% remaining"
            notDashboard
          />
        </Grid>
        <Grid item sm={6} md={3}>
          <MetricSquare
            bgcolor={theme.blend.teal}
            number={data?.activeContracts ?? 0}
            tagline="active contracts"
            notDashboard
          />
        </Grid>
        <Grid item sm={6} md={3}>
          <MetricSquare
            bgcolor={theme.blend.green}
            number={data?.exhaustedQuotas ?? 0}
            tagline="contract quotas exhausted"
            notDashboard
          />
        </Grid>
      </Grid>
      {error ? <h1>Could not fetch Contracts</h1> : null}
      {!error && data && tableData && !tableData.length ? <h1>There are no Contracts</h1> : null}
      {!isFetching && data && tableData && tableData.length ? (
        <Table columns={columns} data={tableData} searchLabel="Search" />
      ) : !error && !data ? (
        <BlendFullpageSpinner />
      ) : null}
    </Box>
  )
}
