import { ContactsTwoTone } from '@mui/icons-material'
import { Box, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { BlendFullpageSpinner } from '../../../../components/Common/Spinner'
import { ShortCoachResBody } from '../../../../types/coach_types'
import { CoacheeContact } from '../../../../types/contact_types'

interface DetailsTabProps {
  coachee: {
    id: string
    contractId: string
    contractName: string
    contractHandle: string
    clientId: string
    clientName: string
    statusStep: number
  }
  contacts: CoacheeContact[]
  coaches: ShortCoachResBody[]
  sessionCount: number
}

export const DetailsTab: React.FC<DetailsTabProps> = ({ coachee, contacts, coaches, sessionCount }) => {
  const delegateContact = useMemo(() => {
    return contacts.filter((contact) => contact.isDelegate)[0] ?? undefined
  }, [contacts])

  const primaryContact = useMemo(() => {
    return contacts.filter((contact) => contact.isPrimary && !contact.isDelegate)[0] ?? undefined
  }, [contacts])

  return (
    <Box sx={{ width: '100%' }}>
      {coachee && contacts && contacts.length ? (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing="20px"
          sx={{ height: '100%' }}
        >
          {delegateContact && (
            <Grid item xs={4}>
              <Card sx={{ width: '100%', height: '100%', padding: '20px', backgroundColor: 'rgba(0, 167, 157, 0.15)' }}>
                <CardContent>
                  <Typography variant="h3">Coachee Details</Typography>
                  <Typography variant="h6" sx={{ marginTop: '20px' }}>
                    Name
                  </Typography>
                  <Typography variant="body1">{`${delegateContact.firstName} ${delegateContact.lastName}`}</Typography>
                  <Typography variant="h6" sx={{ marginTop: '20px' }}>
                    Email
                  </Typography>
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigator.clipboard.writeText(delegateContact?.email || '')
                      alert('Email address copied!')
                    }}
                  >
                    <Typography noWrap variant="body1">
                      {delegateContact.email}
                    </Typography>
                  </Box>
                  <Typography variant="h6" sx={{ marginTop: '20px' }}>
                    Telephone
                  </Typography>
                  <Typography variant="body1">{delegateContact.mobile}</Typography>
                  {delegateContact.alternativeEmail && (
                    <>
                      <Typography variant="h6" sx={{ marginTop: '20px' }}>
                        Alternative Email
                      </Typography>
                      <Typography>{delegateContact.alternativeEmail}</Typography>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}
          {primaryContact && (
            <Grid item xs={4}>
              <Card sx={{ width: '100%', height: '100%', padding: '20px', backgroundColor: 'rgba(23, 99, 175, 0.15)' }}>
                <CardContent>
                  <Typography variant="h3">Secondary Contact Details</Typography>
                  <Typography variant="h6" sx={{ marginTop: '20px' }}>
                    Name
                  </Typography>
                  <Typography variant="body1">{`${primaryContact.firstName} ${primaryContact.lastName}`}</Typography>
                  <Typography variant="h6" sx={{ marginTop: '20px' }}>
                    Email
                  </Typography>
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigator.clipboard.writeText(primaryContact?.email || '')
                      alert('Email address copied!')
                    }}
                  >
                    <Typography noWrap variant="body1">
                      {primaryContact.email}
                    </Typography>
                  </Box>
                  {/* <Typography variant="body1">{primaryContact.email}</Typography> */}
                  <Typography variant="h6" sx={{ marginTop: '20px' }}>
                    Telephone
                  </Typography>
                  <Typography variant="body1">{primaryContact.mobile}</Typography>
                  {primaryContact.alternativeEmail && (
                    <>
                      <Typography variant="h6" sx={{ marginTop: '20px' }}>
                        Alternative Email
                      </Typography>
                      <Typography>{primaryContact.alternativeEmail}</Typography>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid item xs={4}>
            <Card sx={{ width: '100%', height: '100%', padding: '20px', backgroundColor: 'rgba(156, 203, 59, 0.15)' }}>
              <CardContent>
                <Typography variant="h3">Coaching Details</Typography>
                <Typography variant="h6" sx={{ marginTop: '20px' }}>
                  Client
                </Typography>
                <Typography variant="body1">{coachee.clientName}</Typography>
                <Typography variant="h6" sx={{ marginTop: '20px' }}>
                  Contract
                </Typography>
                <Typography variant="body1">{`${coachee.contractName} (${coachee.contractHandle})`}</Typography>
                <Typography variant="h6" sx={{ marginTop: '20px' }}>
                  Total Sessions
                </Typography>
                <Typography variant="body1">{sessionCount}</Typography>
                {coaches && (
                  <>
                    <Typography variant="h6" sx={{ marginTop: '20px' }}>
                      Assigned coach{coaches.length > 1 && 'es'}
                    </Typography>
                    {coaches.map((coach, index) => {
                      return (
                        <Typography key={coach.id}>{`${coach.firstName} ${coach.lastName}${
                          index < coaches.length - 1 ? ', ' : ''
                        }`}</Typography>
                      )
                    })}
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <BlendFullpageSpinner />
      )}
    </Box>
  )
}
