import React, { useState } from 'react'
import { BlendDialog } from '../components/Common/Dialog'
import { ErrorRes } from '../types/response_body'

interface UseDialogProps {
  openDialog: (message: string[], status?: number | undefined) => Promise<unknown>
  openDeleteDialog: (name: string, callback: () => void) => Promise<void>
  openConfirmDialog: (message: string, callback: () => Promise<void>, noConfirm?: boolean) => Promise<void>
  setDialog: (message: string[], status?: number | undefined) => void
  resolveDialog: () => void
  rejectDialog: () => void
  errorDialog: (message: string) => Promise<void>
  fetchErrorDialog: (error: ErrorRes) => Promise<void>
  children: string | JSX.Element | undefined
  status: number
  isOpen: boolean
}

const useDialog = (): UseDialogProps => {
  const [childrenState, setChildrenState] = useState<string | JSX.Element>()
  const [statusState, setStatusState] = useState(0)
  const [openState, setOpenState] = useState(false)
  const [promiseFuncs, setPromiseFuncs] = useState<(() => void)[]>()

  const convertStringArr = (arr: string | string[]): string | JSX.Element | undefined =>
    typeof arr === 'string' ? (
      arr
    ) : arr.length > 1 ? (
      <>
        There were the following errors:
        <ul>
          {arr.map((str, index) => (
            <li key={index}>{str}</li>
          ))}
        </ul>
      </>
    ) : (
      arr[0]
    )

  const openDialog = async (message: string | string[], status?: number) => {
    return new Promise((resolve, reject) => {
      setStatusState(status ?? 0)
      setChildrenState(convertStringArr(message))
      setOpenState(true)
      setPromiseFuncs([resolve, reject])
    })
  }

  const setDialog = (message: string | string[], status?: number) => {
    setStatusState(status ?? statusState + 1)
    setChildrenState(convertStringArr(message))
  }

  const resolveDialog = () => {
    setOpenState(false)
    if (promiseFuncs) promiseFuncs[0]()
  }

  const rejectDialog = () => {
    setOpenState(false)
    if (promiseFuncs) promiseFuncs[1]()
  }

  const openDeleteDialog = async (name: string, callback: () => void) => {
    openDialog([`Are you sure you want to delete ${name}`], 4)
      .then(async () => {
        callback()
        openDialog([`${name} Deleted`], 5)
      })
      .catch(() => openDialog([`Operation cancelled`], 3))
  }

  const openConfirmDialog = async (message: string, callback: () => Promise<void>, noConfirm?: boolean) => {
    openDialog([message], 1)
      .then(async () => {
        callback()
          .then(async () => !noConfirm && openDialog([`Operation completed`], 2))
          .catch((reason) => {
            console.error(reason)
            openDialog([`Operation failed: ${reason}`], 5)
          })
      })
      .catch(() => openDialog([`Operation cancelled`], 3))
  }

  const errorDialog = async (message: string | string[]) => {
    if (openState) {
      setDialog(message, 5)
    } else {
      await openDialog(message, 5)
    }
  }

  const fetchErrorDialog = async (error: ErrorRes) => {
    const messages: string[] = []
    for (const key in error.errors) {
      const messageArr = error.errors[key]
      messageArr.map((message) => messages.push(message))
    }
    await openDialog(messages, 3)
  }

  return {
    openDialog,
    openDeleteDialog,
    openConfirmDialog,
    setDialog,
    resolveDialog,
    rejectDialog,
    errorDialog,
    fetchErrorDialog,
    children: childrenState,
    status: statusState,
    isOpen: openState,
  }
}

interface DialogProviderProps {
  children: React.ReactNode
}

const DialogStateContext = React.createContext<UseDialogProps | undefined>(undefined)

export const DialogProvider = (props: DialogProviderProps): JSX.Element => {
  const dialog = useDialog()
  return (
    <DialogStateContext.Provider value={dialog}>
      <BlendDialog {...dialog} />
      {props.children}
    </DialogStateContext.Provider>
  )
}

export const useDialogContext = (): UseDialogProps => {
  const dialog = React.useContext(DialogStateContext)

  if (dialog) return dialog
  else throw new Error('Something went terribly wrong')
}
