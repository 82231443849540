import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const Redirect: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/dashboard', { replace: true })
  }, [])

  return <>Redirecting...</>
}
