import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useUserContext } from '../../../providers/User'
import { Contract, ContractCoachee, ContractSession, GetContractRes } from '../../../types/contract_type'
import { ClientContact } from '../../../types/contact_types'
import { EditContract } from './edit/editContract'
import { ViewContract } from './view/viewContract'
import { BlendFullpageSpinner } from '../../../components/Common/Spinner'
import { getContract } from '../../../queries'

export interface ContractProps {
  contract: Contract
  clientId?: string
  callback: () => void
  refetch?: () => Promise<unknown>
}

export const ContractProfile: React.FC = () => {
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  const id: string | null = urlParams.get('id')
  const isEdit: string | null = urlParams.get('edit')
  const context = useUserContext()
  const [isEditState, setIsEditState] = useState(isEdit && isEdit === 'true' ? true : false)

  const { isFetching, data } = useQuery<GetContractRes>({
    queryFn: () => getContract(context, id),
    queryKey: ['getContractData', id],
    enabled: !!context.token,
    refetchOnMount: 'always',
    onSuccess: (data) => {
      setContractState({
        ...data.contract,
        officeUserId: data.manager['officeManagerId'],
        officeUser: { ...data.manager, id: data.manager['officeManagerId'] },
        clientId: data.client['clientId'],
        client: { ...data.client, id: data.client['clientId'] },
        coachees: data.coachees as ContractCoachee[],
        sessions: data.sessions as ContractSession[],
        contacts: data.contacts as ClientContact[],
      })
    },
  })

  const [contractState, setContractState] = useState<Contract | undefined>(() => {
    if (data)
      return {
        ...data.contract,
        officeUserId: data.manager['officeManagerId'],
        officeUser: { ...data.manager, id: data.manager['officeManagerId'] },
        clientId: data.client['clientId'],
        client: { ...data.client, id: data.client['clientId'] },
        coachees: data.coachees as ContractCoachee[],
        sessions: data.sessions as ContractSession[],
        contacts: data.contacts as ClientContact[],
      }
    else return undefined
  })

  return contractState && !isFetching ? (
    !isEditState ? (
      <ViewContract contract={contractState} callback={() => setIsEditState(!isEditState)} />
    ) : (
      <EditContract
        contract={contractState}
        callback={() => {
          setIsEditState(!isEditState)
        }}
      />
    )
  ) : (
    <BlendFullpageSpinner />
  )
}
