import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { EditClient } from './edit/editClient'
import { useUserContext } from '../../../providers/User'
import { Client, GetClientRes } from '../../../types/client_type'
import { ViewClient } from './view/viewClient'
import { BlendFullpageSpinner } from '../../../components/Common/Spinner'
import { getClient } from '../../../queries'

export interface ClientProps {
  client?: Client
  callback: () => void
}

export const ClientProfile: React.FC = () => {
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  const id: string | null = urlParams.get('id')
  const isEdit: string | null = urlParams.get('edit')
  const context = useUserContext()
  const [isEditState, setIsEditState] = useState(isEdit && isEdit === 'true' ? true : false)

  const { isFetching, data } = useQuery<GetClientRes>({
    queryFn: () => getClient(context, id),
    queryKey: ['getClientData', id],
    enabled: !!context.token,
    onSuccess: (data) => {
      setClientState({
        id: data.client.id,
        name: data.client.name,
        isActive: data.client.isActive,
        parentClient: { ...data.parentClient },
        contacts: data.contacts,
        contracts: data.contracts,
      })
    },
  })

  const [clientState, setClientState] = useState<Client | undefined>(() => {
    if (data)
      return {
        id: data.client.id,
        name: data.client.name,
        isActive: data.client.isActive,
        parentClient: { ...data.parentClient, isActive: true },
        contacts: data.contacts,
        contracts: data.contracts,
      }
    else return undefined
  })

  return clientState && !isFetching ? (
    !isEditState ? (
      <ViewClient client={clientState} callback={() => setIsEditState(!isEditState)} />
    ) : (
      <EditClient
        client={clientState}
        callback={async () => {
          //await refetch()
          setIsEditState(!isEditState)
        }}
      />
    )
  ) : (
    <BlendFullpageSpinner />
  )
}
