import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { BlendDivider } from '../../../../../components/Common/Divider'
import theme from '../../../../../theme'
import {
  AgeRanges,
  AttributeBody,
  CaringResponsibilities,
  Coach,
  Disabled,
  Ethnicity,
  Gender,
} from '../../../../../types/coach_types'

interface AboutStatProps {
  label: string
  value: string
}

const AboutStat = ({ label, value }: AboutStatProps) => (
  <Grid item sm={12} md={6} sx={{ height: '70px' }}>
    <Typography fontWeight={'fontWeightBold'}>{label}</Typography>
    <Typography>{value}</Typography>
  </Grid>
)

export const ViewAbout: React.FC<Coach> = (props) => {
  const getAge = (input: Date) => {
    const dob: Date = new Date(input)
    const today: Date = new Date()
    let age = today.getFullYear() - dob.getFullYear()
    const monthDiff = today.getMonth() - dob.getMonth()
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) age--
    return age
  }

  return (
    <Box>
      <Typography variant={'h6'}>Profile</Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        sx={{ paddingTop: '20px', margin: '0' }}
      >
        {props.bio ? (
          <Grid
            item
            xs={6}
            sx={{ minHeight: '100%', borderRight: `1px solid ${theme.blend.lightGrey}`, paddingRight: '40px' }}
          >
            <Typography fontWeight={'fontWeightBold'}>Bio</Typography>
            <Typography>{props.bio}</Typography>
          </Grid>
        ) : null}
        <Grid item xs={6} sx={{ paddingLeft: props.bio ? '40px' : '0px', justifyContent: 'flex-start' }}>
          <Grid container direction="row">
            <AboutStat
              label={'Gender'}
              value={props.gender ? Object.values(Gender)[Object.keys(Gender).indexOf(props.gender)] : ''}
            />
            <AboutStat
              label={'Ethnicity'}
              value={props.ethnicity ? Object.values(Ethnicity)[Object.keys(Ethnicity).indexOf(props.ethnicity)] : ''}
            />
            <AboutStat
              label={'Age'}
              value={props.ageRange ? Object.values(AgeRanges)[Object.keys(AgeRanges).indexOf(props.ageRange)] : ''}
            />
            <AboutStat
              label={'Disability'}
              value={props.disabled ? Object.values(Disabled)[Object.keys(Disabled).indexOf(props.disabled)] : ''}
            />
            <AboutStat
              label={'Caring Responsibilities'}
              value={
                props.caringResponsibilities
                  ? Object.values(CaringResponsibilities)[
                      Object.keys(CaringResponsibilities).indexOf(props.caringResponsibilities)
                    ]
                  : ''
              }
            />
            <AboutStat label={'Coach Type'} value={props.isOneToOne ? 'One-to-one' : 'Group'} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
