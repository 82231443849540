import React from 'react'
import { Autocomplete, Box, styled, TextField, Typography } from '@mui/material'
import theme from '../../theme'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const StyledBlendAutocomplete = styled(Autocomplete)({
  fontSize: '15pt',
  color: theme.input.text,
  width: '100%',
  '& .MuiOutlinedInput-root': {
    padding: '0',
    '& input': {
      padding: '10px 20px',
    },
    '& .MuiAutocomplete-input': {
      padding: '10px 20px',
      paddingRight: '0px',
    },
    '& fieldset': {
      borderRadius: '5px',
      border: `0.5px solid ${theme.blend.darkGrey}`,
    },
    '&:hover fieldset': {
      border: `2px solid ${theme.blend.blue}`,
      boxShadow: `${theme.blend.boxShadow} ${theme.blend.lightBlue}`,
    },
    '&.Mui-focused fieldset': {
      border: `2px solid ${theme.blend.blue}`,
      borderColor: theme.blend.blue,
    },
  },
  '& .MuiSvgIcon-root': {
    color: theme.input.text,
  },
})

interface BlendAutocompleteProps {
  width: string
  label?: string
  required?: boolean
  name: string
  placeholder?: string
  options: unknown[]
  value: unknown
  getOptionLabel: (option: unknown) => string
  getOptionDisabled?: (option: unknown) => boolean
  onChange: (e: React.SyntheticEvent, value: unknown, name: string) => void
}

export const BlendAutocomplete: React.FC<BlendAutocompleteProps> = (props) => {
  return (
    <>
      {props.label && (
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '5px',
          }}
        >
          <Typography component={'label'} variant={'body1'}>
            {props.label}
          </Typography>
          {props.required && (
            <Typography variant={'body1'} fontWeight={300} fontSize={'12pt'} color={theme.blend.error}>
              Required
            </Typography>
          )}
        </Box>
      )}
      <StyledBlendAutocomplete
        sx={{ width: props.width }}
        popupIcon={<KeyboardArrowDownIcon />}
        disablePortal
        autoSelect
        options={props.options}
        renderInput={(params) => (
          <TextField {...params} placeholder={props.placeholder || 'Type or choose from list'} />
        )}
        value={props.value}
        getOptionLabel={props.getOptionLabel}
        getOptionDisabled={props.getOptionDisabled}
        onChange={(e, value) => props.onChange(e, value, props.name)}
      />
    </>
  )
}
