import { Box, IconButton, styled, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { BlendSmallPrimaryButton } from '../../../../../components/Common/Buttons'
import { BlendBinIcon, BlendEditIcon, BlendViewIcon, BlendWarningIcon } from '../../../../../components/Common/Icons'
import { Table } from '../../../../../components/Table/table'
import { useUserContext } from '../../../../../providers/User'
import { useDialogContext } from '../../../../../providers/Dialog'
import { deleteCoachee } from '../../../../../queries'
import { Contract, ContractCoachee } from '../../../../../types/contract_type'
import { useDateTimeString } from '../../../../../hooks/useDateTime'
import { Cell } from 'react-table'

const Title = styled(Typography)({
  margin: '20px',
})

interface ReferralTableData {
  id: string
  name: string
  progress: string
  lastUpdated: Date
}

interface LinkedCoacheesProps {
  contract: Contract
  expired: boolean
}

export const LinkedReferrals: React.FC<LinkedCoacheesProps> = ({ contract, expired }) => {
  const navigate = useNavigate()
  const context = useUserContext()
  const queryClient = useQueryClient()
  const dialog = useDialogContext()

  const mutation = useMutation((id: string) => deleteCoachee(context, id), {
    onError: (error) => console.error(error),
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllCoacheesData')
      await queryClient.refetchQueries(['getContractData', contract.id])
    },
  })

  const handleDelete = (id: string, name: string) => {
    dialog.openDeleteDialog(name, () => mutation.mutate(id))
  }

  const handleNewReferralButton = async () => {
    if ((contract.coachees && contract.coacheesQuota && contract.coachees.length >= contract.coacheesQuota) || expired)
      await dialog.openConfirmDialog(
        `You have reached a quota or expiry date, are you sure you want to continue?`,
        async () => await navigate(`/coachees/editor?clientId=${contract.clientId}&contractId=${contract.id}`),
        true,
      )
    else navigate(`/coachees/editor?clientId=${contract.clientId}&contractId=${contract.id}`)
  }

  const tableData = useMemo<ReferralTableData[] | undefined>(
    () =>
      (contract.coachees as ContractCoachee[]) &&
      (contract.coachees as ContractCoachee[])
        .filter((coachee) => {
          return coachee.status === 'Referral'
        })
        .map<ReferralTableData>((coachee) => {
          return {
            id: coachee.id,
            name: `${coachee?.firstName} ${coachee?.lastName}`,
            progress: coachee.questionnaireStatus,
            lastUpdated: coachee.lastUpdated,
          }
        }),
    [contract.coachees?.length, contract.coachees],
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Referrals',
        columns: [
          {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
          },
          {
            Header: 'Questionnaire Progress',
            id: 'progress',
            accessor: 'progress',
          },
          {
            Header: 'Last Updated',
            id: 'lastUpdated',
            accessor: 'lastUpdated',
            Cell: ({ row }: Cell<ReferralTableData>) => useDateTimeString(row.original.lastUpdated),
          },
          {
            Header: '',
            id: 'actions',
            accessor: 'id',
            Cell: ({ row }: Cell<ReferralTableData>) => {
              return (
                <Box sx={{ float: 'right' }}>
                  <IconButton onClick={() => navigate(`/coachees/editor?id=${row.original.id}`)} sx={{ mr: '12px' }}>
                    <BlendEditIcon />
                  </IconButton>
                  <IconButton onClick={() => navigate(`/coachees/profile?id=${row.original.id}`)} sx={{ mr: '12px' }}>
                    <BlendViewIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(row.original.id, row.original.name)}>
                    <BlendBinIcon />
                  </IconButton>
                </Box>
              )
            },
          },
        ],
      },
    ],
    [],
  )

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Title variant="h6">Linked Referrals</Title>
        <Box>
          <BlendSmallPrimaryButton onClick={handleNewReferralButton}>
            {expired && <BlendWarningIcon color="white" style={{ marginRight: '10px' }} />} Add new referral
          </BlendSmallPrimaryButton>
          <BlendSmallPrimaryButton sx={{ marginLeft: '20px' }} onClick={() => navigate(`/coachees`)}>
            View all referrals
          </BlendSmallPrimaryButton>
        </Box>
      </Box>
      {!contract.coachees ? <Title variant="h4">Could not fetch Referrals</Title> : null}
      {tableData && tableData.length > 0 ? (
        <Table columns={columns} data={tableData} />
      ) : (
        <Title variant="h4">There are no Referrals</Title>
      )}
    </Box>
  )
}
