import { CircularProgress, Grid, Typography, Link, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState, ReactNode, useReducer, useEffect, ChangeEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SingleChoice, MultipleChoice, FreeForm, SliderForm, SelectChoice } from '../../components/Questionnaire'
import theme from '../../theme'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { questionnaireReducer } from '../../reducers/questionnaireReducer'
import { useMutation, useQuery } from 'react-query'
import { OuterPage } from './outerPage'
import { Question, QuestionGroup, QuestionnaireResponse, QuestionType } from '../../types/questionnaire_types'
import { CoacheeQuestionnaire, GetAnonCoacheeQuestionnaireRes } from '../../types/coacheeQuestionnaire_type'
import { v4 as uuid } from 'uuid'
import { BlendFullpageSpinner } from '../../components/Common/Spinner'
import { BlendPrimaryButton, BlendSecondaryButton } from '../../components/Common/Buttons'
import { useDialogContext } from '../../providers/Dialog'
import { BlendCheckbox } from '../../components/Common/Checkbox'
// import { questionnaireValidator } from '../../validators/questionnaireValidator'
import { questionnaireErrorReducer } from '../../reducers/questionnaireErrorReducer'

export interface QuestionProps extends Question {
  callBack: (question: Question, response: ResponseExt[]) => void
  children?: ReactNode
  responses: ResponseExt[]
  currentResponse?: ResponseExt[]
  isMandatory: boolean
}

export interface ResponseExt extends QuestionnaireResponse {
  questionId: string
}

export const Questionnaire: React.FC = () => {
  const dialog = useDialogContext()

  const [questionnaireState, setQuestionnaireState] = useState(0)
  const [privacyAcceptedState, setPrivacyAcceptedState] = useState(false)
  const [questionGroupsState, setQuestionGroupsState] = useReducer(questionnaireErrorReducer, [])
  const [currentGroupState, setCurrentGroupState] = useState(0)
  const [responsesState, setResponsesState] = useState<ResponseExt[]>([])
  const [breadcrumbsState, setBreadcrumbsState] = useState<string[]>([])
  const [currentBreadcrumbState, setCurrentBreadcrumbState] = useState(0)
  const [currentResponsesState, setCurrentResponsesState] = useReducer(questionnaireReducer, [])
  const [submitButtonEnabledState, setSubmitButtonEnabledState] = useState(true)
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  const id: string | null = urlParams.get('id')

  const getCoacheeQuestionnaire = async () => {
    try {
      if (!id) throw new Error('No ID provided')
      const data = await fetch(`${process.env.REACT_APP_API_BASE_URL ?? ''}/anonymous/${id}`, {
        method: 'GET',
      })
      return data.json()
    } catch (e) {
      console.error(`Can't fetch coacheeQuestionnaire data`, e)
      throw e
    }
  }

  const { isFetching, data: questionnaireData } = useQuery<GetAnonCoacheeQuestionnaireRes>({
    queryKey: ['getAnonCoacheeQuestionnaireData', id],
    queryFn: getCoacheeQuestionnaire,
    onError: (e) => {
      console.error(e)
    },
  })

  const putCoacheeQuestionnaire = async (coacheeQuestionnaire: CoacheeQuestionnaire) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL ?? ''}/anonymous/submit`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(coacheeQuestionnaire),
      })
      return response
    } catch (e) {
      console.error("Can't put data", e)
    }
  }

  const mutation = useMutation({
    mutationFn: putCoacheeQuestionnaire,
    onSuccess: async () => {
      setSubmitButtonEnabledState(false)
      await dialog.openDialog(['Submission was successful, you can now close this page!'], 2)
    },
    onError: async () =>
      await dialog.openDialog(['Something went wrong, have you completed the questionnaire already?']),
  })

  useEffect(() => {
    if (questionnaireData && questionnaireData.questionGroups.length > 0) {
      questionnaireData.questionGroups.sort((a: QuestionGroup, b: QuestionGroup) => {
        if (a.order && b.order) {
          return a.order - b.order
        } else return 0
      })
      questionnaireData.questionGroups.map((group: QuestionGroup) => {
        group.questions.sort((a: Question, b: Question) => {
          if (a.order && b.order) {
            return a.order - b.order
          } else return 0
        })
      })

      setQuestionGroupsState({
        type: 'INIT',
        groups: questionnaireData.questionGroups.map<QuestionGroup>((group) => {
          return {
            ...group,
            questions: group.questions.map<Question>((question) => {
              return { ...question, error: question.isMandatory ? true : false }
            }),
          }
        }),
      })
      setResponsesState(() => {
        const responses: ResponseExt[] = []
        questionnaireData.questionGroups.map((group: QuestionGroup) => {
          group.questions.map((question) => {
            question.responseOptions.map((responseOption) => {
              responses.push({
                text: responseOption.text ? responseOption.text : '',
                value: 0,
                id: uuid(),
                responseOptionId: responseOption.id,
                questionId: question.id,
                coacheeQuestionnaireId: questionnaireData?.coacheeQuestionnaire.id,
              })
            })
          })
        })
        return responses
      })
    } else console.error('Questions are invalid??')
    setBreadcrumbsState(() => {
      if (questionnaireData) {
        const breadcrumbs: string[] = []
        questionnaireData.questionGroups.map((group: QuestionGroup) => {
          if (!breadcrumbs[breadcrumbs.length - 1] || group.groupBreadcrumb != breadcrumbs[breadcrumbs.length - 1]) {
            breadcrumbs.push(group.groupBreadcrumb)
          }
        })
        return breadcrumbs
      } else return []
    })
  }, [questionnaireData, isFetching])

  const getQuestionResponses = (questionId: string): ResponseExt[] => {
    return responsesState.filter((response: ResponseExt) => response.questionId === questionId)
  }

  const getCurrentResponses = (questionId: string): ResponseExt[] | undefined => {
    const responses = currentResponsesState.filter((response: ResponseExt) => response.questionId === questionId)
    return responses.length > 0 ? responses : undefined
  }

  const handleNextButton = () => {
    // Check all required questions are answered here

    if (currentGroupState < questionGroupsState.length - 1) {
      setCurrentGroupState(currentGroupState + 1)
      setCurrentBreadcrumbState(breadcrumbsState.indexOf(questionGroupsState[currentGroupState + 1].groupBreadcrumb))
    } else {
      setQuestionnaireState(3)
    }
  }

  const handleBackButton = () => {
    if (currentGroupState > 0) {
      setCurrentGroupState(currentGroupState - 1)
      setCurrentBreadcrumbState(breadcrumbsState.indexOf(questionGroupsState[currentGroupState - 1].groupBreadcrumb))
    } else {
      setQuestionnaireState(1)
    }
  }

  const handleSendButton = () => {
    if (questionnaireData) {
      mutation.mutate({
        id: questionnaireData.coacheeQuestionnaire.id,
        coacheeId: questionnaireData.coacheeQuestionnaire.coacheeId,
        questionnaireId: questionnaireData.questionnaire.questionnaireId,
        completed: new Date(),
        status: 'Completed',
        responses: currentResponsesState.map<QuestionnaireResponse>((response) => {
          return {
            id: response.id,
            responseOptionId: response.responseOptionId,
            coacheeQuestionnaireId: response.coacheeQuestionnaireId,
            text: response.text,
            value: response.value,
          }
        }),
      })
    }
  }

  useEffect(() => {
    questionGroupsState[currentGroupState]?.questions?.map((question, questionIndex) => {
      if (question.isMandatory) {
        const responseIndex = currentResponsesState.findIndex(
          (response) => response.responseOptionId == question.responseOptions[0].id,
        )
        switch (question.type) {
          case QuestionType.longFreeForm:
          case QuestionType.freeForm:
            if (
              responseIndex == -1 ||
              !currentResponsesState[responseIndex].text ||
              currentResponsesState[responseIndex].text == ''
            ) {
              setQuestionGroupsState({ type: 'SET', groupIndex: currentGroupState, questionIndex: questionIndex })
            } else {
              setQuestionGroupsState({ type: 'UNSET', groupIndex: currentGroupState, questionIndex: questionIndex })
            }
            break
          case QuestionType.multipleChoice:
          case QuestionType.singleChoice:
          case QuestionType.numeric:
            if (
              !currentResponsesState.some((response) =>
                question.responseOptions.some((option) => response.responseOptionId === option.id),
              )
            ) {
              setQuestionGroupsState({ type: 'SET', groupIndex: currentGroupState, questionIndex: questionIndex })
            } else {
              setQuestionGroupsState({ type: 'UNSET', groupIndex: currentGroupState, questionIndex: questionIndex })
            }
            break
        }
      }
    })
  }, [JSON.stringify(currentResponsesState)])

  const nextDisabled =
    questionGroupsState[currentGroupState]?.questions?.some(
      (question) => question.type != QuestionType.coachSelect && question.error,
    ) ?? false

  switch (questionnaireState) {
    case 0:
      return (
        <OuterPage>
          <Typography variant="h2">Welcome!</Typography>
          <Typography variant="body1">
            {questionnaireData?.questionnaire.type === 'Matching' ? (
              <>
                Our rigorous selection and the quality assurance of our coaches and leadership practitioners, ensures
                that you will be matched with the best associate for your needs. This questionnaire allows us to get to
                know you better and how you will most benefit from our coaching services.
              </>
            ) : (
              <>
                We would like to understand more about your experience with Blend, and how you found your coaching. This
                survey aims to gain your honest, anonymous feedback so that we can continue to improve and develop our
                service. Thank you in advance for your time.
              </>
            )}
          </Typography>
          <BlendPrimaryButton onClick={() => setQuestionnaireState(1)}>Next</BlendPrimaryButton>
        </OuterPage>
      )
    case 1:
      return (
        <OuterPage>
          <Typography variant="body2">
            Please read this before you complete the survey. This Privacy Notice governs the manner in which BLEND
            Associates Limited collects, uses, maintains and discloses Personal Data. Blend Associates Limited is a
            company registered in England (company no. 08815945) and with its registered address at 217a Newmarket Road,
            Norwich, NR4 7LA, England (&quot;BLEND&quot;). Your privacy is very important to both Blend Associates
            Limited. We will not use your survey responses for any purpose other than to inform the evaluation. Unless
            you provide your name all survey responses provided will be anonymised along with all other responses and
            used by Blend Associates Ltd as part of updates to the commissioning body. In compliance with GDPR Blend
            Associates Ltd, as the Data Processor, will only process your Personal Data for the purpose of the producing
            the evaluation that has been commissioned. We will only collect personal data that is directly relevant for
            this purpose. All the Personal Data collected by us and stored electronically is held on a secure server in
            the EEA only. Where required, this information is encrypted for additional security. We use computer
            safeguards such as firewalls and data encryption, we enforce physical access controls to our buildings and
            files, and we only authorise access to Personal Data only for those employees who require it to fulfil their
            job responsibilities Blend Associates Ltd is registered as a Data Controller with the ICO and no data will
            leave the UK and will only be used and accessed by Blend Associates Ltd as part of this contract. You can
            see Blend Associates Ltd full privacy policy and compliance with GDPR{' '}
            <Link
              href="https://www.ltdblend.com/wp-content/uploads/2022/12/BLEND-Privacy-Notice-08122022-RC.pdf"
              target="_blank"
              rel="noreferrer"
            >
              here
            </Link>
          </Typography>
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <BlendCheckbox
              value={privacyAcceptedState}
              onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => setPrivacyAcceptedState(checked)}
            />
            <Typography>I agree with the above statement</Typography>
          </Box>
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <BlendSecondaryButton onClick={() => setQuestionnaireState(0)}>Back</BlendSecondaryButton>
            <BlendPrimaryButton
              disabled={!privacyAcceptedState}
              onClick={() => setQuestionnaireState(2)}
              sx={{ marginLeft: '20px' }}
            >
              Begin
            </BlendPrimaryButton>
          </Box>
        </OuterPage>
      )
    case 2:
      return !isFetching && questionGroupsState.length > 0 ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignContent: 'stretch', minHeight: '100%', width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="center" sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'inline-flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  padding: '40px',
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 108,
                    width: 108,
                    alignSelf: 'center',
                  }}
                  alt="Blend Logo"
                  src="/blendLogo.png"
                />
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', height: '100%', marginX: 4 }}>
                  {breadcrumbsState.map<JSX.Element>((breadcrumb: string, index: number) => {
                    return (
                      <Box key={`group-${index}`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {index > 0 &&
                          (index > currentBreadcrumbState ? (
                            <FontAwesomeIcon icon={faAngleRight} style={{ marginInline: '20px' }} />
                          ) : (
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              style={{ marginInline: '20px' }}
                              color={theme.palette.success.main}
                            />
                          ))}
                        <Typography
                          variant={'h4'}
                          key={breadcrumb}
                          color={index <= currentBreadcrumbState ? theme.palette.success.main : theme.text.black}
                        >
                          {breadcrumb}
                        </Typography>
                      </Box>
                    )
                  })}
                </Box>
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress
                    variant="determinate"
                    size={100}
                    value={(currentGroupState / questionGroupsState.length) * 100}
                    color="success"
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="h3" component="div" color="text.secondary">{`${Math.round(
                      (currentGroupState / questionGroupsState.length) * 100,
                    )}%`}</Typography>
                  </Box>
                </Box>
              </Box>
              {/* <Typography variant="h2">
                {questionGroupsState[currentGroupState].groupTitle
                  ? questionGroupsState[currentGroupState].groupTitle
                  : questionGroupsState[currentGroupState].groupBreadcrumb}
              </Typography> */}
            </Grid>
          </Box>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
              sx={{ width: '100%', height: '100%' }}
            >
              <Typography variant="h2">
                {questionGroupsState[currentGroupState].groupTitle
                  ? questionGroupsState[currentGroupState].groupTitle
                  : questionGroupsState[currentGroupState].groupBreadcrumb}
              </Typography>
              {questionGroupsState[currentGroupState].questions.map((question: Question) => {
                switch (question.type) {
                  case QuestionType.singleChoice:
                    return (
                      <SingleChoice
                        key={question.id}
                        {...question}
                        callBack={(question: Question, responses: ResponseExt[]) =>
                          setCurrentResponsesState({ question: question, responses: responses })
                        }
                        responses={getQuestionResponses(question.id)}
                        currentResponse={getCurrentResponses(question.id)}
                      />
                    )
                  case QuestionType.multipleChoice:
                    return (
                      <MultipleChoice
                        key={question.id}
                        {...question}
                        callBack={(question: Question, responses: ResponseExt[]) =>
                          setCurrentResponsesState({ question: question, responses: responses })
                        }
                        responses={getQuestionResponses(question.id)}
                        currentResponse={getCurrentResponses(question.id)}
                      />
                    )
                  case QuestionType.freeForm:
                  case QuestionType.longFreeForm:
                    return (
                      <FreeForm
                        key={question.id}
                        {...question}
                        callBack={(question: Question, responses: ResponseExt[]) =>
                          setCurrentResponsesState({ question: question, responses: responses })
                        }
                        responses={getQuestionResponses(question.id)}
                        currentResponse={getCurrentResponses(question.id)}
                      />
                    )
                  case QuestionType.numeric:
                    return (
                      <SliderForm
                        key={question.id}
                        {...question}
                        callBack={(question: Question, responses: ResponseExt[]) =>
                          setCurrentResponsesState({ question: question, responses: responses })
                        }
                        responses={getQuestionResponses(question.id)}
                        currentResponse={getCurrentResponses(question.id)}
                      />
                    )
                  case QuestionType.coachSelect:
                    return (
                      <SelectChoice
                        key={question.id}
                        {...question}
                        callBack={(question: Question, responses: ResponseExt[]) =>
                          setCurrentResponsesState({ question: question, responses: responses })
                        }
                        responses={getQuestionResponses(question.id)}
                        currentResponse={getCurrentResponses(question.id)}
                        coaches={questionnaireData?.coaches ?? null}
                        defaultCoachId={questionnaireData?.coachee.defaultCoachId ?? null}
                      />
                    )

                  default:
                    return <>Something else (error!)</>
                }
              })}
            </Grid>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
              sx={{ padding: '40px', height: '100%' }}
              spacing="20px"
            >
              <Grid item>
                <BlendSecondaryButton onClick={handleBackButton}>Back</BlendSecondaryButton>
              </Grid>
              <Grid item>
                <Tooltip title={nextDisabled ? 'You must complete all required questions' : ''} placement={'top'} arrow>
                  <span>
                    <BlendPrimaryButton onClick={handleNextButton} disabled={nextDisabled}>
                      Next
                    </BlendPrimaryButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <BlendFullpageSpinner />
      )
    case 3:
      return (
        <OuterPage>
          <Typography variant="h2">Thank you for your time!</Typography>
          <Typography variant="body1">
            {questionnaireData?.questionnaire.type === 'Matching' ? (
              <>
                Thank you for taking the time to complete this questionnaire. We will use the information you have
                provided to match you with one of our skilled coaches. You should expect a response confirming your
                ideal coach and availability, and you will be invited to schedule the start of your coaching journey.
              </>
            ) : (
              <>
                Thank you for taking the time to complete this survey. We will use the information you have provided to
                continue to develop and improve our coaching services. We wish you all the best in your career, and hope
                to hear from you again.
              </>
            )}
          </Typography>
          <Grid container alignItems="center" justifyContent="center" spacing="20px" sx={{ width: '100%' }}>
            <Grid item>
              <BlendSecondaryButton onClick={() => setQuestionnaireState(2)}>Back</BlendSecondaryButton>
            </Grid>
            <Grid item>
              <BlendPrimaryButton disabled={!submitButtonEnabledState} onClick={() => handleSendButton()}>
                Submit
              </BlendPrimaryButton>
            </Grid>
          </Grid>
        </OuterPage>
      )
    default:
      return <>Something went wrong!</>
  }
}
