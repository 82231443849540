import React from 'react'
import { useNavigate } from 'react-router-dom'
import { EditContract } from '../profile/edit/editContract'

export const CreateContract: React.FC = () => {
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  const clientId: string | null = urlParams.get('clientId')

  const navigate = useNavigate()
  return (
    <EditContract
      clientId={clientId ?? ''}
      callback={() => (clientId ? navigate(`/clients/profile?id=${clientId}`) : navigate('/contracts'))}
    />
  )
}
