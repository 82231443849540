import { Grid, styled, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import React, { useMemo, useRef } from 'react'
import { QuestionProps, ResponseExt } from '../../pages/questionnaire'
import { QuestionComponent } from '.'
import { ResponseOption } from '../../types/questionnaire_types'

const FormRadio = styled(Radio)({
  color: '#1763AF',
  '&.Mui-checked': {
    color: '#1763AF',
  },
})

export const SingleChoice: React.FC<QuestionProps> = (props: QuestionProps) => {
  const defaultSelection = useRef(props.currentResponse ? props.currentResponse[0].responseOptionId : undefined)
  const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const response: ResponseExt | undefined = props.responses.find(
      (option: ResponseExt) => event.target.value === option.responseOptionId,
    )
    if (response) {
      props.callBack(props, [response])
    }
  }

  const options = useMemo(
    () => [...props.responseOptions?.sort((a: ResponseOption, b: ResponseOption) => a.value - b.value)],
    [],
  )

  return (
    <QuestionComponent {...props}>
      <FormControl sx={{ width: '100%' }}>
        <RadioGroup defaultValue={defaultSelection.current}>
          <Grid
            container
            direction={props.responseOptions.length > 3 ? 'column' : 'row'}
            justifyContent="flex-start"
            alignItems={props.responseOptions.length > 3 ? 'flex-start' : 'center'}
            overflow="wrap"
            spacing={props.responseOptions.length > 3 ? '10px' : '100px'}
          >
            {options.map((option: ResponseOption) => {
              return (
                <Grid item key={option.id}>
                  <FormControlLabel
                    value={option.value.toString()}
                    control={<FormRadio value={option.id} onChange={handleSelection} />}
                    label={option.text}
                  />
                </Grid>
              )
            })}
          </Grid>
        </RadioGroup>
      </FormControl>
    </QuestionComponent>
  )
}
