import { Box, Grid } from '@mui/material'
import React from 'react'

export const OuterPage: React.FC = (props) => {
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: '100%',
        background: 'linear-gradient(.35turn, rgba(23,99,175,1) 0%, rgba(0,167,157,1) 50%, rgba(156,203,59,1) 100%)',
      }}
    >
      <Grid
        item
        sx={{
          minHeight: '60%',
          backgroundColor: 'white',
          borderRadius: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '50px',
        }}
        xs={12}
        md={8}
        xl={6}
      >
        <Box
          component="img"
          sx={{
            height: '20vh',
            alignSelf: 'center',
          }}
          alt="Blend Logo"
          src="/blendLogo.png"
        />
        {props.children}
      </Grid>
    </Grid>
  )
}
