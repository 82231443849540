import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import enGB from 'date-fns/locale/en-GB'
import { Authentication } from './components/Authentication'

import { Layout } from './components/Layout'
import { Calendar } from './pages/calendar'
import { Coachees } from './pages/coachees'
import { Coaches } from './pages/coaches'
import { ContractManager } from './pages/contracts'
import { Dashboard } from './pages/dashboard'
import { Evaluations } from './pages/evalutations'
import { Logout } from './pages/logout'
import { Questionnaires } from './pages/questionnaires'
import { Reports } from './pages/reports'
import { UserProvider } from './providers/User'
import { Questionnaire } from './pages/questionnaire'
import { Profile } from './pages/coaches/profile'
import { SessionEditor } from './pages/sessionManager'
import { Redirect } from './pages/redirect'
import { SessionHistory } from './pages/coachSessionHistory'
import { MatchingTable } from './pages/coacheeReferral/matching'
import { CoacheeView } from './pages/coacheeReferral/view'
import { ContractProfile } from './pages/contracts/profile'
import { CreateContract } from './pages/contracts/create'
import { CoacheeEditor } from './pages/coachees/edit'
import { ClientManager } from './pages/clientManager'
import { ClientProfile } from './pages/clientManager/profile'
import { QuestionnaireView } from './pages/questionnaire/view'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { CreateClient } from './pages/clientManager/create/createClient'
import { DialogProvider } from './providers/Dialog'
import { NotFound } from './pages/notFound'
import { QueryClient, useQueryClient } from 'react-query'
import { AvatarProvider } from './providers/Avatars'

function App() {
  useEffect(() => {
    const script = document.createElement('script')
    script.innerHTML =
      'window.fwSettings={"widget_id":60000004010}; !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()'
    document.body.appendChild(script)

    const scriptLink = document.createElement('script')
    scriptLink.src = 'https://widget.freshworks.com/widgets/60000004010.js'
    scriptLink.async = true
    scriptLink.defer = true
    document.body.appendChild(scriptLink)

    return () => {
      document.body.removeChild(script)
      document.body.removeChild(scriptLink)
    }
  }, [])

  return (
    <Router basename={`${process.env.PUBLIC_URL}`}>
      <UserProvider>
        <DialogProvider>
          <AvatarProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
              <Layout>
                <Routes>
                  <Route path="/" element={<Redirect />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/login" element={<Authentication />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/session/create" element={<SessionEditor mode="create" />} />
                  <Route path="/session/edit" element={<SessionEditor mode="edit" />} />
                  <Route path="/session/editPast" element={<SessionEditor mode="edit" isPastSession />} />
                  <Route path="/clients" element={<ClientManager />} />
                  <Route path="/clients/profile" element={<ClientProfile />} />
                  <Route path="/clients/create" element={<CreateClient />} />
                  <Route path="/contracts" element={<ContractManager />} />
                  <Route path="/contracts/profile" element={<ContractProfile />} />
                  <Route path="/contracts/create" element={<CreateContract />} />
                  <Route path="/coaches" element={<Coaches />} />
                  <Route path="/coaches/profile" element={<Profile />} />
                  <Route path="/session-history" element={<SessionHistory />} />
                  <Route path="/coachees" element={<Coachees />} />
                  <Route path="/coachees/referral" element={<MatchingTable />} />
                  <Route path="/coachees/editor" element={<CoacheeEditor />} />
                  <Route path="/coachees/profile" element={<CoacheeView />} />
                  <Route path="/questionnaires" element={<Questionnaires />} />
                  <Route path="/evaluations" element={<Evaluations />} />
                  <Route path="/reports" element={<Reports />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/questionnaire" element={<Questionnaire />} />
                  <Route path="/questionnaire/view" element={<QuestionnaireView />} />
                  <Route path="/*" element={<NotFound />} />
                </Routes>
              </Layout>
            </LocalizationProvider>
          </AvatarProvider>
        </DialogProvider>
      </UserProvider>
    </Router>
  )
}

export default App
