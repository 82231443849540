import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton, styled, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import theme from '../../theme'
import { BlendDatePicker } from './DatePicker'

interface BlendDateRangePickerProps {
  dateFrom: Date | null
  dateTo: Date | null
  onChange: (dateFrom: Date | null, dateTo: Date | null) => void
}

export const BlendDateRangePicker: React.FC<BlendDateRangePickerProps> = (props) => {
  const [dateState, setDateState] = useState([props.dateFrom, props.dateTo])

  useEffect(() => props.onChange(dateState[0], dateState[1]), [dateState.toString()])

  const changeDate = (date: Date | null, increment: number): Date | null => {
    if (date) {
      date.setMonth(date.getMonth() + increment)
      return date
    } else return null
  }

  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
      <Typography fontSize="15pt" fontWeight={600} mr="10px" color={theme.blend.blue}>
        Date range
      </Typography>
      <IconButton onClick={() => setDateState([changeDate(dateState[0], -1), dateState[1]])}>
        <FontAwesomeIcon icon={faAngleLeft as IconProp} color={theme.blend.blue} size={'xs'} />
      </IconButton>
      <BlendDatePicker
        small
        name="dateFrom"
        value={dateState[0]}
        onChange={(date) => setDateState([date, dateState[1]])}
      />
      <IconButton onClick={() => setDateState([changeDate(dateState[0], 1), dateState[1]])}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} color={theme.blend.blue} size={'xs'} />
      </IconButton>
      <IconButton onClick={() => setDateState([dateState[0], changeDate(dateState[1], -1)])}>
        <FontAwesomeIcon icon={faAngleLeft as IconProp} color={theme.blend.blue} size={'xs'} />
      </IconButton>
      <BlendDatePicker
        small
        name="dateTo"
        value={dateState[1]}
        onChange={(date) => setDateState([dateState[0], date])}
      />
      <IconButton onClick={() => setDateState([dateState[0], changeDate(dateState[1], 1)])}>
        <FontAwesomeIcon icon={faAngleRight as IconProp} color={theme.blend.blue} size={'xs'} />
      </IconButton>
    </Box>
  )
}
