import React from 'react'
import { styled, Tab, Tabs } from '@mui/material'
import theme from '../../theme'

interface StyledTabProps {
  label: string
}

export const BlendTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)({
  textTransform: 'none',
  fontSize: '18pt',
  fontWeight: 600,
  marginTop: '10px',
  '&.Mui-selected': {
    color: theme.blend.blue,
    borderColor: theme.blend.blue,
  },
})

interface StyledTabsProps {
  children?: React.ReactNode
  value: number
  onChange: (event: React.SyntheticEvent, newValue: number) => void
  variant: 'fullWidth' | 'standard' | 'scrollable' | undefined
}

export const BlendTabsGroup = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  borderBottom: `0.5px solid ${theme.blend.lightGrey}`,
  borderTop: `0.5px solid ${theme.blend.lightGrey}`,
  '& .MuiTabs-indicator': {
    //backgroundColor: theme.blend.blue,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: '20vw',
    width: '100%',
    backgroundColor: theme.blend.blue,
  },
})
