import { Coachee } from './coachee_type'

export interface GetAllCoachesRes {
  coaches: ShortCoachResBody[]
}
export interface ShortCoachResBody {
  id: string
  firstName: string
  lastName: string
  avatarFilename: string | null
  isActive: boolean
  headline: string
  availability: string
  email: string
}

export interface GetCoachRes {
  coach: Coach
  attributes: AttributeBody[]
  sessions: Session[]
  coachees: Coachee[]
}

export interface Coach {
  id: string
  firstName: string
  lastName: string
  email: string
  mobile: string
  address: string
  gender: string | null
  ethnicity: string | null
  ageRange: string | null
  disabled: string | null
  caringResponsibilities: string | null
  dateOfBirth: Date | null
  avatarFilename: string | null
  isOneToOne: boolean
  isGroup: boolean
  bio: string
  availability: string

  inactiveFrom: Date | null
  inactiveTo: Date | null
  isActive: boolean

  associateContractSigned: Date | null
  associateContractExpiry: Date | null

  dataProtectionAgreementSigned: Date | null
  dataProtectionAgreementExpiry: Date | null

  identityCheckSigned: Date | null

  insuranceProvider: string
  insuranceValue: number | null
  insuranceExpiry: Date | null

  supervisorFirstName: string
  supervisorLastName: string
  supervisorEmail: string

  attributes?: AttributeBody[]
  sessions?: Session[]
  coachees?: Coachee[]
}

export interface CoachReq extends Omit<Coach, 'gender' | 'ethnicity'> {
  gender: number | Gender
  ethnicity: number | Ethnicity
}

export interface GetAttributeRes {
  attributeTags: AttributeBody[]
}
export interface AttributeBody {
  id: string
  type: string
  text: string
  isCustom: boolean
}

export interface Session {
  id: string
  status: string | number
  start: Date
  end: Date
  sessionPaid: boolean
  coacheeCapacity: number
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  Intersex = 'Intersex',
  Non_binary = 'Non binary',
  Other = 'Other',
  Prefer_not_to_say = 'Prefer not to say',
}

export enum Ethnicity {
  White_English = 'White English',
  White_Welsh = 'White Welsh',
  White_Scottish = 'White Scottish',
  White_Northern_Irish = 'White Northern Irish',
  White_British = 'White British',
  White_Irish = 'White Irish',
  White_Gypsy_Or_Irish_Traveller = 'White Gypsy or Irish Traveller',
  White_Roma = 'White Roma',
  White_Other = 'White Other',
  Asian_Indian = 'Asian Indian',
  Asian_Pakistani = 'Asian Pakistani',
  Asian_Bangladeshi = 'Asian Bangladeshi',
  Asian_Chinese = 'Asian Chinese',
  Asian_British_Or_Other = 'Asian British or Other',
  Black_Caribbean = 'Black Caribbean',
  Black_African = 'Black African',
  Black_British_Or_Other = 'Black British or Other',
  White_And_Black_Caribbean = 'White and Black Caribbean',
  White_And_Black_African = 'White and Black African',
  White_And_Asian = 'White and Asian',
  Mixed_Other = 'Mixed Other',
  Arab = 'Arab',
  Other = 'Other',
  Prefer_not_to_say = 'Prefer not to say',
}

export enum Disabled {
  Yes = 'Yes',
  No = 'No',
  Prefer_not_to_say = 'Prefer not to say',
}

export enum CaringResponsibilities {
  Yes = 'Yes',
  No = 'No',
  Prefer_not_to_say = 'Prefer not to say',
}

export enum AgeRanges {
  _16_24 = '16-24',
  _25_29 = '25-29',
  _30_34 = '30-34',
  _35_39 = '35-39',
  _40_44 = '40-44',
  _45_49 = '45-49',
  _50_54 = '50-54',
  _55_59 = '55-59',
  _60_64 = '60-64',
  _65_plus = '65 plus',
  Prefer_not_to_say = 'Prefer not to say',
}

export enum AttributeType {
  Professional_Experience = 'Professional experience',
  Style = 'Coaching style',
  Expertise = 'Expertise',
  Qualifications = 'Qualifications',
  Life_Experience = 'Life experience',
  Psychometrics = 'Psychometric qualifications',
  Sector = 'Sector speciality',
}
