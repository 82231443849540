import { ClientContact } from '../types/contact_types'
import { v4 as uuid } from 'uuid'

export interface ContactEditAction {
  type: 'STRING' | 'BOOLEAN' | 'RESET'
  field: string[]
  payload?: string | Date | boolean | number
}

export const clientContactReducer = (state: ClientContact, action: ContactEditAction): ClientContact => {
  switch (action.type) {
    case 'STRING':
      return {
        ...state,
        [action.field[0]]: action.payload,
      }
    case 'BOOLEAN':
      return {
        ...state,
        [action.field[0]]: action.payload as boolean,
      }
    case 'RESET':
      return {
        ...state,
        id: uuid(),
        isPrimary: false,
        isContractPrimary: false,
        firstName: undefined,
        lastName: undefined,
        mobile: undefined,
        email: undefined,
        alternativeMobile: undefined,
        alternativeEmail: undefined,
      }
    default:
      return { ...state }
  }
}
