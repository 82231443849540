import { ResponseExt } from '../pages/questionnaire'
import { Question, QuestionType } from '../types/questionnaire_types'

export interface QuestionnaireAction {
  question: Question
  responses: ResponseExt[]
}

export const questionnaireReducer = (state: ResponseExt[], action: QuestionnaireAction) => {
  const question: Question = action.question
  const input: ResponseExt[] = action.responses
  const responses: ResponseExt[] = state.filter((response: ResponseExt) => response.questionId != question.id)

  switch (action.question.type) {
    case QuestionType.multipleChoice:
      if (input.length > 0) input.map((response: ResponseExt) => responses.push(response))
      return responses
    case QuestionType.singleChoice:
    case QuestionType.numeric:
    case QuestionType.freeForm:
    case QuestionType.longFreeForm:
    case QuestionType.coachSelect:
      const response: ResponseExt | undefined = responses.find((response: ResponseExt) => response.id == input[0].id)
      if (response) {
        const index: number = responses.indexOf(response)
        if (index > -1) {
          responses[index] = input[0]
        }
      } else {
        responses.push(input[0])
      }
      return responses
    default:
      return responses
  }
}
