import { Box, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { BlendDivider } from '../../../../../components/Common/Divider'
import { Coach } from '../../../../../types/coach_types'

export const ViewContactDetails: React.FC<Coach> = (props) => {
  return (
    <Box>
      <Typography variant={'h6'}>Contact Details</Typography>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ paddingTop: 2 }}>
        <Grid item>
          <Typography fontWeight={'fontWeightBold'}>Email</Typography>
          <Typography sx={{ paddingTop: 1 }}>{props.email}</Typography>
        </Grid>
        <Grid item sx={{ paddingLeft: '40px' }}>
          <Typography fontWeight={'fontWeightBold'}>Phone Number</Typography>
          <Typography sx={{ paddingTop: 1 }}>{props.mobile}</Typography>
        </Grid>
      </Grid>
      <BlendDivider sx={{ paddingTop: 2 }} />
      <Box sx={{ paddingTop: 2 }}>
        <Typography fontWeight={'fontWeightBold'}>Address</Typography>
        <Box sx={{ paddingTop: 1 }}>
          {props.address && props.address.includes(',') ? (
            props.address.split(',').map((str: string) => {
              return <Typography key={str}>{str}</Typography>
            })
          ) : (
            <Typography>{props.address}</Typography>
          )}
        </Box>
      </Box>
      <BlendDivider sx={{ paddingTop: 2 }} />
      <Box sx={{ paddingTop: 2 }}>
        <Typography fontWeight={'fontWeightBold'}>Availability</Typography>
        <Typography sx={{ paddingTop: 1 }}>{props.availability}</Typography>
      </Box>
    </Box>
  )
}
