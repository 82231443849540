import { FormControlLabel, Grid, Typography } from '@mui/material'
import React, { useEffect, useReducer, useState } from 'react'
import { clientContactReducer } from '../../reducers/clientContactReducer'
import { ClientContact } from '../../types/contact_types'
import { useDebounce } from '../../hooks/useDebounce'
import { BlendTextField } from '../Common/TextField'
import { BlendPrimaryButton, BlendDestructiveButton } from '../Common/Buttons'
import { BlendCheckbox } from '../Common/Checkbox'
import { v4 as uuid } from 'uuid'

interface EditContactProps {
  title?: string
  contact?: ClientContact
  index: number
  isContractContact?: boolean
  handleCallback?: (contact: ClientContact, index: number) => void
  handleButtonCallback: (isCreate: boolean, index?: number) => void
}

export const newContact: ClientContact = {
  id: '',
  isPrimary: false,
  isContractPrimary: false,
  firstName: '',
  lastName: '',
  mobile: '',
  email: '',
  alternativeEmail: '',
  alternativeMobile: '',
}

export const EditContact: React.FC<EditContactProps> = ({
  title,
  contact,
  index,
  isContractContact,
  handleCallback,
  handleButtonCallback,
}) => {
  const [isPrimaryState, setIsPrimaryState] = useState(
    isContractContact ? contact?.isContractPrimary ?? false : contact?.isPrimary ?? false,
  )
  const [contactState, setContactState] = useReducer(clientContactReducer, contact ?? { ...newContact, id: uuid() })
  const debounceContactState = useDebounce(contactState, 50)

  const handleFastTextChange = (e: any) => {
    setContactState({
      type: 'STRING',
      field: [e.target.name],
      payload: e.target.value,
    })
  }

  useEffect(
    () =>
      setContactState({
        type: 'BOOLEAN',
        field: isContractContact ? ['isContractPrimary'] : ['isPrimary'],
        payload: isPrimaryState,
      }),
    [isPrimaryState],
  )

  useEffect(() => {
    if (handleCallback) handleCallback(contactState, index)
  }, [debounceContactState])

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      sx={{ marginBottom: '40px' }}
      md={12}
      lg={10}
      xl={8}
    >
      <form>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: '10px' }}
        >
          <Typography>{title ? `${title}` : `Contact ${index + 1}`}</Typography>
          <FormControlLabel
            control={<BlendCheckbox checked={isPrimaryState} onChange={() => setIsPrimaryState(!isPrimaryState)} />}
            label="Primary contact"
          />
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={'20px'}
          sx={{ marginBottom: '10px' }}
        >
          <Grid item sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <BlendTextField
              required
              label="First Name"
              placeholder="First Name"
              name="firstName"
              value={contactState.firstName}
              sx={{ paddingTop: 1 }}
              onChange={handleFastTextChange}
            />
          </Grid>
          <Grid item sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <BlendTextField
              required
              label="Last Name"
              placeholder="Last Name"
              name="lastName"
              value={contactState.lastName}
              sx={{ paddingTop: 1 }}
              onChange={handleFastTextChange}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={'20px'}
          sx={{ marginBottom: '10px' }}
        >
          <Grid item sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <BlendTextField
              required
              label="Phone Number"
              placeholder="Phone Number"
              name="mobile"
              value={contactState.mobile}
              sx={{ paddingTop: 1 }}
              onChange={handleFastTextChange}
            />
          </Grid>
          <Grid item sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <BlendTextField
              required
              label="Email"
              placeholder="Email"
              name="email"
              value={contactState.email}
              sx={{ paddingTop: 1 }}
              onChange={handleFastTextChange}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={'20px'}
          sx={{ marginBottom: '20px' }}
        >
          <Grid item sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <BlendTextField
              label="Alternative Phone Number"
              placeholder="Alternative Phone Number"
              name="alternativeMobile"
              value={contactState.alternativeMobile}
              sx={{ paddingTop: 1 }}
              onChange={handleFastTextChange}
            />
          </Grid>
          <Grid item sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <BlendTextField
              label="Alternative Email"
              placeholder="Alternative Email"
              name="alternativeEmail"
              value={contactState.alternativeEmail}
              sx={{ paddingTop: 1 }}
              onChange={handleFastTextChange}
            />
          </Grid>
        </Grid>
      </form>
      <Grid item>
        {title ? (
          <BlendPrimaryButton onClick={() => handleButtonCallback(true)}>Add</BlendPrimaryButton>
        ) : (
          <BlendDestructiveButton onClick={() => handleButtonCallback(false, index)}>Delete</BlendDestructiveButton>
        )}
      </Grid>
    </Grid>
  )
}
