import { Button, ButtonProps, styled } from '@mui/material'
import React from 'react'
import theme from '../../theme'
import { BlendPrimaryButton, BlendSecondaryButton } from '../Common/Buttons'
import Color from 'color'

interface BlendAttributeButtonProps extends ButtonProps {
  primaryColor: string
  isCustom?: boolean
}

export const BlendAttributeButton = styled((props: BlendAttributeButtonProps) => <BlendPrimaryButton {...props} />)(
  (props) => {
    return {
      fontWeight: 600,
      height: '40px',
      padding: '10px 15px',
      color: theme.text.white,
      backgroundColor: props.primaryColor,
      border: `2px solid rgba(0, 0, 0, 0)`,
      '&:hover': {
        backgroundColor: props.primaryColor,
        border: `2px solid ${props.primaryColor}`,
        boxShadow: `${theme.blend.boxShadow} ${Color(props.primaryColor).alpha(0.5).string()}`,
      },
      '&:disabled': {
        pointerEvents: 'none',
        backgroundColor: props.primaryColor,
        border: `2px solid ${props.primaryColor}`,
        color: theme.text.white,
      },
    }
  },
)

export const BlendSecondaryAttributeButton = styled((props: BlendAttributeButtonProps) => (
  <BlendSecondaryButton {...props} />
))((props) => {
  return {
    fontWeight: 600,
    height: '40px',
    padding: '10px 15px',
    color: props.primaryColor,
    backgroundColor: theme.blend.white,
    border: `2px solid ${props.primaryColor}`,
    '&:hover': {
      color: props.primaryColor,
      border: `2px solid ${props.primaryColor}`,
      boxShadow: `${theme.blend.boxShadow} ${Color(props.primaryColor).alpha(0.5).string()}`,
    },
    '&:disabled': {
      pointerEvents: 'none',
      backgroundColor: theme.blend.white,
      border: `2px solid ${props.primaryColor}`,
      color: props.primaryColor,
    },
  }
})
