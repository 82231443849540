export interface GetSessionRes {
  id: string | null
  coachId: string | null
  coachName?: string | null
  clientId: string | null
  clientName?: string | null
  contractId: string | null
  contractHandle?: string | null
  coacheeId: string | null
  coacheeName?: string | null
  start?: Date | null
  end?: Date | null
  createOutlookEvent: boolean
  status: SessionStatus
  isPaid: boolean
  invoiceReference?: string | null
}

export enum SessionStatus {
  Open = 'Open',
  Cancelled = 'Cancelled',
  ShortNoticeCancellation = 'Short Notice Cancellation',
  DitNotAttend = 'Did Not Attend',
  Confirmed = 'Confirmed',
  Completed = 'Completed',
}

export interface GetCoachSessionHistoryRes {
  sessions: {
    id: string
    transactionId: string
    contractName: string
    contractHandle: string
    coacheeName: string
    status: string
    isPaid: boolean
    lastUpdated: Date
  }[]
}

export interface GetContractSessionHistoryRes {
  sessions: GetContractSessionHistoryResBody[]
}
export interface GetContractSessionHistoryResBody {
  id: string
  transactionId: string
  coachName: string
  coacheeName: string
  status: SessionStatus
  lastUpdated: Date
}
