import * as React from 'react'
import { styled } from '@mui/material/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHome,
  faChevronRight,
  faChevronLeft,
  faCalendar,
  faUsers,
  faMapSigns,
  faScroll,
  faRocket,
  faQuestion,
  faStar,
  faChartPie,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  Box,
  CssBaseline,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material'
import theme from '../../theme'
import { useNavigate, useLocation } from 'react-router-dom'
import { useIsAuthenticated } from '@azure/msal-react'
import { Authentication } from '../Authentication'
import { BlendDivider } from '../Common/Divider'

interface LayoutProps {
  children?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  const [open, setOpen] = React.useState(false)

  const navigate = useNavigate()
  const isAuthenticated = useIsAuthenticated()
  const location = useLocation()

  const drawerWidth = 271

  const handleDrawerToggle = () => {
    setOpen(!!!open)
  }

  interface RootProps {
    open?: boolean
  }

  const DrawerHeader = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  }))

  const openedMixin = (): Record<string, any> => ({
    background: 'linear-gradient(180deg, rgba(23,99,175,1) 0%, rgba(0,167,157,1) 50%, rgba(156,203,59,1) 100%)',
    width: drawerWidth,
    overflowX: 'hidden',
    borderRight: '0',
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      outline: `1px solid slategrey`,
    },
  })

  const closedMixin = (theme: Theme): Record<string, any> => ({
    background: 'linear-gradient(180deg, rgba(23,99,175,1) 0%, rgba(0,167,157,1) 50%, rgba(156,203,59,1) 100%)',
    overflowX: 'hidden',
    width: `calc(${theme.spacing(10)} + 1px)`,
    borderRight: '0',
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      outline: `1px solid slategrey`,
    },
  })

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })<RootProps>(({ theme, open }) => ({
    flexShrink: 0,
    display: 'flex',
    alignItems: 'flex-start',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(),
      '& .MuiDrawer-paper': openedMixin(),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }))

  interface NavOptionProps {
    path: string
    icon: IconDefinition
    text: string
  }

  const NavOption: React.FC<NavOptionProps> = (props: NavOptionProps) => {
    return (
      <ListItem
        sx={{
          width: '100%',
          paddingLeft: '25px',
          paddingTop: '15px',
          paddingBottom: '15px',
          borderTopLeftRadius: '101px',
          borderBottomLeftRadius: '101px',
          backgroundColor: location.pathname.includes(props.path) ? 'white' : undefined,
        }}
        button
        key={props.text}
        onClick={() => {
          if (location.pathname === props.path) return
          navigate(props.path)
        }}
      >
        <ListItemIcon>
          <FontAwesomeIcon
            size="lg"
            fixedWidth
            color={location.pathname.includes(props.path) ? theme.blend.blue : theme.blend.white}
            icon={props.icon}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography color={location.pathname.includes(props.path) ? theme.blend.blue : theme.blend.white}>
              {props.text}
            </Typography>
          }
        />
      </ListItem>
    )
  }

  return (
    <React.Fragment>
      {location.pathname === '/questionnaire' ? (
        <Box sx={{ display: 'flex', minHeight: '100vh', width: '100%' }}>
          <CssBaseline />
          {children}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', height: '100vh' }}>
          <CssBaseline />
          <Drawer variant="permanent" open={open}>
            {isAuthenticated ? (
              <React.Fragment>
                <DrawerHeader>
                  <IconButton onClick={handleDrawerToggle}>
                    {open ? (
                      <FontAwesomeIcon size="sm" color={theme.blend.white} icon={faChevronLeft} />
                    ) : (
                      <FontAwesomeIcon size="sm" color={theme.blend.white} icon={faChevronRight} />
                    )}
                  </IconButton>
                </DrawerHeader>
                <Box sx={{ display: 'flex', height: '140px', width: '100%', justifyContent: 'center' }}>
                  <Box
                    component="img"
                    sx={{
                      height: open ? 108 : 65,
                      width: open ? 108 : 65,
                      alignSelf: 'center',
                    }}
                    alt="Blend Logo"
                    src="/blendLogo.png"
                  />
                </Box>
                <List sx={{ width: '100%' }}>
                  <NavOption text="Dashboard" path={'/dashboard'} icon={faHome} />
                  <NavOption text="Calendar" path={'/calendar'} icon={faCalendar} />
                  <NavOption text="Clients" path={'/clients'} icon={faUsers} />
                  <NavOption text="Contracts" path={'/contracts'} icon={faScroll} />
                  <NavOption text="Coaches" path={'/coaches'} icon={faMapSigns} />
                  <NavOption text="Coachees" path={'/coachees'} icon={faRocket} />
                  <NavOption text="Questionnaires" path={'/questionnaires'} icon={faQuestion} />
                  <NavOption text="Evaluations" path={'/evaluations'} icon={faStar} />
                  <NavOption text="Reports" path={'/reports'} icon={faChartPie} />
                  <BlendDivider />
                  <NavOption text="Log Out" path={'/logout'} icon={faSignOutAlt} />
                </List>
              </React.Fragment>
            ) : null}
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {isAuthenticated ? children : <Authentication />}
          </Box>
        </Box>
      )}
    </React.Fragment>
  )
}

export { Layout }
