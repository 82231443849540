import React from 'react'
import { useMsal } from '@azure/msal-react'
import { Button } from '@mui/material'
import theme from '../../theme'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { UserStateContext } from '../../providers/User'
import { useHandleLogin } from '../../hooks/useHandleLogin'
import { BlendPrimaryButton } from '../Common/Buttons'

const CustomButton = styled(BlendPrimaryButton)({
  marginTop: '20px',
})

export const SignInButton = () => {
  const { instance } = useMsal()
  const context = React.useContext(UserStateContext)

  return (
    <CustomButton variant="outlined" size="large" color="secondary" onClick={() => useHandleLogin(instance, context)}>
      Sign in
    </CustomButton>
  )
}
