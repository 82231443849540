import React from 'react'
import { Box, Typography } from '@mui/material'
import theme from '../../theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

export const NotFound: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="h1">
        Oh,{' '}
        <span
          style={{
            //color: 'linear-gradient(.25turn, rgba(23,99,175,1) 0%, rgba(0,167,157,1) 50%, rgba(156,203,59,1) 100%)',
            background:
              '-webkit-linear-gradient(0deg, rgba(23,99,175,1) 0%, rgba(0,167,157,1) 50%, rgba(156,203,59,1) 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Blend!
        </span>
      </Typography>
      <Typography variant="h1" fontSize="60pt" color={theme.blend.warning} my="40px">
        Error 404
      </Typography>
      <Box sx={{ marginBottom: '20px' }}>
        <Typography variant="h3">{`We're really lost here...`}</Typography>
      </Box>
      <Box sx={{ display: 'inline-flex' }}>
        <FontAwesomeIcon
          icon={faArrowLeft}
          size="lg"
          style={{ paddingTop: '10px', paddingRight: '10px' }}
        ></FontAwesomeIcon>
        <Typography variant="h3">Try one of the links on the left</Typography>
      </Box>
    </Box>
  )
}
