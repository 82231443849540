/* eslint-disable react/jsx-key */
import React, { ChangeEvent, useMemo, useState } from 'react'

import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useExpanded,
  Row,
  Cell,
  TableState,
  Column,
} from 'react-table'
import { Box, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Table as MuiTable } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import theme from '../../theme'
import { BlendSearchTextField } from '../Common/SearchTextField'
import { BlendPagination } from '../Common/Pagination'

export const StyledTableCell = styled(TableCell)({
  head: {
    backgroundColor: theme.blend.white,
    fontWeight: 'bold',
    border: 'none',
    '&:last-child:not(:first-of-type)': {
      textAlign: 'end',
    },
    '&>svg': {
      verticalAlign: 'bottom',
    },
  },
  root: {
    padding: 8,
    borderBottom: 0,
    '&:first-of-type': {},
    '&:last-child:not(:first-of-type)': {
      textAlign: 'end',
    },
  },
})

export const StyledTableRow = styled(TableRow)({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#EDEDED',
    },
    borderBottom: `1px solid ${theme.blend.darkGrey}`,
  },
})

export const StyledTableHeaderRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.blend.blue,
    th: {
      color: 'white',
    },
  },
})

interface TableRowProps {
  children?: React.ReactNode
  className?: string
}

export const StaticTableRow: React.FC<TableRowProps> = ({ children, className }: TableRowProps) => {
  const cells = React.Children.toArray(children)
  return (
    <StyledTableRow className={className}>
      {cells?.map((label, i) => (
        <StyledTableCell key={typeof label === 'string' ? label + i : i}>{label}</StyledTableCell>
      ))}
    </StyledTableRow>
  )
}

interface TableData extends Object {
  lastUpdated?: Date
}

interface TableProps {
  columns: readonly Column<object>[]
  data: TableData[]
  initialStateOptions?: Partial<TableState<object>>
  searchLabel?: string | undefined
  secondaryColor?: boolean
  showHeader?: boolean
}

export const Table: React.FC<TableProps> = ({
  columns,
  data,
  searchLabel,
  initialStateOptions,
  secondaryColor,
  showHeader,
}: TableProps) => {
  const sortedData = useMemo(
    () => data.sort((a, b) => (b.lastUpdated && a.lastUpdated ? (b.lastUpdated >= a.lastUpdated ? 1 : -1) : 0)),
    [],
  )

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    gotoPage,
    setGlobalFilter,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: sortedData,
      initialState: initialStateOptions ? { ...initialStateOptions, pageIndex: 0 } : { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  )

  const [searchState, setSearchState] = useState('')
  const handleSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const res = evt.target.value
    setSearchState(() => res)
    setGlobalFilter(() => res)
  }

  const handlePaginationChange = (event: ChangeEvent<unknown>, page: number) => {
    gotoPage(page - 1)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        {showHeader ? <Typography variant="h4">{headerGroups[0].headers[0].render('Header')}</Typography> : <Box />}
        {searchLabel && (pageOptions.length >= 1 || searchState) && (
          <BlendSearchTextField placeholder={searchLabel} value={searchState} onChange={handleSearch} />
        )}
      </Box>
      <MuiTable {...getTableProps()}>
        <TableHead>
          {/* {headerGroups.map((headerGroup) => ( */}
          <StyledTableHeaderRow
            {...headerGroups[1].getHeaderGroupProps()}
            sx={{
              '&:nth-of-type(odd)': {
                backgroundColor: secondaryColor ? theme.blend.teal : theme.blend.blue,
              },
            }}
          >
            {headerGroups[1].headers.map((column) => (
              <StyledTableCell
                {...column.getHeaderProps(column.getSortByToggleProps())}
                sx={{
                  textTransform: 'none',
                  fontSize: '13pt',
                }}
              >
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {column.render('Header')}
                  {column.isSorted ? column.isSortedDesc ? <KeyboardArrowDown /> : <KeyboardArrowUp /> : ''}
                </Box>
              </StyledTableCell>
            ))}
          </StyledTableHeaderRow>
          {/* ))} */}
        </TableHead>
        <TableBody>
          {page.map((row: Row<object>) => {
            prepareRow(row)
            return (
              <StyledTableRow {...row.getRowProps()}>
                {row.cells.map((cell: Cell<object, TableData>) => {
                  return (
                    <StyledTableCell
                      {...cell.getCellProps()}
                      sx={{
                        textTransform: 'none',
                        fontSize: '12pt',
                        borderBottom: `1px solid ${theme.blend.midGrey}`,
                      }}
                    >
                      {cell.render('Cell')}
                    </StyledTableCell>
                  )
                })}
              </StyledTableRow>
            )
          })}
        </TableBody>
      </MuiTable>
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px' }}>
        {rows.length > 10 ? (
          <BlendPagination
            count={pageOptions.length}
            page={pageIndex + 1}
            variant="outlined"
            shape="rounded"
            onChange={handlePaginationChange}
            color="standard"
          />
        ) : null}
        <Typography variant="body1" sx={{ fontSize: '13pt' }}>
          Total: {rows.length}
        </Typography>
      </Box>
    </Box>
  )
}
