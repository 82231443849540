export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_AUTH_AUTHORITY ?? '',
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI ?? '',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  scopes: [process.env.REACT_APP_API_SCOPE ?? '', 'User.Read'],
}

export const loginRequest = {
  scopes: [process.env.REACT_APP_API_SCOPE ?? '', 'User.Read'],
}
