import React, { useMemo } from 'react'
import { Typography, Box, IconButton, Grid, CardHeader, Avatar, Card, CardActionArea } from '@mui/material'
import { styled } from '@mui/system'
import { useUserContext } from '../../providers/User'
import { Table } from '../../components/Table/table'
import { useMutation, useQuery } from 'react-query'
import { deleteSession, getCoachSessionHistory, getCoachShortInfo } from '../../queries'
import { useNavigate } from 'react-router-dom'
import { BlendPrimaryButton } from '../../components/Common/Buttons'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ShortCoachResBody } from '../../types/coach_types'
import { GetCoachSessionHistoryRes, SessionStatus } from '../../types/session_type'
import { useDateTimeString } from '../../hooks/useDateTime'
import { BlendCheckbox } from '../../components/Common/Checkbox'
import { BlendBinIcon, BlendEditIcon } from '../../components/Common/Icons'
import { BlendBreadcrumb, BlendBreadcrumbs } from '../../components/Common/Breadcrumbs'
import { BlendFullpageSpinner } from '../../components/Common/Spinner'
import theme from '../../theme'
import { Cell } from 'react-table'
import { useAvatarContext } from '../../providers/Avatars'

const Title = styled(Typography)({
  marginBottom: '20px',
})

interface SessionHistoryTableData {
  id: string
  transactionId: string
  contractName: string
  contractHandle: string
  coacheeName: string
  status: string
  isPaid: boolean
  lastUpdated: Date
}

export const SessionHistory: React.FC = () => {
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  const coachId: string | null = urlParams.get('coachId')

  const context = useUserContext()
  const navigate = useNavigate()

  const {
    isFetching: isCoachShortFetching,
    isError: isCoachShortError,
    data: coachShortData,
  } = useQuery<ShortCoachResBody>({
    queryFn: () => getCoachShortInfo(context, coachId),
    queryKey: ['coachInfo', coachId],
    enabled: !!context.token && !!coachId,
    onError: (e) => console.error(e),
  })

  const {
    isFetching: isCoachSessionHistoryFetching,
    isError: isCoachSessionHistoryError,
    data: coachSessionHistoryData,
  } = useQuery<GetCoachSessionHistoryRes>({
    queryFn: () => getCoachSessionHistory(context, coachId),
    queryKey: ['getCoachSessionHistoryData', coachId],
    enabled: !!context.token && !!coachId,
    onError: (e) => console.error(e),
  })

  const mutation = useMutation((id: string) => deleteSession(context, id), {
    onError: (error) => console.error(error),
  })

  const handleDelete = (id: string, coacheeName: string) => {
    if (confirm(`Are you sure you want to delete entry for ${coacheeName}?`)) mutation.mutate(id)
  }

  const handleBackToCoachClick = () => navigate(`/coaches/profile?id=${coachId}`)

  const handleAddSessionClick = () => navigate(`/session/create`)

  const tableData = useMemo(
    () =>
      coachSessionHistoryData?.sessions.map<SessionHistoryTableData>((session) => {
        return {
          id: session.id,
          transactionId: session.transactionId,
          contractName: session.contractName,
          contractHandle: session.contractHandle,
          coacheeName: session.coacheeName,
          status: SessionStatus[session.status as keyof typeof SessionStatus]?.valueOf(),
          isPaid: session.isPaid,
          lastUpdated: session.lastUpdated,
        }
      }),
    [coachSessionHistoryData],
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Session History',
        columns: [
          {
            Header: 'Contract',
            id: 'contractName',
            accessor: 'contractName',
          },
          {
            Header: 'Contract Handle',
            id: 'contractHandle',
            accessor: 'contractHandle',
          },
          {
            Header: 'Coachee',
            id: 'coacheeName',
            accessor: 'coacheeName',
          },
          {
            Header: 'Status',
            id: 'status',
            accessor: 'status',
          },
          {
            Header: 'Paid',
            id: 'isPaid',
            accessor: 'isPaid',
            Cell: ({ row }: Cell<SessionHistoryTableData>) => <BlendCheckbox checked={row.values.isPaid} />,
          },
          {
            Header: 'Last Updated',
            id: 'lastUpdated',
            accessor: 'lastUpdated',
            Cell: ({ row }: Cell<SessionHistoryTableData>) => useDateTimeString(row.original.lastUpdated),
          },
          {
            Header: '',
            id: 'actions',
            accessor: 'id',
            Cell: ({ row }: Cell<SessionHistoryTableData>) => {
              return (
                <Box sx={{ float: 'right' }}>
                  <IconButton onClick={() => navigate(`/session/editPast?id=${row.original.id}`)} sx={{ mr: '12px' }}>
                    <BlendEditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(row.original.id, row.values.coacheeName)}>
                    <BlendBinIcon />
                  </IconButton>
                </Box>
              )
            },
          },
        ],
      },
    ],
    [],
  )

  const avatar = useAvatarContext(coachShortData?.avatarFilename)

  const breadcrumbs: BlendBreadcrumb[] = [
    { label: 'Coaches', link: '/coaches' },
    {
      label: `${coachShortData?.firstName ?? ''} ${coachShortData?.lastName ?? ''}`,
      link: `/coaches/profile?id=${coachId}`,
    },
    { label: 'Session History' },
  ]

  return !isCoachShortFetching && coachShortData ? (
    <Box>
      <BlendBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container direction="row">
        <Grid item md={6}>
          <BlendPrimaryButton onClick={handleBackToCoachClick} sx={{ marginY: '10px' }}>
            <ArrowBackIcon />
          </BlendPrimaryButton>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Title variant="h2">Session History</Title>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Card
            sx={{
              height: '84px',
              width: '254px',
              backgroundColor: coachShortData.isActive ? '#FFFFFF' : '#EFEFEF',
              borderRadius: '10px',
              display: 'flex',
              boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.15)',
              float: 'right',
            }}
          >
            <CardActionArea onClick={handleBackToCoachClick}>
              <CardHeader
                avatar={<Avatar src={avatar} sx={{ height: '59px', width: '59px' }} />}
                title={
                  <>
                    <Typography variant="h6" lineHeight={1}>
                      {coachShortData.firstName} {coachShortData.lastName}
                    </Typography>
                    <Typography
                      color={coachShortData.isActive ? theme.blend.teal : theme.blend.midGrey}
                      fontSize="16px"
                    >
                      {coachShortData.isActive ? 'Active' : 'Inactive'}
                    </Typography>
                  </>
                }
                sx={{ p: '0', ml: '17px' }}
              />
            </CardActionArea>
          </Card>
          <BlendPrimaryButton
            sx={{
              display: 'flex',
              float: 'right',
              marginTop: '16px',
              marginRight: '23px',
            }}
            onClick={handleAddSessionClick}
          >
            Add a session
          </BlendPrimaryButton>
        </Grid>
      </Grid>
      {(isCoachSessionHistoryError || isCoachShortError) && <h1>Could not fetch session history</h1>}
      {(isCoachSessionHistoryFetching || isCoachShortFetching) && <BlendFullpageSpinner />}
      {!isCoachSessionHistoryFetching && coachSessionHistoryData && !coachSessionHistoryData.sessions.length && (
        <h1>There is no session history</h1>
      )}
      {!isCoachSessionHistoryFetching && tableData && tableData.length && (
        <Table columns={columns} data={tableData} searchLabel="Search" />
      )}
    </Box>
  ) : (
    <BlendFullpageSpinner />
  )
}
