import { GetSessionRes, SessionStatus } from '../types/session_type'

export interface SessionEditAction {
  type: 'SESSION' | 'SELECT' | 'STATUS' | 'STRING' | 'DATE' | 'TIME' | 'CHECKBOX'
  field: string
  payload: any
}

const getFullDateTime = (time: string, date?: Date | null) => {
  if (time) {
    const timeArray = time.split(':')
    const dateCopy = date ? new Date(date) : new Date()
    dateCopy.setHours(Number(timeArray[0]), Number(timeArray[1]), 0)
    return dateCopy
  } else return null
}

export const sessionReducer = (state: GetSessionRes, action: SessionEditAction): GetSessionRes => {
  switch (action.type) {
    case 'SESSION':
      return {
        ...(action.payload as GetSessionRes),
      }
    case 'SELECT':
    case 'STRING':
      return {
        ...state,
        [action.field]: action.payload,
      }
    case 'STATUS':
      return {
        ...state,
        status: SessionStatus[action.payload as keyof typeof SessionStatus],
      }
    case 'DATE':
      const newDate = new Date(action.payload)
      return {
        ...state,
        start: state.start
          ? new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate(),
              state.start.getHours(),
              state.start.getMinutes(),
            )
          : newDate,
        end: state.end
          ? new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate(),
              state.end.getHours(),
              state.end.getMinutes(),
            )
          : newDate,
      }
    case 'TIME':
      if (action.field === 'startTime')
        return {
          ...state,
          start: getFullDateTime(action.payload, state.start),
        }
      else
        return {
          ...state,
          end: getFullDateTime(action.payload, state.end),
        }
    case 'CHECKBOX':
      return {
        ...state,
        [action.field]: action.payload as boolean,
      }
  }
}
