import { Client } from '../types/client_type'
import { hasPrimaryContact, isEmail, isPhoneNumber, isRequired } from './validations'

export const clientValidator = (client: Client) => {
  const errors: string[] = []
  errors.push(isRequired('Client name', client.name))

  client.contacts?.map((contact, index) => {
    errors.push(isRequired(`Contact ${index}: First name`, contact.firstName))
    errors.push(isRequired(`Contact ${index}: Last name`, contact.lastName))
    errors.push(isEmail(`Contact ${index}`, contact.email))
    errors.push(isPhoneNumber(`Contact ${index}`, contact.mobile))
  })

  errors.push(hasPrimaryContact(client.contacts))

  return errors.filter((error) => error.length > 1)
}
