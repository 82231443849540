import * as React from 'react'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import { StepIconProps } from '@mui/material/StepIcon'

const BlendConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 7,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#00A79D',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#00A79D',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 6,
    width: '100%',
    border: 0,
    backgroundColor: '#E5E5E5',
    borderRadius: 1,
  },
}))

const BlendStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean }
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 20,
  height: 20,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#00A79D',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#00A79D',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
}))

function BlendStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return <BlendStepIconRoot ownerState={{ completed, active }} className={className} />
}

const steps = [
  'Questionnaire sent',
  'Questionnaire submitted',
  'First session',
  'Active programme',
  'Evaluation sent',
  'Evaluation submitted',
]

interface ReferralStepperProps {
  activeStep: number
}

export const ReferralStepper = (props: ReferralStepperProps) => {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel activeStep={props.activeStep} connector={<BlendConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={BlendStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  )
}
