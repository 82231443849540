import {
  Box,
  Grid,
  SelectChangeEvent,
  styled,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from '@mui/material'
import React, { useMemo, useReducer, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ContractProps } from '..'
import { EditContact, newContact } from '../../../../components/Contact'
import { BlendBreadcrumb, BlendBreadcrumbs } from '../../../../components/Common/Breadcrumbs'
import { useUserContext } from '../../../../providers/User'
import { contractReducer } from '../../../../reducers/contractReducer'
import theme from '../../../../theme'
import { GetAllClientsRes } from '../../../../types/client_type'
import { ClientContact, OfficeUser } from '../../../../types/contact_types'
import { Contract } from '../../../../types/contract_type'
import { BlendTab, BlendTabsGroup } from '../../../../components/Common/Tabs'
import { BlendTextField } from '../../../../components/Common/TextField'
import { BlendMenuItem, BlendSelect } from '../../../../components/Common/Select'
import { BlendDatePicker } from '../../../../components/Common/DatePicker'
import { BlendDestructiveButton, BlendPrimaryButton, BlendSecondaryButton } from '../../../../components/Common/Buttons'
import { BlendDivider } from '../../../../components/Common/Divider'
import { v4 as uuid } from 'uuid'
import { BlendFullpageSpinner } from '../../../../components/Common/Spinner'
import { BlendDialog } from '../../../../components/Common/Dialog'
import { contractValidator } from '../../../../validators/contractValidator'
import { useNavigate } from 'react-router-dom'
import { createContract, deleteContract, getClients, getOfficeUsers, updateContract } from '../../../../queries'
import { BlendRadio } from '../../../../components/Common/Checkbox'
import { useDialogContext } from '../../../../providers/Dialog'

const TextBox = styled(Typography)({
  border: `0.5px solid ${theme.blend.darkGrey}`,
  borderRadius: '4px',
  marginLeft: '10px',
  padding: '8px 19px',
  marginTop: '4px',
  position: 'relative',
  top: '20px',
})

interface ContractAddProps {
  contract?: Contract
  clientId: string
  callback: () => void
}

interface OfficeUsersRes {
  officeUsers: OfficeUser[]
}

const newContract = (clientId?: string): Contract => {
  return {
    id: uuid(),
    contractName: '',
    contractHandle: '',
    sessionQuota: 0,
    coacheesQuota: 0,
    sessionLength: 0,
    sessionsPerCoachee: 0,
    isGroup: false,
    isOneToOne: true,
    rate: 0,
    clientId: clientId,
    contacts: [{ ...newContact, id: uuid() }],
  }
}

export const EditContract: React.FC<ContractProps | ContractAddProps> = (props) => {
  const dialog = useDialogContext()
  const [contractDetailsState, setContractDetailsState] = useReducer(
    contractReducer,
    props.contract
      ? {
          ...props.contract,
          contacts: props.contract.contacts
            ? [...props.contract.contacts, { ...newContact, id: uuid() }]
            : [{ ...newContact, id: uuid() }],
          sessions: undefined,
          coachees: undefined,
        }
      : newContract(props.clientId),
  )
  const [tabState, setTabState] = useState(0)
  const context = useUserContext()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const isEditState = !!props.contract

  const clientsQuery = useQuery<GetAllClientsRes>({
    queryFn: () => getClients(context),
    queryKey: 'getAllClientsData',
    enabled: !!context.token,
  })

  const officeUsersQuery = useQuery<OfficeUsersRes>({
    queryFn: () => getOfficeUsers(context),
    queryKey: 'getAllOfficeUsersData',
    enabled: !!context.token,
  })

  const mutation = useMutation<unknown, Error>({
    mutationFn: async () =>
      isEditState
        ? updateContract(context, { ...contractDetailsState, contacts: filterLastContact() })
        : createContract(context, { ...contractDetailsState, contacts: filterLastContact() }),
    onError: async (error) => await dialog.fetchErrorDialog(JSON.parse(error.message)),
    onSuccess: async () => {
      await queryClient.refetchQueries(['getContractData', contractDetailsState.id])
      await queryClient.invalidateQueries(['getClientData', contractDetailsState.clientId])
      queryClient.invalidateQueries('getAllContractData').then(() => props.callback())
    },
  })

  const handleEditButton = async () => {
    const contractValidation = contractValidator({ ...contractDetailsState, contacts: filterLastContact() })
    if (contractValidation.length > 0) {
      await dialog.openDialog(contractValidation, 3)
    } else {
      await dialog.openConfirmDialog('Are you sure you want to save this contract?', async () => {
        mutation.mutate()
      })
    }
  }

  const deleteMutation = useMutation({
    mutationFn: () => deleteContract(context, contractDetailsState.id),
    onError: (error) => console.error(error),
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllContractsData')
      await navigate('/contracts')
    },
  })

  const handleDelete = async () => {
    dialog.openDeleteDialog(contractDetailsState.contractName, () => deleteMutation.mutate())
  }

  const filterLastContact = () => {
    const contactDetails: ClientContact[] = []
    contractDetailsState.contacts?.map((contact, index) => {
      if (
        contractDetailsState.contacts &&
        (index < contractDetailsState.contacts.length - 1 ||
          (index === contractDetailsState.contacts.length - 1 &&
            (contact.firstName || contact.lastName || contact.email || contact.mobile)))
      ) {
        contactDetails.push({ ...contact, id: uuid() })
      }
    })
    return contactDetails
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabState(newValue)
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setContractDetailsState({
      type: 'STRING',
      field: [e.target.name],
      payload: e.target.value,
    })
  }

  const handleSelectChange = (e: SelectChangeEvent<unknown>) => {
    setContractDetailsState({
      type: 'STRING',
      field: [e.target.name],
      payload: e.target.value as string,
    })
  }

  const handleContactCallback = (contact: ClientContact) => {
    setContractDetailsState({
      type: 'CONTACT',
      field: [contact.id],
      payload: contact,
    })
  }

  const handleContactButtonCallback = (isCreate: boolean, index?: number) => {
    if (isCreate) {
      setContractDetailsState({
        type: 'CREATE_CONTACT',
        field: [],
        payload: { ...newContact, id: uuid() },
      })
    } else if (!isCreate && index != undefined && index > -1) {
      setContractDetailsState({
        type: 'DELETE_CONTACT',
        field: [],
        payload: index,
      })
    }
  }

  const sessionQuota = useMemo(
    () =>
      contractDetailsState.sessionsPerCoachee &&
      contractDetailsState.coacheesQuota &&
      contractDetailsState.sessionsPerCoachee > 0 &&
      contractDetailsState.coacheesQuota > 0
        ? contractDetailsState.sessionsPerCoachee * contractDetailsState.coacheesQuota
        : 0,
    [contractDetailsState.sessionsPerCoachee, contractDetailsState.coacheesQuota],
  )

  const breadcrumbs: BlendBreadcrumb[] = [
    { label: 'Contract Manager', link: '/contracts' },
    { label: props.contract ? contractDetailsState.contractName : 'Create New Contract' },
  ]

  return contractDetailsState ? (
    <>
      <BlendDialog {...dialog} />
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        sx={{ minHeight: '100%', width: '100%' }}
      >
        <Box>
          <BlendBreadcrumbs breadcrumbs={breadcrumbs} />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h2" mt="20px">
              {isEditState ? 'Edit contract' : 'Create new contract'}
            </Typography>
            <Box>
              <BlendSecondaryButton
                variant={'contained'}
                sx={{ mr: '11px' }}
                onClick={() => (props.contract ? props.callback() : navigate(-1))}
              >
                Cancel
              </BlendSecondaryButton>
              <BlendPrimaryButton variant={'contained'} color={'success'} onClick={handleEditButton}>
                Save
              </BlendPrimaryButton>
            </Box>
          </Box>
          <BlendTabsGroup
            sx={{ marginY: '20px' }}
            value={tabState}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            variant="fullWidth"
          >
            <BlendTab label="Details" />
            <BlendTab label="Contacts" />
          </BlendTabsGroup>
          {
            {
              0: (
                <Grid container direction="column" justifyContent="center" alignItems="stretch" md={12} xl={10}>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={'20px'}
                    xs={12}
                  >
                    <Grid item sx={{ display: 'flex', flexDirection: 'column' }} xs={4}>
                      <BlendTextField
                        label="Contract Name"
                        required
                        type="text"
                        name="contractName"
                        sx={{ paddingTop: 1 }}
                        value={contractDetailsState.contractName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item sx={{ display: 'flex', flexDirection: 'column' }} xs={4}>
                      <BlendSelect
                        label="Client"
                        required
                        name="clientId"
                        value={contractDetailsState.clientId ?? props.clientId ?? 'n/a'}
                        onChange={handleSelectChange}
                        sx={{ marginTop: 1 }}
                      >
                        <BlendMenuItem key={'n/a'} value={'n/a'}>
                          Select Option
                        </BlendMenuItem>
                        {clientsQuery.data?.clients.map((client) => {
                          return (
                            <BlendMenuItem key={client.id} value={client.id}>
                              {client.name}
                            </BlendMenuItem>
                          )
                        })}
                      </BlendSelect>
                    </Grid>
                    <Grid item sx={{ display: 'flex', flexDirection: 'column' }} xs={4}>
                      <BlendTextField
                        label="Contract Handle"
                        required
                        type="text"
                        name="contractHandle"
                        value={contractDetailsState.contractHandle}
                        sx={{ paddingTop: 1 }}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={'20px'}
                    sx={{ marginY: '20px' }}
                    xs={12}
                  >
                    <Grid item sx={{ display: 'flex', flexDirection: 'column' }} xs={4}>
                      <Typography variant={'body1'}>Coaching Type</Typography>
                      <FormControl sx={{ paddingTop: 1 }}>
                        <RadioGroup defaultValue={'1:1'}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="center"
                            sx={{ height: '60px' }}
                          >
                            <FormControlLabel value={'1:1'} control={<BlendRadio value={'1:1'} />} label={'1:1'} />
                            <FormControlLabel
                              value={'Group'}
                              control={<BlendRadio value={'Group'} disabled />}
                              label={'Group'}
                            />
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sx={{ display: 'flex', flexDirection: 'column' }} xs={4}>
                      <BlendDatePicker
                        required
                        label="Start Date"
                        name="startDate"
                        value={contractDetailsState.startDate ? contractDetailsState.startDate : null}
                        onChange={(date: Date | null) => {
                          setContractDetailsState({
                            type: 'DATE',
                            field: ['startDate'],
                            payload: date ? date : undefined,
                          })
                        }}
                      />
                    </Grid>
                    <Grid item sx={{ display: 'flex', flexDirection: 'column' }} xs={4}>
                      <BlendDatePicker
                        required
                        label="Expiration Date"
                        name="expirationDate"
                        value={contractDetailsState.expirationDate ? contractDetailsState.expirationDate : null}
                        onChange={(date: Date | null) => {
                          setContractDetailsState({
                            type: 'DATE',
                            field: ['expirationDate'],
                            payload: date ? date : undefined,
                          })
                        }}
                      />
                    </Grid>
                  </Grid>
                  <BlendDivider sx={{ paddingtop: '20px', marginBottom: '20px' }} />
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={'20px'}
                    xs={12}
                  >
                    <Grid item sx={{ display: 'flex', flexDirection: 'column' }} xs={4}>
                      <BlendTextField
                        label="Sessions Per Coachee"
                        type="number"
                        name="sessionsPerCoachee"
                        value={contractDetailsState.sessionsPerCoachee}
                        sx={{ paddingTop: 1 }}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item sx={{ display: 'flex', flexDirection: 'column' }} xs={4}>
                      <BlendTextField
                        label="Number Of Coachees"
                        type="number"
                        name="coacheesQuota"
                        value={contractDetailsState.coacheesQuota}
                        sx={{ paddingTop: 1 }}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item sx={{ display: 'flex', flexDirection: 'column' }} xs={4}>
                      <BlendTextField
                        label="Contracted Sessions"
                        disabled={true}
                        type="number"
                        name="sessionQuota"
                        value={sessionQuota}
                        sx={{ paddingTop: 1 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={'20px'}
                    sx={{ marginY: '20px' }}
                    xs={12}
                  >
                    <Grid item sx={{ display: 'flex', flexDirection: 'column' }} xs={4}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <BlendTextField
                          label="Session Length"
                          required
                          type="number"
                          name="sessionLength"
                          value={contractDetailsState.sessionLength}
                          sx={{ paddingTop: 1 }}
                          onChange={handleChange}
                        />
                        <TextBox variant="body1">Mins</TextBox>
                      </Box>
                    </Grid>
                    <Grid item sx={{ display: 'flex', flexDirection: 'column' }} xs={4}>
                      <BlendSelect
                        label="Blend Contract Manager"
                        required
                        name="officeUserId"
                        value={contractDetailsState.officeUserId ?? 'n/a'}
                        onChange={handleSelectChange}
                        sx={{ marginTop: 1 }}
                      >
                        <BlendMenuItem key={'n/a'} value={'n/a'}>
                          Select Option
                        </BlendMenuItem>
                        {officeUsersQuery.data?.officeUsers.map((user) => {
                          return (
                            <BlendMenuItem key={user.id} value={user.id}>
                              {`${user.firstName} ${user.lastName}`}
                            </BlendMenuItem>
                          )
                        })}
                      </BlendSelect>
                    </Grid>
                  </Grid>
                </Grid>
              ),
              1: (
                <Box>
                  {contractDetailsState.contacts?.map((contact: ClientContact, index: number) => {
                    return (
                      <EditContact
                        key={contact.id}
                        contact={contact}
                        index={index}
                        title={index == (contractDetailsState.contacts?.length ?? 0) - 1 ? 'New Contact' : undefined}
                        isContractContact
                        handleCallback={handleContactCallback}
                        handleButtonCallback={handleContactButtonCallback}
                      />
                    )
                  })}
                </Box>
              ),
            }[tabState]
          }
        </Box>
        {isEditState && (
          <Box>
            <BlendDivider sx={{ paddingTop: '20px' }} />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ paddingTop: '20px' }}
            >
              <Box>
                <BlendDestructiveButton variant={'contained'} onClick={handleDelete}>
                  Delete contract
                </BlendDestructiveButton>
              </Box>
            </Grid>
          </Box>
        )}
      </Grid>
    </>
  ) : (
    <BlendFullpageSpinner />
  )
}
