import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { BlendPrimaryButton, BlendSecondaryButton } from '../../../components/Common/Buttons'
import { useUserContext } from '../../../providers/User'
import { createCoachee, getActiveContracts, getClients, getCoachee, updateCoachee } from '../../../queries'
import { BlendTextField } from '../../../components/Common/TextField'
import { CoacheeContact } from '../../../types/contact_types'
import { GetActiveContractsRes, GetActiveContractsResBody } from '../../../types/contract_type'
import { GetCoacheeRes } from '../../../types/coachee_type'
import { Coachee, CoacheeStatus } from '../../../types/coachee_type'
import { GetAllClientsRes, GetAllClientsResBody } from '../../../types/client_type'
import { coacheeReducer } from '../../../reducers/coacheeReducer'
import { ContractView } from './contract/view'
import { BlendBreadcrumb, BlendBreadcrumbs } from '../../../components/Common/Breadcrumbs'
import { BlendDivider } from '../../../components/Common/Divider'
import { v4 as uuid } from 'uuid'
import { BlendFullpageSpinner } from '../../../components/Common/Spinner'
import { BlendAutocomplete } from '../../../components/Common/Autocomplete'
import { coacheeValidator } from '../../../validators/coacheeValidator'
import { useDialogContext } from '../../../providers/Dialog'

const coacheeContactInitState: Partial<CoacheeContact> = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  isPrimary: true,
  isDelegate: true,
  isActive: true,
  alternativeEmail: '',
}

export const CoacheeEditor: React.FC = () => {
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  const coacheeId: string | null = urlParams.get('id')
  const clientId: string | null = urlParams.get('clientId')
  const contractId: string | null = urlParams.get('contractId')

  const queryClient = useQueryClient()
  const context = useUserContext()
  const navigate = useNavigate()
  const dialog = useDialogContext()

  const coacheeInitState: Coachee = useMemo(() => {
    return { id: uuid(), contractId: '', clientId: '', status: CoacheeStatus.Referral }
  }, [])

  const {
    error: coacheeError,
    isFetching: coacheeFetching,
    data: coacheeData,
  } = useQuery<GetCoacheeRes>({
    queryFn: () => getCoachee(context, coacheeId),
    queryKey: ['getCoacheeData', coacheeId],
    enabled: !!context.token && !!coacheeId,
    onSuccess: (data: GetCoacheeRes) => {
      setCoacheeState({
        type: 'SET',
        field: '',
        payload: {
          coachee: data.coachee,
          contactDelegate: data.contacts.filter((contact: CoacheeContact) => contact.isDelegate)[0],
          contactPrimary:
            data.contacts.filter((contact: CoacheeContact) => contact.isPrimary && !contact.isDelegate)[0] ?? undefined,
        },
      })
    },
  })

  const {
    error: clientsError,
    isFetching: clientsFetching,
    data: clientsData,
  } = useQuery<GetAllClientsRes>({
    queryFn: () => getClients(context),
    queryKey: 'getAllClientsData',
    enabled: !!context.token,
  })

  const {
    error: contractsError,
    isFetching: contractsFetching,
    data: contractsData,
  } = useQuery<GetActiveContractsRes>({
    queryFn: () => getActiveContracts(context),
    queryKey: 'getActiveContractsData',
    enabled: !!context.token,
  })

  const [coacheeState, setCoacheeState] = useReducer(
    coacheeReducer,
    coacheeData
      ? {
          contactDelegate:
            coacheeData?.contacts.filter((contact: CoacheeContact) => contact.isDelegate)[0] ?? undefined,
          contactPrimary:
            coacheeData?.contacts.filter((contact: CoacheeContact) => contact.isPrimary && !contact.isDelegate)[0] ??
            undefined,
          coachee: coacheeData.coachee,
        }
      : {
          contactDelegate: coacheeContactInitState,
          contactPrimary: { ...coacheeContactInitState, isDelegate: false },
          coachee: {
            ...coacheeInitState,
            contractId: contractId ?? undefined,
            clientId: clientId ?? undefined,
            status: CoacheeStatus.Referral,
          },
        },
  )

  const [clientState, setClientState] = useState<GetAllClientsResBody | null>(
    clientsData?.clients.find((client: GetAllClientsResBody) => client.id === coacheeState.coachee.clientId) ?? null,
  )

  const [contractState, setContractState] = useState<GetActiveContractsResBody | null>(
    contractsData?.contracts.find(
      (contract: GetActiveContractsResBody) => contract.id === coacheeState.coachee.contractId,
    ) ?? null,
  )

  //Ensures contracts and clients are set correctly upon loading
  useEffect(() => {
    if (contractId) {
      setCoacheeState({
        type: 'CONTRACT',
        field: '',
        payload: contractId,
      })
      setContractState(
        contractsData?.contracts.find((contract: GetActiveContractsResBody) => contract.id === contractId) ?? null,
      )
    }
    if (clientId) {
      setClientState(clientsData?.clients.find((client: GetAllClientsResBody) => client.id === clientId) ?? null)
    }
  }, [contractId, clientId, clientsData, contractsData])

  const filteredContracts = useMemo(() => {
    return contractsData?.contracts.filter((contract) => (clientState ? contract.clientId === clientState.id : true))
  }, [clientState, clientsData, contractsData])

  const isFetching = coacheeFetching || clientsFetching || contractsFetching
  const isError = !!coacheeError || !!clientsError || !!contractsError

  const mutation = useMutation<unknown, Error>({
    mutationFn: async () => {
      if (!!coacheeId) updateCoachee(context, getCoacheeObjects())
      else createCoachee(context, getCoacheeObjects())
    },
    onError: async (error) => await dialog.fetchErrorDialog(JSON.parse(error.message)),
    // Use onSettled instead of onSuccess
    onSettled: async () => {
      await queryClient.refetchQueries('getAllCoacheesData')
      await queryClient.invalidateQueries(['getContractData', coacheeState.coachee.contractId])
      if (clientState) await queryClient.refetchQueries(['getClientData', clientState.id])
      if (coacheeId)
        await queryClient.refetchQueries(['getCoacheeData', coacheeId]).then(() => {
          navigate(`/coachees/profile?id=${coacheeState.coachee.id}` ?? '/coachees')
        })
      else navigate(`/contracts/profile?id=${coacheeState.coachee.contractId}` ?? '/coachees')
    },
  })

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, isPrimary?: boolean) => {
    const { name, value } = e.target
    setCoacheeState({
      type: 'STRING',
      field: name,
      payload: value,
      isContactPrimary: isPrimary ?? false,
    })
  }

  const checkCoacheeContact = (contact: Partial<CoacheeContact> | undefined) => {
    return (contact && !!contact.firstName && !!contact.lastName && !!contact.email && !!contact.mobile) ?? false
  }

  const getCoacheeObjects = (): Coachee => {
    const str =
      checkCoacheeContact(coacheeState.contactPrimary) && !!coacheeState.contactPrimary
        ? {
            ...coacheeState.coachee,
            contacts: [
              {
                ...coacheeState.contactDelegate,
                id: coacheeState.contactDelegate.id ?? uuid(),
                isDelegate: true,
                isPrimary: false,
                alternativeEmail:
                  coacheeState.contactDelegate.alternativeEmail && coacheeState.contactDelegate.alternativeEmail !== ''
                    ? coacheeState.contactDelegate.alternativeEmail
                    : null,
                isActive: true,
              },
              {
                ...coacheeState.contactPrimary,
                id: coacheeState.contactPrimary.id ?? uuid(),
                isDelegate: false,
                isPrimary: true,
                alternativeEmail:
                  coacheeState.contactPrimary.alternativeEmail && coacheeState.contactPrimary.alternativeEmail !== ''
                    ? coacheeState.contactPrimary.alternativeEmail
                    : null,
                isActive: true,
              },
            ],
          }
        : {
            ...coacheeState.coachee,
            contacts: [
              {
                ...coacheeState.contactDelegate,
                id: coacheeState.contactDelegate.id ?? uuid(),
                isDelegate: true,
                isPrimary: true,
                isActive: true,
              },
            ],
          }
    return str
  }

  const handleConfirmClick = () => {
    const coacheeValidation = coacheeValidator(coacheeState)
    if (coacheeValidation.length > 0) {
      dialog.openDialog(coacheeValidation, 3)
    } else if (
      contractState &&
      contractState.coacheeQuota !== undefined &&
      contractState.assignedCoachees >= contractState.coacheeQuota
    ) {
      dialog.openConfirmDialog(
        'This contract has reached its maximum number of referrals, are you sure you want to save these changes?',
        async () => {
          await mutation.mutateAsync()
        },
      )
    } else {
      dialog.openConfirmDialog('Are you sure you want to save these changes?', async () => {
        await mutation.mutateAsync()
      })
    }
  }

  const pageTitle = coacheeData?.contacts
    ? `${coacheeData?.contacts[0].firstName} ${coacheeData?.contacts[0].lastName}`
    : 'Create New Referral'
  const breadcrumbs: BlendBreadcrumb[] = [{ label: 'Coachees', link: '/coachees' }, { label: pageTitle }]

  return !isFetching ? (
    <Box>
      <BlendBreadcrumbs breadcrumbs={breadcrumbs} />
      <Grid container direction="column" justifyContent="space-between" alignItems="stretch">
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '25px' }}>
          <Typography variant="h2">{pageTitle}</Typography>
          <Box>
            <BlendSecondaryButton variant="contained" sx={{ mr: '11px' }} onClick={() => navigate(-1)}>
              Cancel
            </BlendSecondaryButton>
            {!isFetching && !isError && (
              <BlendPrimaryButton variant="contained" onClick={handleConfirmClick}>
                Save
              </BlendPrimaryButton>
            )}
          </Box>
        </Box>
        <Grid item pt="42px">
          <Typography variant="h4" mb="15px">
            Assigned contract
          </Typography>
          {!coacheeId && clientsData && (
            <Grid container direction="row" pb="40px">
              <Grid item md={4} pr="10px">
                <BlendAutocomplete
                  required
                  name="clientId"
                  label="Client"
                  width="100%"
                  value={clientState}
                  options={clientsData.clients}
                  getOptionLabel={(option) => (option as GetAllClientsResBody)?.name || ''}
                  onChange={(event, value) => {
                    const client = value as GetAllClientsResBody
                    setClientState(client ?? null)
                    setContractState(client && clientState && client.id === clientState.id ? contractState : null)
                  }}
                />
              </Grid>
              <Grid item md={4} pl="10px" pr="10px">
                <BlendAutocomplete
                  required
                  name="contractId"
                  label="Contract"
                  width="100%"
                  value={contractState}
                  options={filteredContracts ?? []}
                  getOptionLabel={(option) => (option as GetActiveContractsResBody)?.contractHandle || ''}
                  onChange={(event, value) => {
                    const contract = value as GetActiveContractsResBody
                    if (contract)
                      setCoacheeState({
                        type: 'CONTRACT',
                        field: '',
                        payload: contract.id,
                      })
                    setContractState(contract ?? null)
                    setClientState(
                      clientsData?.clients.find((client: GetAllClientsResBody) => client.id === contract.clientId) ??
                        null,
                    )
                  }}
                />
              </Grid>
              <Grid item md={4} pl="10px">
                <BlendTextField
                  disabled
                  //readonly={true}
                  label="Status"
                  placeholder="Status"
                  name="status"
                  width="100%"
                  value={coacheeState.coachee.status}
                />
              </Grid>
            </Grid>
          )}
          {coacheeId && coacheeData && (
            <ContractView
              clientName={coacheeData?.coachee.clientName}
              contractHandle={coacheeData?.coachee.contractHandle}
              status={coacheeData?.coachee.statusStep}
            />
          )}
        </Grid>
        {/* {!coacheeContactId && (
          <Grid item md={12} mb="39px">
            <PrimaryButton variant="contained" sx={{ height: '38px', float: 'right' }} onClick={handleAddContractClick}>
              Add additional contract
            </PrimaryButton>
          </Grid>
        )} */}
        {!isFetching && !isError && (
          <>
            <BlendDivider sx={{ marginBottom: '20px' }} />
            <Grid container item direction="row" pb="58px">
              <Typography variant="h4" mb="15px">
                {`Coachee Contact Details`}
              </Typography>
              <Grid container item direction="row" pb="24px">
                <Grid item md={6} pr="38px">
                  <BlendTextField
                    required
                    width="100%"
                    label="First Name"
                    name="firstName"
                    placeholder="First Name"
                    value={coacheeState.contactDelegate.firstName}
                    onChange={handleTextFieldChange}
                  />
                </Grid>
                <Grid item md={6} pl="38px">
                  <BlendTextField
                    required
                    width="100%"
                    label="Last Name"
                    name="lastName"
                    placeholder="Last Name"
                    value={coacheeState.contactDelegate.lastName}
                    onChange={handleTextFieldChange}
                  />
                </Grid>
              </Grid>
              <Grid container item direction="row">
                <Grid item md={4} pr="11px">
                  <BlendTextField
                    required
                    width="100%"
                    label="Email"
                    name="email"
                    placeholder="Email"
                    value={coacheeState.contactDelegate.email}
                    onChange={handleTextFieldChange}
                  />
                </Grid>
                <Grid item md={4} pl="11px" pr="11px">
                  <BlendTextField
                    width="100%"
                    label="Phone Number"
                    name="mobile"
                    placeholder="Phone Number"
                    value={coacheeState.contactDelegate.mobile}
                    onChange={handleTextFieldChange}
                  />
                </Grid>
                <Grid item md={4} pl="11px">
                  <BlendTextField
                    width="100%"
                    label="Alternative Email"
                    name="alternativeEmail"
                    placeholder="Alternative Email"
                    value={coacheeState.contactDelegate.alternativeEmail}
                    onChange={handleTextFieldChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <BlendDivider sx={{ marginBottom: '20px' }} />{' '}
            <Grid container item direction="row" pb="58px">
              <Typography variant="h4" mb="15px">
                Secondary Contact Details (Optional)
              </Typography>
              <Grid container item direction="row" pb="24px">
                <Grid item md={6} pr="38px">
                  <BlendTextField
                    width="100%"
                    label="First Name"
                    name="firstName"
                    placeholder="First Name"
                    value={coacheeState.contactPrimary?.firstName ?? ''}
                    onChange={(e) => handleTextFieldChange(e, true)}
                  />
                </Grid>
                <Grid item md={6} pl="38px">
                  <BlendTextField
                    width="100%"
                    label="Last Name"
                    name="lastName"
                    placeholder="Last Name"
                    value={coacheeState.contactPrimary?.lastName ?? ''}
                    onChange={(e) => handleTextFieldChange(e, true)}
                  />
                </Grid>
              </Grid>
              <Grid container item direction="row">
                <Grid item md={4} pr="11px">
                  <BlendTextField
                    width="100%"
                    label="Email"
                    name="email"
                    placeholder="Email"
                    value={coacheeState.contactPrimary?.email ?? ''}
                    onChange={(e) => handleTextFieldChange(e, true)}
                  />
                </Grid>
                <Grid item md={4} pl="11px" pr="11px">
                  <BlendTextField
                    width="100%"
                    label="Phone Number"
                    name="mobile"
                    placeholder="Phone Number"
                    value={coacheeState.contactPrimary?.mobile ?? ''}
                    onChange={(e) => handleTextFieldChange(e, true)}
                  />
                </Grid>
                <Grid item md={4} pl="11px">
                  <BlendTextField
                    width="100%"
                    label="Alternative Email"
                    name="alternativeEmail"
                    placeholder="Alternative Email"
                    value={coacheeState.contactPrimary?.alternativeEmail ?? ''}
                    onChange={(e) => handleTextFieldChange(e, true)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  ) : (
    <BlendFullpageSpinner />
  )
}
