import React, { useEffect, useState } from 'react'
import { Box, OutlinedTextFieldProps, styled, TextField, Typography } from '@mui/material'
import theme from '../../theme'
import { useDebounce } from '../../hooks/useDebounce'

export const BlendStyledTextField = styled(TextField)({
  fontSize: '15pt',
  color: theme.input.text,
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& input': {
      padding: '10px 20px',
    },
    '& fieldset': {
      borderRadius: '5px',
      border: `0.5px solid ${theme.blend.darkGrey}`,
    },
    '&:hover fieldset': {
      border: `2px solid ${theme.blend.blue}`,
      boxShadow: `${theme.blend.boxShadow} ${theme.blend.lightBlue}`,
    },
    '&.Mui-focused fieldset': {
      border: `2px solid ${theme.blend.blue}`,
      borderColor: theme.blend.blue,
    },
  },
})

interface BlendTextFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  width?: string
  readOnly?: boolean
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  onBlur?: (e: any) => void
}

export const BlendTextField: React.FC<BlendTextFieldProps> = (props) => {
  return (
    <Box sx={{ ...props.sx }}>
      {props.label && (
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '5px',
          }}
        >
          <Typography component={'label'} variant={'body1'}>
            {props.label}
          </Typography>
          {props.required && (
            <Typography variant={'body1'} fontWeight={300} fontSize={'12pt'} color={theme.blend.error}>
              Required
            </Typography>
          )}
        </Box>
      )}
      <BlendStyledTextField
        {...props}
        sx={{ height: '100%', width: '100%' }}
        variant={'outlined'}
        InputProps={{ ...props.InputProps, readOnly: props.readOnly ?? false }}
        placeholder={props.placeholder ?? 'Input text here'}
        label={undefined}
        defaultValue={props.value ?? undefined}
        onChange={(event) => {
          props.onChange && props.onChange(event)
        }}
        onBlur={props.onBlur}
      />
    </Box>
  )
}
