import { Avatar, Card, CardActionArea, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from '../../theme'
import { ShortCoachResBody } from '../../types/coach_types'
import { useAvatarContext } from '../../providers/Avatars'

export interface CoachCardProps extends ShortCoachResBody {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const CoachCard: React.FC<CoachCardProps> = (props: CoachCardProps) => {
  const avatar = useAvatarContext(props.avatarFilename)

  return (
    <Card
      sx={{
        height: '180px',
        backgroundColor: props.isActive ? '#1763AF26' : '#EFEFEF',
        color: props.isActive ? 'inherit' : '#C4C4C4',
        borderRadius: '10px',
        display: 'flex',
        boxShadow: 'none',
      }}
    >
      <CardActionArea
        sx={{
          height: '100%',
          width: '100%',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onClick={props.onClick}
      >
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          <Grid item md={10}>
            <CardHeader
              avatar={<Avatar src={avatar} sx={{ height: '60px', width: '60px' }} />}
              title={
                <Typography variant="h6" lineHeight={1.3} pr="5px">
                  {props.firstName} {props.lastName}
                </Typography>
              }
              sx={{ padding: '0' }}
            />
          </Grid>
          <Grid item md={2} justifyContent="center">
            {props.isActive ? <Typography>Active</Typography> : <Typography>Inactive</Typography>}
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          sx={{
            marginBottom: !props.isActive ? '-20px' : '0px',
            flexGrow: '1',
          }}
        >
          <CardContent sx={{ padding: '15px 0px 0px', maxWidth: 'calc(90%)' }}>
            <Typography
              variant="body1"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                display: '-webkit-box',
              }}
            >
              {props.headline}
            </Typography>
          </CardContent>
        </Grid>
        {!props.isActive && (
          <FontAwesomeIcon
            size="sm"
            color={theme.palette.error.main}
            icon={faExclamationTriangle}
            style={{ marginLeft: 'auto', height: '20px', width: '20px' }}
          />
        )}
      </CardActionArea>
    </Card>
  )
}

export { CoachCard }
