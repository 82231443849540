import React from 'react'
import { Box, Link, styled, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import theme from '../../theme'

export const MetricSquareInner = styled(Box)({
  height: '239px',
  padding: '30px 60px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
})

export interface MetricSquareProps {
  number: number
  tagline: string
  linkTo?: string
  bgcolor?: string
  notDashboard?: boolean
}

export const MetricSquare: React.FC<MetricSquareProps> = ({ number, tagline, linkTo, bgcolor, notDashboard }) => {
  return (
    <MetricSquareInner bgcolor={bgcolor ?? theme.blend.blue} borderRadius={notDashboard ? '30px' : '10px'}>
      <Typography textAlign="center" color="white" variant="h1">
        {number}
      </Typography>
      <Typography textAlign="center" color="white" variant="body1">
        {tagline}
      </Typography>
      {linkTo && (
        <Link>
          <Typography textAlign="center" variant="body1">
            <FontAwesomeIcon fixedWidth color="white" icon={faEye} /> View
          </Typography>
        </Link>
      )}
    </MetricSquareInner>
  )
}
