import { GetSessionRes } from '../types/session_type'
import { isAfterYear, isGreaterThan, isRequired } from './validations'

export const sessionValidator = (session: GetSessionRes) => {
  const errors: string[] = []
  errors.push(isRequired('Contract', session.contractId))
  errors.push(isRequired('Coach', session.coachId))
  errors.push(isRequired('Coachee', session.coacheeId))
  errors.push(isRequired('Client', session.contractId))
  errors.push(isRequired('Start date or time', session.start))
  errors.push(isRequired('End time', session.end))
  errors.push(isAfterYear('Start date', session.start, 2010))
  errors.push(isGreaterThan('Start and end time', session.start, session.end))

  return errors.filter((error) => error.length > 1)
}
