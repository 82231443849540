import React, { useMemo } from 'react'
import { Typography, Box, IconButton } from '@mui/material'
import { Table } from '../../../../components/Table/table'
import { useNavigate } from 'react-router-dom'
import { BlendButtonCalendarIcon, BlendEditIcon, BlendWarningIcon } from '../../../../components/Common/Icons'
import { BlendPrimaryButton } from '../../../../components/Common/Buttons'
import { BlendCheckbox } from '../../../../components/Common/Checkbox'
import { BlendFullpageSpinner } from '../../../../components/Common/Spinner'
import { ShortCoachResBody } from '../../../../types/coach_types'
import { CoacheeContact } from '../../../../types/contact_types'
import { SessionStatus } from '../../../../types/session_type'
import { Cell } from 'react-table'
import { useDateTimeString } from '../../../../hooks/useDateTime'
import { useDialogContext } from '../../../../providers/Dialog'

interface SessionsTabProps {
  coacheeId: string
  contacts: CoacheeContact[]
  coaches: ShortCoachResBody[]
  contractExpired?: boolean
  sessions: {
    sessionId: string
    start: Date
    end: Date
    sessionPaid: boolean
    coachId: string
    coachFirstName: string
    coachLastName: string
    lastUpdated: Date
    status: SessionStatus
  }[]
}

interface SessionsTableData {
  id: string
  name: string
  start: Date
  end: Date
  sessionPaid: boolean
  status: string
  lastUpdated: Date
}

export const SessionsTab: React.FC<SessionsTabProps> = ({
  coacheeId,
  contacts,
  coaches,
  contractExpired,
  sessions,
}) => {
  const navigate = useNavigate()
  const dialog = useDialogContext()

  const delegateContact = useMemo(() => {
    return contacts.filter((contact) => contact.isDelegate)[0] ?? undefined
  }, [contacts])

  const createSessionHandler = async () => {
    if (contractExpired)
      await dialog.openConfirmDialog(
        `You have reached a quota or expiry date, are you sure you want to continue?`,
        async () => await navigate(`/session/create?coacheeId=${coacheeId}`),
        true,
      )
    else navigate(`/session/create?coacheeId=${coacheeId}`)
  }

  const tableData = useMemo<SessionsTableData[] | undefined>(
    () =>
      sessions
        ? sessions.map<SessionsTableData>((session) => {
            return {
              id: session.sessionId,
              name: `${session.coachFirstName} ${session.coachLastName}`,
              start: session.start,
              end: session.end,
              sessionPaid: session.sessionPaid,
              lastUpdated: session.lastUpdated,
              status: SessionStatus[session.status as keyof typeof SessionStatus]?.valueOf(),
            }
          })
        : undefined,
    [sessions],
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Sessions',
        columns: [
          {
            Header: 'Coach',
            id: 'name',
            accessor: 'name',
          },
          {
            Header: 'Start',
            id: 'start',
            accessor: 'start',
            Cell: ({ row }: Cell<SessionsTableData>) => useDateTimeString(row.values.start),
          },
          {
            Header: 'End',
            id: 'end',
            accessor: 'end',
            Cell: ({ row }: Cell<SessionsTableData>) => useDateTimeString(row.values.end),
          },
          {
            Header: 'Paid',
            id: 'sessionPaid',
            accessor: 'sessionPaid',
            Cell: ({ row }: Cell<SessionsTableData>) => <BlendCheckbox checked={row.values.sessionPaid} />,
          },
          {
            Header: 'Status',
            id: 'status',
            accessor: 'status',
          },
          {
            Header: 'Last Updated',
            id: 'lastUpdated',
            accessor: 'lastUpdated',
            Cell: ({ row }: Cell<SessionsTableData>) => useDateTimeString(row.original.lastUpdated),
          },
          {
            Header: '',
            id: 'actions',
            accessor: 'id',
            Cell: ({ row }: Cell<SessionsTableData>) => {
              return (
                <Box sx={{ float: 'right' }}>
                  {
                    <IconButton sx={{ mr: '12px' }} onClick={() => navigate(`/session/editPast?id=${row.original.id}`)}>
                      <BlendEditIcon />
                    </IconButton>
                  }
                </Box>
              )
            },
          },
        ],
      },
    ],
    [],
  )

  return (
    <Box sx={{ width: '100%' }}>
      {!tableData || (tableData && !tableData.length) ? (
        !!coaches && coaches.length > 0 && !!coaches[0] ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography>Coachee has been matched with a Coach, ready to create the first session.</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <BlendPrimaryButton onClick={createSessionHandler}>
                {contractExpired && <BlendWarningIcon color="white" style={{ marginRight: '10px' }} />} Create first
                session
              </BlendPrimaryButton>
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography>{`There are no Coaches matched to ${delegateContact.firstName} ${delegateContact.lastName}.`}</Typography>
          </Box>
        )
      ) : tableData && tableData.length ? (
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
            <Typography variant="h2">Sessions</Typography>
            <BlendPrimaryButton onClick={createSessionHandler} startIcon={<BlendButtonCalendarIcon />}>
              {contractExpired && <BlendWarningIcon color="white" style={{ marginRight: '10px' }} />} New Session
            </BlendPrimaryButton>
          </Box>
          <Table columns={columns} data={tableData} searchLabel="Search" />
        </Box>
      ) : (
        <BlendFullpageSpinner />
      )}
    </Box>
  )
}
