import { styled, Slider } from '@mui/material'
import React, { useState } from 'react'
import { QuestionProps } from '../../pages/questionnaire'
import { QuestionComponent } from '.'

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
]

const CustomSlider = styled(Slider)({
  color: '#1763AF',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
})

export const SliderForm: React.FC<QuestionProps> = (props: QuestionProps) => {
  const [sliderState, setSliderState] = useState<number | number[] | undefined>(
    props.currentResponse ? +props.currentResponse[0].text : undefined,
  )

  const handleDragEnd = () => {
    props.callBack(props, [{ ...props.responses[0], text: sliderState ? sliderState.toString() : '' }])
  }

  return (
    <QuestionComponent {...props}>
      <CustomSlider
        key={`slider-${props.id}`}
        min={1}
        max={10}
        step={1}
        value={sliderState}
        valueLabelDisplay="off"
        marks={marks}
        onChange={(event: Event, value: number | number[]) => setSliderState(value)}
        onChangeCommitted={handleDragEnd}
        sx={
          sliderState == undefined
            ? {
                '& .MuiSlider-thumb': {
                  display: 'none',
                },
              }
            : {}
        }
      />
    </QuestionComponent>
  )
}
