export const useDateTimeString = (date?: Date | null) => {
  if (!date) return 'N/A'
  const strDate = new Date(date)
  return strDate.toLocaleString()
}

export const useDateString = (date?: Date | null) => {
  if (!date) return 'N/A'
  const strDate = new Date(date)
  return strDate.toLocaleDateString()
}

export const useIsWithinDate = (start: Date | null, end: Date | null) => {
  const date1 = start ? new Date(start) : null
  const date2 = end ? new Date(end) : null
  const today = new Date()
  return date1 ? (date2 ? date1 < today && date2 > today : date1 < today) : false
}

export enum ContractStatus {
  upcoming = 'Upcoming',
  active = 'Active',
  expired = 'Expired',
  something_wrong = 'Something wrong',
}

export const getContractStatus = (startDate?: Date, expiryDate?: Date): ContractStatus => {
  if (startDate && expiryDate) {
    if (new Date(startDate) > new Date()) return ContractStatus.upcoming
    else if (new Date(startDate) <= new Date() && new Date(expiryDate) >= new Date()) return ContractStatus.active
    else if (new Date(expiryDate) < new Date()) return ContractStatus.expired
    else return ContractStatus.something_wrong
  } else return ContractStatus.something_wrong
}

export const getFirstAndLastDaysOfPreviousMonth = () => {
  const date = new Date()
  return [new Date(date.getFullYear(), date.getMonth() - 1, 1), new Date(date.getFullYear(), date.getMonth(), 0)]
}

export const getBigTimeSpan = () => {
  const date1 = new Date(2022, 6, 1)
  const date2 = new Date()
  return [date1, date2]
}
