import React from 'react'
import { Box, MenuItem, Select, SelectChangeEvent, SelectProps, styled, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import theme from '../../theme'

export const BlendStyledSelectField = styled(Select)({
  '& fieldset': {
    border: 'none',
    '&:hover': {
      border: 'none',
    },
  },
  '& .MuiInputBase-input': {
    fontSize: '15pt',
    color: theme.input.text,
    width: '100%',
    padding: '10px 20px',
    borderRadius: '5px',
    border: `0.5px solid ${theme.blend.darkGrey}`,
    '&:hover': {
      border: `2px solid ${theme.blend.blue}`,
      padding: '9px 19px',
      boxShadow: `${theme.blend.boxShadow} ${theme.blend.lightBlue}`,
    },
    '&:focused': {
      border: `2px solid ${theme.blend.blue}`,
      borderRadius: '5px',
      padding: '9px 19px',
      boxShadow: `${theme.blend.boxShadow} ${theme.blend.lightBlue}`,
    },
  },
})

export const BlendMenuItem = styled(MenuItem)({
  fontSize: '15pt',
  color: theme.input.text,
})

interface BlendSelectProps extends SelectProps {
  width?: string
  label?: string | JSX.Element
  required?: boolean
  name: string
  value?: unknown
  defaultValue?: unknown
  renderValue?: (value: unknown) => React.ReactNode
  onChange?: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void
}

export const BlendSelect: React.FC<BlendSelectProps> = (props) => {
  return (
    <Box sx={{ ...props.sx }}>
      {(props.label || props.required) && (
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '5px',
          }}
        >
          <Typography component={'label'} variant={'body1'}>
            {!!props.label && props.label}
          </Typography>
          {props.required && (
            <Typography variant={'body1'} fontWeight={300} fontSize={'12pt'} color={theme.blend.error}>
              Required
            </Typography>
          )}
        </Box>
      )}
      <BlendStyledSelectField
        sx={{ height: '100%', width: '100%' }}
        required={props.required ?? false}
        name={props.name}
        displayEmpty
        value={props.value}
        defaultValue={props.defaultValue}
        renderValue={props.renderValue}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          style: {
            maxHeight: 300,
          },
        }}
        onChange={props.onChange}
      >
        {props.children}
      </BlendStyledSelectField>
    </Box>
  )
}
