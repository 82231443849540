import { ClientContact } from '../types/contact_types'
import { Contract } from '../types/contract_type'

export interface ContractEditAction {
  type: 'STRING' | 'SELECT' | 'DATE' | 'BOOLEAN' | 'CONTACT' | 'CREATE_CONTACT' | 'DELETE_CONTACT' | 'PARENT'
  field: string[]
  payload: string | Date | boolean | number | ClientContact | undefined
}

export const contractReducer = (state: Contract, action: ContractEditAction): Contract => {
  switch (action.type) {
    case 'STRING':
    case 'SELECT':
    case 'DATE':
      return {
        ...state,
        [action.field[0]]: action.payload,
      }
    case 'BOOLEAN':
      return {
        ...state,
        [action.field[0]]: action.payload as boolean,
      }
    case 'CONTACT':
      const client = { ...state }
      const index = state.contacts?.findIndex((con) => con.id === action.field[0]) ?? -1
      if (client.contacts && index > -1 && client.contacts[index])
        client.contacts[index] = action.payload as ClientContact
      return { ...client }
    case 'CREATE_CONTACT':
      return {
        ...state,
        contacts: state.contacts
          ? [...state.contacts, action.payload as ClientContact]
          : [action.payload as ClientContact],
      }
    case 'DELETE_CONTACT':
      const clientWithDelete = { ...state }
      if (state.contacts?.length == 1) return { ...state, contacts: [] }
      else if (action.payload != undefined && action.payload > -1)
        clientWithDelete.contacts?.splice(action.payload as number, 1)
      return { ...clientWithDelete }
    default:
      return { ...state }
  }
}
