import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faEye, faPenToSquare, faTrashCan, faUser } from '@fortawesome/free-regular-svg-icons'
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import theme from '../../theme'
import { faExclamationTriangle, faCheckCircle, faDownload } from '@fortawesome/free-solid-svg-icons'
import { Box, Tooltip } from '@mui/material'

export const BlendEditIcon = () => (
  <FontAwesomeIcon icon={faPenToSquare as IconProp} color={theme.blend.teal} size="xs" />
)

export const BlendCoacheeIcon = () => <FontAwesomeIcon icon={faUser as IconProp} color={theme.blend.teal} size="xs" />
export const BlendViewIcon = () => <FontAwesomeIcon icon={faEye as IconProp} color={theme.blend.teal} size="xs" />
export const BlendBinIcon = () => <FontAwesomeIcon icon={faTrashCan as IconProp} color={theme.blend.error} size="xs" />
export const BlendDownloadIcon = () => (
  <FontAwesomeIcon icon={faDownload as IconProp} color={theme.blend.teal} size="xs" />
)

export const BlendTickIcon = (props: {
  color: 'green' | 'amber' | 'red' | 'white'
  size?: SizeProp
  style?: React.CSSProperties
}) => {
  const iconColor = useMemo(() => {
    switch (props.color) {
      case 'green':
        return theme.blend.green
      case 'amber':
        return '#FE8A58'
      case 'red':
        return theme.blend.error
      case 'white':
        return theme.blend.white
      default:
        return theme.blend.teal
    }
  }, [props.color])

  return (
    <FontAwesomeIcon icon={faCheckCircle as IconProp} color={iconColor} size={props.size ?? 'xs'} style={props.style} />
  )
}

export const BlendWarningIcon = (props: {
  color: 'warning' | 'white'
  size?: SizeProp
  style?: React.CSSProperties
}) => {
  const iconColor = useMemo(() => {
    switch (props.color) {
      case 'warning':
        return theme.blend.warning
      case 'white':
        return theme.blend.white
      default:
        return theme.blend.teal
    }
  }, [props.color])

  return (
    <FontAwesomeIcon
      icon={faExclamationTriangle as IconProp}
      color={iconColor}
      size={props.size ?? 'xs'}
      style={props.style}
    />
  )
}

export const BlendCalendarIcon = (props: { size?: SizeProp; style?: React.CSSProperties }) => (
  <FontAwesomeIcon
    icon={faCalendar as IconProp}
    color={theme.blend.darkGrey}
    size={props.size ?? 'xs'}
    style={props.style}
  />
)

export const BlendCalendarTooltip = () => (
  <Tooltip arrow placement="left" title="This report is affected by the date selection">
    <Box sx={{ marginLeft: '10px' }}>
      <BlendCalendarIcon size="1x" style={{ color: theme.blend.midGrey }} />
    </Box>
  </Tooltip>
)

export const BlendButtonCalendarIcon = () => <FontAwesomeIcon icon={faCalendar as IconProp} size="xs" />
