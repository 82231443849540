import { QuestionGroup } from '../types/questionnaire_types'

export interface QuestionnaireErrorAction {
  type: 'INIT' | 'SET' | 'UNSET'
  groups?: QuestionGroup[]
  groupIndex?: number
  questionIndex?: number
}

export const questionnaireErrorInitState = (groups: QuestionGroup[]) => groups

export const questionnaireErrorReducer = (
  state: QuestionGroup[],
  { type, groups, groupIndex, questionIndex }: QuestionnaireErrorAction,
) => {
  switch (type) {
    case 'INIT': {
      return groups ? [...groups] : []
    }
    case 'SET': {
      if (groupIndex !== undefined && questionIndex !== undefined)
        state[groupIndex].questions[questionIndex].error = true
      return [...state]
    }
    case 'UNSET': {
      if (groupIndex !== undefined && questionIndex !== undefined)
        state[groupIndex].questions[questionIndex].error = false
      return [...state]
    }
  }
}
