import { Coachee, CoacheeEditorProps } from '../types/coachee_type'
import { CoacheeContact } from '../types/contact_types'

export interface CoacheeContactEditAction {
  type: 'SET' | 'STRING' | 'CONTRACT'
  field: string
  index?: number
  isContactPrimary?: boolean
  payload?: CoacheeEditorProps | Coachee | string
}

export const coacheeReducer = (state: CoacheeEditorProps, action: CoacheeContactEditAction): CoacheeEditorProps => {
  switch (action.type) {
    case 'SET':
      const coacheeProps = (action.payload as CoacheeEditorProps) ?? state
      return {
        ...coacheeProps,
      }
    case 'STRING':
      return !action.isContactPrimary
        ? {
            ...state,
            contactDelegate: { ...state.contactDelegate, [action.field]: action.payload },
          }
        : {
            ...state,
            contactPrimary: { ...state.contactPrimary, [action.field]: action.payload },
          }
    case 'CONTRACT':
      const contractId = action.payload as string
      return {
        ...state,
        coachee: { ...state.coachee, contractId: contractId },
      }
    // case 'ADD_CONTRACT':
    //   return {
    //     ...state,
    //     coachee: {...state.coachee, action.payload as Coachee},
    //   }
    default:
      return { ...state }
  }
}
