import React, { useState } from 'react'
import { Dialog, DialogContent, DialogContentText, DialogActions, Button, styled, DialogProps } from '@mui/material'
import theme from '../../theme'
import { BlendTickIcon, BlendWarningIcon } from './Icons'

const BlendDialogPrimaryButton = styled(Button)({
  color: theme.blend.blue,
  textTransform: 'none',
  backgroundColor: 'none',
  border: 'none',
  fontSize: '12pt',
  fontWeight: 600,
  height: '50px',
  padding: '13px 20px',
  marginLeft: '20px',
  borderRadius: '10px',
  '&:hover': {
    color: theme.blend.white,
    backgroundColor: theme.blend.blue,
  },
})

const BlendDialogSecondaryButton = styled(BlendDialogPrimaryButton)({
  color: theme.blend.darkGrey,
  '&:hover': {
    color: theme.blend.white,
    backgroundColor: theme.blend.midGrey,
  },
})

const BlendDialogSuccessButton = styled(BlendDialogPrimaryButton)({
  color: theme.blend.white,
  '&:hover': {
    color: theme.blend.green,
    backgroundColor: theme.blend.white,
  },
})

const BlendDialogWarningPrimaryButton = styled(BlendDialogPrimaryButton)({
  color: theme.blend.warning,
  '&:hover': {
    color: theme.blend.white,
    backgroundColor: theme.blend.warning,
  },
})

const BlendDialogWarningSuccessButton = styled(BlendDialogPrimaryButton)({
  color: theme.blend.white,
  '&:hover': {
    color: theme.blend.warning,
    backgroundColor: theme.blend.white,
  },
})

interface BaseDialogProps extends DialogProps {
  status: BlendDialogStatus
}

const BlendBaseDialog = styled((props: BaseDialogProps) => <Dialog {...props}>{props.children}</Dialog>)((props) => {
  return {
    '& .MuiPaper-root': {
      minWidth: '500px',
      backgroundColor:
        props.status === BlendDialogStatus.Success
          ? theme.blend.green
          : props.status === BlendDialogStatus.WarningSuccess
          ? theme.blend.warning
          : theme.blend.white,
      boxShadow: `0px 0px 6px 0px ${
        props.status === BlendDialogStatus.Success
          ? theme.blend.lightGreen
          : props.status === BlendDialogStatus.WarningSuccess ||
            props.status === BlendDialogStatus.WarningInformation ||
            props.status === BlendDialogStatus.WarningConfirmation
          ? theme.blend.warning
          : theme.blend.midGrey
      }`,
      '& .MuiDialogContent-root p': {
        color:
          props.status === BlendDialogStatus.Success
            ? theme.text.white
            : props.status === BlendDialogStatus.WarningSuccess
            ? theme.text.white
            : theme.text.black,
      },
    },
  }
})

enum BlendDialogStatus {
  Information,
  Confirmation,
  Success,
  WarningInformation,
  WarningConfirmation,
  WarningSuccess,
}

interface BlendDialogProps {
  isOpen?: boolean
  status: BlendDialogStatus
  resolveDialog: () => void
  rejectDialog: () => void
  callback?: () => void
}

export const BlendDialog: React.FC<BlendDialogProps> = ({
  isOpen: open,
  status,
  children,
  resolveDialog,
  rejectDialog,
  callback,
}) => {
  const resolveHandler = () => {
    if (callback) callback()
    resolveDialog()
  }

  const rejectHandler = () => {
    rejectDialog()
  }

  return (
    <BlendBaseDialog status={status} open={open ?? false} onClose={rejectHandler} onAbort={rejectHandler}>
      <DialogContent sx={{ padding: '40px', paddingBottom: '0px' }}>
        <DialogContentText variant={'body1'} fontWeight={600}>
          {status === BlendDialogStatus.Success && (
            <BlendTickIcon color="white" size="1x" style={{ marginRight: '10px' }} />
          )}
          {status === BlendDialogStatus.WarningSuccess && (
            <BlendWarningIcon color="white" size="1x" style={{ marginRight: '10px' }} />
          )}
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions disableSpacing sx={{ padding: '20px', paddingTop: '20px' }}>
        {
          {
            0: <BlendDialogSecondaryButton onClick={resolveHandler}>OK</BlendDialogSecondaryButton>,
            1: (
              <>
                <BlendDialogSecondaryButton onClick={rejectHandler}>Cancel</BlendDialogSecondaryButton>
                <BlendDialogPrimaryButton onClick={resolveHandler}>Yes</BlendDialogPrimaryButton>
              </>
            ),
            2: <BlendDialogSuccessButton onClick={resolveHandler}>OK</BlendDialogSuccessButton>,
            3: <BlendDialogWarningPrimaryButton onClick={resolveHandler}>OK</BlendDialogWarningPrimaryButton>,
            4: (
              <>
                <BlendDialogSecondaryButton onClick={rejectHandler}>Cancel</BlendDialogSecondaryButton>
                <BlendDialogWarningPrimaryButton onClick={resolveHandler}>Yes</BlendDialogWarningPrimaryButton>
              </>
            ),
            5: <BlendDialogWarningSuccessButton onClick={resolveHandler}>OK</BlendDialogWarningSuccessButton>,
          }[status]
        }
      </DialogActions>
    </BlendBaseDialog>
  )
}
