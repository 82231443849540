import React from 'react'
import { useMsal } from '@azure/msal-react'
import { styled } from '@mui/material'
import { BlendDestructiveButton } from '../Common/Buttons'

function handleLogout(instance: any) {
  instance.logoutRedirect().catch((e: any) => {
    console.error(e)
  })
}

const CustomButton = styled(BlendDestructiveButton)({
  marginTop: '20px',
})

export const LogOutButton = () => {
  const { instance } = useMsal()

  return (
    <CustomButton variant="outlined" size="large" onClick={() => handleLogout(instance)}>
      Confirm logout
    </CustomButton>
  )
}
