import React from 'react'
import { Box, styled, TextField, TextFieldProps, Typography } from '@mui/material'
import { BlendStyledTextField } from './TextField'
import { DatePicker } from '@mui/x-date-pickers'
import { BlendCalendarIcon } from './Icons'
import theme from '../../theme'

export const BlendSmallDateTextField = styled(TextField)({
  width: '180px',
  '& .MuiOutlinedInput-root': {
    '& input': {
      fontSize: '12pt',
      color: theme.input.text,
      padding: '5px 10px',
      paddingRight: '0px',
    },
    '& fieldset': {
      borderRadius: '8px',
      border: `2px solid ${theme.blend.blue}`,
    },
    '&:hover fieldset': {
      border: `2px solid ${theme.blend.blue}`,
      boxShadow: `${theme.blend.boxShadow} ${theme.blend.lightBlue}`,
    },
    '&.Mui-focused fieldset': {
      border: `2px solid ${theme.blend.blue}`,
      borderColor: theme.blend.blue,
    },
  },
})

interface BlendDatePickerProps {
  width?: string
  required?: boolean
  label?: string
  name: string
  placeholder?: string
  value: any
  small?: boolean
  onChange: (date: Date | null, name: string) => void
}

export const BlendDatePicker: React.FC<BlendDatePickerProps> = (props) => {
  return (
    <>
      {(props.label || props.required) && (
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '5px',
          }}
        >
          <Typography component={'label'} variant={'body1'}>
            {!!props.label && props.label}
          </Typography>
          {props.required && (
            <Typography variant={'body1'} fontWeight={300} fontSize={'12pt'} color={theme.blend.error}>
              Required
            </Typography>
          )}
        </Box>
      )}
      <DatePicker
        components={{
          OpenPickerIcon: () => <BlendCalendarIcon />,
        }}
        renderInput={(params: TextFieldProps) =>
          !props.small ? (
            <BlendStyledTextField
              {...params}
              sx={{ width: props.width }}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
                placeholder: props.placeholder ?? 'dd/mm/yyyy',
              }}
            />
          ) : (
            <BlendSmallDateTextField {...params} />
          )
        }
        value={props.value}
        onChange={(date: Date | null) => props.onChange(date, props.name)}
      />
    </>
  )
}
