import { AttributeBody, Coach } from '../types/coach_types'

export interface CoachEditAction {
  type: string
  field: string
  payload: string | Date | boolean | number | AttributeBody
}

export const coachReducer = (state: Coach, action: CoachEditAction): Coach => {
  switch (action.type) {
    case 'STRING':
    case 'SELECT':
    case 'DATE':
      return {
        ...state,
        [action.field]: action.payload,
      }
    case 'ATTRIBUTE_TAG':
      const payloadAttr = action.payload as AttributeBody | undefined
      const stateAttributes = state.attributes && state.attributes.length > 0 ? [...state.attributes] : undefined
      if (stateAttributes && payloadAttr && stateAttributes.some((attr) => attr.id === payloadAttr.id)) {
        stateAttributes.splice(
          stateAttributes.findIndex((attr) => attr.id === payloadAttr.id),
          1,
        )
        return { ...state, attributes: [...stateAttributes] }
      } else if (payloadAttr && stateAttributes) {
        return { ...state, attributes: [...stateAttributes, payloadAttr] }
      } else if (payloadAttr) {
        return { ...state, attributes: [payloadAttr] }
      }
      return { ...state }
    default:
      return { ...state }
  }
}
