import React from 'react'
import { Button, ButtonProps, styled } from '@mui/material'
import theme from '../../theme'

export const BlendPrimaryButton = styled(Button)({
  color: theme.text.white,
  textTransform: 'none',
  backgroundColor: theme.blend.blue,
  border: `2px solid ${theme.blend.blue}`,
  fontSize: '12pt',
  fontWeight: 600,
  height: '50px',
  padding: '13px 20px',
  boxShadow: `${theme.blend.boxShadow} ${theme.blend.white}`,
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: theme.blend.teal,
    border: `2px solid ${theme.blend.teal}`,
    boxShadow: `${theme.blend.boxShadow} ${theme.blend.lightTeal}`,
  },
  '&:disabled': {
    backgroundColor: theme.blend.lightGrey,
    border: `2px solid ${theme.blend.lightGrey}`,
    color: theme.text.white,
  },
})

export const BlendSecondaryButton = styled((props: ButtonProps) => <BlendPrimaryButton {...props} />)({
  color: theme.blend.blue,
  backgroundColor: theme.blend.white,
  '&:hover': {
    color: theme.blend.teal,
    backgroundColor: theme.blend.white,
    borderColor: theme.blend.teal,
    boxShadow: `${theme.blend.boxShadow} ${theme.blend.lightTeal}`,
  },
})

export const BlendInvertedButton = styled((props: ButtonProps) => <BlendPrimaryButton {...props} />)({
  backgroundColor: theme.blend.teal,
  border: `2px solid ${theme.blend.teal}`,
  '&:hover': {
    backgroundColor: theme.blend.blue,
    border: `2px solid ${theme.blend.blue}`,
    boxShadow: `${theme.blend.boxShadow} ${theme.blend.lightBlue}`,
  },
})

export const BlendDestructiveButton = styled((props: ButtonProps) => <BlendPrimaryButton {...props} />)({
  backgroundColor: theme.blend.error,
  borderColor: theme.blend.error,
  '&:hover': {
    backgroundColor: theme.blend.midGrey,
    borderColor: theme.blend.midGrey,
  },
})

export const BlendSmallPrimaryButton = styled((props: ButtonProps) => <BlendPrimaryButton {...props} />)({
  height: '40px',
})

export const BlendSmallSecondaryButton = styled((props: ButtonProps) => <BlendSecondaryButton {...props} />)({
  height: '40px',
})
