import { Grid, Typography, Box } from '@mui/material'
import React from 'react'
import { QuestionProps } from '../../pages/questionnaire'
import theme from '../../theme'
import { FreeForm } from './freeForm'
import { MultipleChoice } from './multipleChoice'
import { SingleChoice } from './singleChoice'
import { SliderForm } from './sliderForm'
import { SelectChoice } from './selectChoice'

export const QuestionComponent: React.FC<QuestionProps> = (props: QuestionProps) => {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ width: '800px', marginY: '20px' }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body1" sx={{ marginBottom: '20px' }}>
            {props.questionText}
          </Typography>
          {props.isMandatory && (
            <Typography
              variant={'body1'}
              fontWeight={300}
              fontSize={'12pt'}
              color={theme.blend.error}
              sx={{ marginLeft: '20px' }}
            >
              Required
            </Typography>
          )}
        </Box>
        {props.children}
      </Grid>
    </Grid>
  )
}

export { FreeForm, MultipleChoice, SingleChoice, SliderForm, SelectChoice }
