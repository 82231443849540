import { CoacheeEditorProps } from '../types/coachee_type'
import { isEmail, isPhoneNumber, isRequired } from './validations'

export const coacheeValidator = (coachee: CoacheeEditorProps) => {
  const errors: string[] = []
  errors.push(isRequired('First name', coachee.contactDelegate.firstName))
  errors.push(isRequired('Last name', coachee.contactDelegate.lastName))
  errors.push(isRequired('Email', coachee.contactDelegate.email))
  errors.push(isEmail('Email', coachee.contactDelegate.email))
  errors.push(isPhoneNumber('Phone number', coachee.contactDelegate.mobile))

  if (coachee.contactPrimary?.firstName || coachee.contactPrimary?.lastName || coachee.contactPrimary?.email) {
    errors.push(isRequired('Secondary contact first name', coachee.contactPrimary?.firstName))
    errors.push(isRequired('Secondary contact last name', coachee.contactPrimary?.lastName))
    errors.push(isRequired('Secondary contact email', coachee.contactPrimary?.email))
    errors.push(isEmail('Secondary contact email', coachee.contactPrimary?.email))
    errors.push(isPhoneNumber('Secondary contact phone number', coachee.contactPrimary?.mobile))
  }

  return errors.filter((error) => error.length > 1)
}
