import React, { useMemo, useState } from 'react'
import { Typography, Box, IconButton } from '@mui/material'
import { styled } from '@mui/system'
import { useUserContext } from '../../providers/User'
import { Table } from '../../components/Table/table'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { deleteCoacheeQuestionnaire, getBlobRequest, getCoacheeQuestionnaires } from '../../queries'
import { useDateTimeString } from '../../hooks/useDateTime'
import { GetAllCoacheeQuestionnaireRes } from '../../types/coacheeQuestionnaire_type'
import { BlendBinIcon, BlendCoacheeIcon, BlendViewIcon, BlendDownloadIcon } from '../../components/Common/Icons'
import { BlendFullpageSpinner } from '../../components/Common/Spinner'
import { useDialogContext } from '../../providers/Dialog'
import { Cell } from 'react-table'
import { useNavigate } from 'react-router-dom'

const Title = styled(Typography)({
  margin: '20px',
})

interface TableData {
  id: string
  coacheeId: string
  coacheeName: string
  clientName: string
  status: string
  lastUpdated: Date
}

export const Questionnaires: React.FC = () => {
  const context = useUserContext()
  const dialog = useDialogContext()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const userContext = useUserContext()

  const [downloading, setDownloading] = useState('')

  const handleDownload = (row: any) => {
    const query = `/report/responses/${row.actions}/docx`
    setDownloading(row.actions)
    const filename = row.coacheeName.replace(/[^a-z0-9]/gi, '_')
    getBlobRequest(userContext, query, filename, 'docx').then(() => setDownloading(''))
  }

  const { isFetching, error, data } = useQuery<GetAllCoacheeQuestionnaireRes>({
    queryFn: () => getCoacheeQuestionnaires(context),
    queryKey: 'getAllCoacheeQuestionnaireData',
    enabled: !!context.token,
  })

  const mutation = useMutation({
    mutationFn: (id: string) => deleteCoacheeQuestionnaire(context, id),
    onError: (error) => console.error(error),
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllCoacheeQuestionnaireData')
    },
  })

  const handleDelete = (name: string, id: string) => {
    dialog.openDeleteDialog(`${name}'s questionnaire`, () => mutation.mutate(id))
  }

  const tableData = useMemo(
    () =>
      data?.coacheeQuestionnaires
        .filter((cq) => cq.questionnaire.type === 'Matching')
        .map<TableData>((cq) => {
          return {
            id: cq.id,
            coacheeId: cq.coachee?.coacheeId,
            coacheeName: cq.contacts ? `${cq.contacts[0].firstName} ${cq.contacts[0].lastName}` : '',
            clientName: cq.client.name,
            status: cq.statusName,
            lastUpdated: cq.lastUpdated,
          }
        }),
    [data],
  )

  console.log(tableData)

  const columns = useMemo(
    () => [
      {
        Header: 'Questionnaires',
        columns: [
          {
            Header: 'Coachee',
            id: 'coacheeName',
            accessor: 'coacheeName',
          },
          {
            Header: 'Client',
            id: 'clientName',
            accessor: 'clientName',
          },
          {
            Header: 'Status',
            id: 'status',
            accessor: 'status',
          },
          {
            Header: 'Last Updated',
            id: 'lastUpdated',
            accessor: 'lastUpdated',
            Cell: ({ row }: Cell<TableData>) => useDateTimeString(row.original.lastUpdated),
          },
          {
            Header: '',
            id: 'actions',
            accessor: 'id',
            Cell: ({ row }: Cell<TableData>) => {
              const disabled = downloading != '' || row.values.status != 'Completed'
              const sx = disabled ? { filter: 'grayscale(1)' } : {}
              return (
                <Box sx={{ float: 'right' }}>
                  <IconButton onClick={() => navigate(`/coachees/profile?id=${row.original.coacheeId}`)}>
                    <BlendCoacheeIcon />
                  </IconButton>
                  <IconButton onClick={() => navigate(`/questionnaire/view?id=${row.values.actions}`)}>
                    <BlendViewIcon />
                  </IconButton>
                  <IconButton disabled={disabled} onClick={() => handleDownload(row.values)} sx={sx}>
                    <BlendDownloadIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(row.values.coacheeName, row.original.id)}>
                    <BlendBinIcon />
                  </IconButton>
                </Box>
              )
            },
          },
        ],
      },
    ],
    [],
  )

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Title variant="h2">Questionnaires</Title>
      </Box>
      {/* <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item sm={6} md={4}>
          <DashboardSquare bgcolor="#1763AF">
            <DashboardSquareInner number={0} tagline="Data coming soon" />
          </DashboardSquare>
        </Grid>
        <Grid item sm={6} md={4}>
          <DashboardSquare bgcolor="#1763AF">
            <DashboardSquareInner number={0} tagline="Data coming soon" />
          </DashboardSquare>
        </Grid>
        <Grid item sm={6} md={4}>
          <DashboardSquare bgcolor="#1763AF">
            <DashboardSquareInner number={0} tagline="Data coming soon" />
          </DashboardSquare>
        </Grid>
      </Grid> */}
      {error ? <h1>Could not fetch Questionnaires</h1> : null}
      {!error && data && (!tableData || tableData.length == 0) ? <h1>There are no Questionnaires</h1> : null}
      {!isFetching && data && tableData && tableData.length ? (
        <Table columns={columns} data={tableData} searchLabel="Search Questionnaires" secondaryColor />
      ) : !error && !data ? (
        <BlendFullpageSpinner />
      ) : null}
    </Box>
  )
}
