import { Box, Grid, styled, Typography } from '@mui/material'
import React, { useEffect, ChangeEvent, useState } from 'react'
import { CoachCard } from './coachCard'
import { useWindowSize } from '../../hooks/useWindoSize'
import { useQuery } from 'react-query'
import { useUserContext } from '../../providers/User'
import { useNavigate } from 'react-router-dom'
import { getCoaches } from '../../queries'
import { BlendSearchTextField } from '../../components/Common/SearchTextField'
import { BlendPrimaryButton } from '../../components/Common/Buttons'
import { GetAllCoachesRes } from '../../types/coach_types'
import { BlendPagination } from '../../components/Common/Pagination'
import { BlendFullpageSpinner } from '../../components/Common/Spinner'
import { ValidatedRes } from '../../types/response_body'

const Title = styled(Typography)({
  margin: '20px',
})

export const ScrollableContainer = styled(Box)({
  width: '100%',
  minHeight: '200px',
  overflowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: 5,
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    outline: `1px solid slategrey`,
  },
})

export const Coaches: React.FC = () => {
  const { height, width } = useWindowSize()

  const context = useUserContext()
  const navigate = useNavigate()

  //Pagniation States
  const [currentPageState, setCurrentPageState] = useState(1)
  const [cardsPerPageState, setCardsPerPageState] = useState(3)
  const [indexOfLastCardState, setIndexOfLastCardState] = useState<number>(0)
  const [indexOfFirstCardState, setIndexOfFirstCardState] = useState<number>(0)

  const { isFetching, isLoading, data } = useQuery<ValidatedRes<GetAllCoachesRes>, Error>({
    queryFn: () => getCoaches(context),
    queryKey: 'getAllCoachesData',
    enabled: !!context.token,
    onSuccess: (data) => {
      setFilteredCoachesState(data.coaches)
    },
    onError: (err) => {
      console.error(err)
    },
  })

  //Coach List states
  const [filterState, setFilterState] = useState<string>('')
  const coachState = data && data.coaches.length > 0 ? data.coaches : []
  const [filteredCoachesState, setFilteredCoachesState] = useState([...coachState])

  //Initial State calculation
  useEffect(() => {
    const calculatedIndexOfLastCardState = currentPageState * cardsPerPageState
    setIndexOfLastCardState(calculatedIndexOfLastCardState)
    setIndexOfFirstCardState(
      calculatedIndexOfLastCardState - cardsPerPageState <= 0 ? 0 : calculatedIndexOfLastCardState - cardsPerPageState,
    )
  }, [])

  //Resize useEffect
  useEffect(() => {
    const handleResize = () => {
      let cards = 3

      if (width && width <= 900) cards = 6
      else if (width && width <= 1200) cards = 9
      else if (width && width > 1200) cards = 12

      setCardsPerPageState(cards)

      const calculatedIndexOfLastCardState = currentPageState * cards

      setIndexOfLastCardState(currentPageState * cards)
      setIndexOfFirstCardState(calculatedIndexOfLastCardState - cards <= 0 ? 0 : calculatedIndexOfLastCardState - cards)
    }

    handleResize()
  }, [width && width > 900, width && width > 1200])

  const handlePaginationChange = (pageState: number) => {
    setCurrentPageState(pageState)

    const calculatedIndexOfLastCardState = pageState * cardsPerPageState

    setIndexOfLastCardState(calculatedIndexOfLastCardState)
    setIndexOfFirstCardState(
      calculatedIndexOfLastCardState - cardsPerPageState <= 0 ? 0 : calculatedIndexOfLastCardState - cardsPerPageState,
    )
  }

  useEffect(() => {
    handlePaginationChange(1)
  }, [cardsPerPageState])

  const handleCardClick = (id: string) => {
    navigate(`/coaches/profile?id=${id}`)
  }

  const handleSearchBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilter = event.target.value
    if (newFilter != '') {
      const newFilteredCoaches =
        coachState &&
        coachState.filter(
          (coach) =>
            coach.firstName.toLowerCase().includes(newFilter.toLowerCase()) ||
            coach.lastName.toLowerCase().includes(newFilter.toLowerCase()),
        )
      if (newFilteredCoaches !== undefined) {
        setFilteredCoachesState(newFilteredCoaches)
      } else setFilteredCoachesState(coachState)
      setFilterState(newFilter)
    } else {
      setFilterState('')
      setFilteredCoachesState(coachState)
    }
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      sx={{ minHeight: '100%', width: '100%' }}
    >
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Title variant="h2">Coaches</Title>
        </Grid>
        <Grid item>
          <BlendPrimaryButton variant="contained" onClick={() => navigate('/coaches/profile?edit=true')}>
            Create New Coach
          </BlendPrimaryButton>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Grid item key="filter-box-grid-item">
          <BlendSearchTextField
            value={filterState}
            placeholder="Search"
            onChange={(e: any) => handleSearchBoxChange(e)}
          />
        </Grid>
      </Grid>
      {!isFetching && !isLoading && filteredCoachesState ? (
        filteredCoachesState && filteredCoachesState.length > 0 ? (
          <ScrollableContainer height={'calc(100vh - 250px)'} mt="10px" p="5px">
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="stretch"
              spacing="20px"
              sx={{ height: 'auto' }}
            >
              {filteredCoachesState.slice(indexOfFirstCardState, indexOfLastCardState).map((coach, i) => (
                <Grid
                  item
                  key={'card-' + coach.firstName + i}
                  sm={6}
                  md={width && width < 1200 ? 4 : 3}
                  sx={{ height: '100%' }}
                >
                  <CoachCard {...coach} onClick={() => handleCardClick(coach.id)} />
                </Grid>
              ))}
            </Grid>
          </ScrollableContainer>
        ) : (
          <h1>There are no Coaches</h1>
        )
      ) : (
        <BlendFullpageSpinner />
      )}
      <Grid container direction="row">
        <BlendPagination
          count={Math.ceil(filteredCoachesState.length / cardsPerPageState)}
          page={currentPageState}
          variant="outlined"
          shape="rounded"
          onChange={(event, pageState) => handlePaginationChange(pageState)}
          color="standard"
        />
      </Grid>
    </Grid>
  )
}
