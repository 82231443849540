import { Box, styled, Typography, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BlendSwitch } from '../../components/Common/Switch'
import theme from '../../theme'

export interface CalendarCoach {
  id: string
  firstName: string
  lastName: string
  showEvents: boolean
}

export interface CoachFilterProps {
  coaches: CalendarCoach[]
  handleShowAllSwitchChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  handleCoachSwitchChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, coachId: string) => void
}

interface CoachToggleProps {
  coach: CalendarCoach
  handleCoachSwitchChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, coachId: string) => void
}

const FilterContainer = styled(Box)({
  boxShadow: '-2px 2px 4px rgba(20, 89, 171, 0.33), 0px 1px 9px rgba(0, 0, 0, 0.09)',
  width: '100%',
  height: '800px',
  borderRadius: '8px',
  padding: '20px',
  overflowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: 5,
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    outline: `1px solid slategrey`,
  },
})

const CoachToggle: React.FC<CoachToggleProps> = (props) => {
  const [checkedState, setCheckedState] = useState(props.coach.showEvents)

  useEffect(() => setCheckedState(props.coach.showEvents), [props.coach.showEvents])

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: '100%', marginTop: '20px' }}
    >
      <Typography variant="caption" fontSize="13pt">
        {props.coach.firstName} {props.coach.lastName}
      </Typography>
      <BlendSwitch
        inputProps={{ 'aria-label': 'ant design' }}
        checked={checkedState}
        onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
          props.handleCoachSwitchChange(event, checked, props.coach.id)
        }}
      />
    </Grid>
  )
}

export const CoachFilter: React.FC<CoachFilterProps> = (props) => {
  return (
    <FilterContainer>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: '100%',
          marginTop: '20px',
          paddingBottom: '20px',
          borderBottom: `1px solid ${theme.blend.midGrey}`,
        }}
      >
        <Typography variant="caption" fontSize="13pt">
          Show all
        </Typography>
        <BlendSwitch
          inputProps={{ 'aria-label': 'ant design' }}
          defaultChecked={true}
          onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            props.handleShowAllSwitchChange(event, checked)
          }}
        />
      </Grid>
      {props.coaches.map((coach, i) => (
        <CoachToggle key={'toggle-' + i} coach={coach} handleCoachSwitchChange={props.handleCoachSwitchChange} />
      ))}
    </FilterContainer>
  )
}
