import { FormControlLabel, Grid, SelectChangeEvent, Typography } from '@mui/material'
import React, { useMemo, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useUserContext } from '../../providers/User'
import { BlendSelect, BlendMenuItem } from '../../components/Common/Select'
import { BlendTextField } from '../../components/Common/TextField'
import { BlendCheckbox } from '../../components/Common/Checkbox'
import {
  getClients,
  getCoacheesByContract,
  getCoaches,
  getActiveContracts,
  getSession,
  getCoachee,
  createSession,
  updateSession,
  getContract,
  getClient,
  deleteSession,
} from '../../queries'
import { BlendDatePicker } from '../../components/Common/DatePicker'
import { BlendDestructiveButton, BlendPrimaryButton, BlendSecondaryButton } from '../../components/Common/Buttons'
import { useNavigate } from 'react-router-dom'
import { GetCoacheeRes, GetCoacheesByContractRes } from '../../types/coachee_type'
import { GetSessionRes, SessionStatus } from '../../types/session_type'
import { GetAllCoachesRes } from '../../types/coach_types'
import { GetAllClientsRes, GetClientRes } from '../../types/client_type'
import { GetActiveContractsRes, GetContractRes } from '../../types/contract_type'
import { BlendFullpageSpinner } from '../../components/Common/Spinner'
import { useDialogContext } from '../../providers/Dialog'
import { sessionValidator } from '../../validators/sessionValidator'
import { sessionReducer } from '../../reducers/sessionReducer'
import { BlendDivider } from '../../components/Common/Divider'
import { BlendWarningIcon } from '../../components/Common/Icons'
import { BlendBreadcrumb, BlendBreadcrumbs } from '../../components/Common/Breadcrumbs'
import { useDateString } from '../../hooks/useDateTime'

interface SessionEditorProps {
  mode: 'create' | 'edit'
  isPastSession?: boolean | null
}

export const SessionEditor: React.FC<SessionEditorProps> = (props) => {
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  const sessionId: string | null = props.mode == 'edit' ? urlParams.get('id') : null
  const clientId: string | null = urlParams.get('clientId')
  const contractId: string | null = urlParams.get('contractId')
  const coacheeId: string | null = urlParams.get('coacheeId')

  const context = useUserContext()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const dialog = useDialogContext()

  // Queries
  const getSessionQuery = () => getSession(context, sessionId)
  const { isFetching: isFetchingSession, data: dataSession } = useQuery<GetSessionRes>({
    queryFn: getSessionQuery,
    queryKey: ['getSessionData', sessionId],
    enabled: !!context.token && sessionId != null,
    onSuccess: (data) => {
      const sessionData = data ?? {}
      const start = new Date(sessionData.start || '')
      const end = new Date(sessionData.end || '')
      setSessionState({ type: 'SESSION', field: '', payload: { ...sessionData, start, end } })
      const startMinutes = start.getMinutes()
      const endMinutes = end.getMinutes()
      setStartTime(`${start.getHours()}:${startMinutes == 0 ? '00' : startMinutes}`)
      setEndTime(`${end.getHours()}:${endMinutes == 0 ? '00' : endMinutes}`)
    },
  })

  const getCoacheeQuery = () => getCoachee(context, coacheeId ?? null)
  const { isFetching: isFetchingCoachee, data: dataCoachee } = useQuery<GetCoacheeRes>({
    queryFn: getCoacheeQuery,
    queryKey: ['getCoacheeData', coacheeId],
    enabled: !!context.token && coacheeId != null,
    onSuccess: (data) => {
      setSessionState({
        type: 'SESSION',
        field: '',
        payload: {
          ...sessionState,
          coachId: data.coaches && data.coaches.length ? data.coaches[0]?.id : null,
          clientId: data.coachee?.clientId ?? null,
          contractId: data.coachee?.contractId ?? null,
          coacheeId: data.coachee?.id ?? null,
          createOutlookEvent: false,
          status: SessionStatus[SessionStatus.Open as keyof typeof SessionStatus],
          isPaid: false,
        },
      })
    },
  })

  const getContractQuery = () => getContract(context, contractId ?? null)
  const { isFetching: isFetchingContract, data: dataContract } = useQuery<GetContractRes>({
    queryFn: getContractQuery,
    queryKey: ['getContractId', contractId],
    enabled: !!context.token && contractId != null,
    onSuccess: (data) => {
      setSessionState({
        type: 'SESSION',
        field: '',
        payload: {
          ...sessionState,
          coachId: null,
          clientId: data.client.clientId,
          contractId: data.contract.id,
          coacheeId: null,
          createOutlookEvent: false,
          status: SessionStatus[SessionStatus.Open as keyof typeof SessionStatus],
          isPaid: false,
        },
      })
    },
  })

  const getClientQuery = () => getClient(context, clientId ?? null)
  const { isFetching: isFetchingClient, data: dataClient } = useQuery<GetClientRes>({
    queryFn: getClientQuery,
    queryKey: ['getClientId', clientId],
    enabled: !!context.token && clientId != null,
    onSuccess: (data) => {
      setSessionState({
        type: 'SESSION',
        field: '',
        payload: {
          ...sessionState,
          coachId: null,
          clientId: data.client.id,
          contractId: null,
          coacheeId: null,
          createOutlookEvent: false,
          status: SessionStatus[SessionStatus.Open as keyof typeof SessionStatus],
          isPaid: false,
        },
      })
    },
  })

  const getCoachesQuery = () => getCoaches(context)
  const { isFetching: isFetchingCoaches, data: dataCoaches } = useQuery<GetAllCoachesRes>({
    queryFn: getCoachesQuery,
    queryKey: 'getAllCoachesData',
    enabled: !!context.token,
  })

  const getClientsQuery = () => getClients(context)
  const { isFetching: isFetchingClients, data: dataClients } = useQuery<GetAllClientsRes>({
    queryFn: getClientsQuery,
    queryKey: 'getAllClientsData',
    enabled: !!context.token && (!props.isPastSession || props.mode === 'create'),
  })

  // Session Reducer
  const [sessionState, setSessionState] = useReducer(
    sessionReducer,
    dataSession
      ? dataSession
      : dataCoachee
      ? {
          id: null,
          coachId: dataCoachee.coaches && dataCoachee.coaches.length ? dataCoachee.coaches[0]?.id : null,
          clientId: dataCoachee.coachee?.clientId ?? null,
          contractId: dataCoachee.coachee?.contractId ?? null,
          coacheeId: dataCoachee.coachee?.id ?? null,
          start: null,
          end: null,
          createOutlookEvent: false,
          status: SessionStatus[SessionStatus.Open as keyof typeof SessionStatus],
          isPaid: false,
        }
      : dataContract
      ? {
          id: null,
          coachId: null,
          clientId: dataContract.client.clientId ?? null,
          contractId: dataContract.contract.id ?? null,
          coacheeId: null,
          start: null,
          end: null,
          createOutlookEvent: false,
          status: SessionStatus[SessionStatus.Open as keyof typeof SessionStatus],
          isPaid: false,
        }
      : {
          id: null,
          coachId: null,
          clientId: dataClient?.client.id ?? null,
          contractId: null,
          coacheeId: null,
          start: null,
          end: null,
          createOutlookEvent: false,
          status: SessionStatus[SessionStatus.Open as keyof typeof SessionStatus],
          isPaid: false,
        },
  )

  // Contracts and Coachees queries
  const getActiveContractsForClientQuery = () => getActiveContracts(context, sessionState.clientId)
  const { isFetching: isFetchingContracts, data: dataContracts } = useQuery<GetActiveContractsRes>({
    queryFn: getActiveContractsForClientQuery,
    queryKey: ['getActiveContractsData', sessionState.clientId],
    enabled: !!context.token && !!sessionState.clientId && (!props.isPastSession || props.mode === 'create'),
  })

  const getCoacheesByContractQuery = () => getCoacheesByContract(context, sessionState.contractId)
  const { isFetching: isFetchingCoachees, data: dataCoachees } = useQuery<GetCoacheesByContractRes>({
    queryFn: getCoacheesByContractQuery,
    queryKey: ['getCoacheesByContractData', sessionState.contractId],
    enabled: !!context.token && !!sessionState.contractId && (!props.isPastSession || props.mode === 'create'),
  })

  // Other useStates and useMemos
  const [coachHasBlendEmailState, setCoachHasBlendEmailState] = useState<boolean>(
    sessionState.coachId && dataCoaches
      ? () => {
          const coach = dataCoaches.coaches.find((coach) => coach.id === sessionState.coachId)
          return coach?.email.toLowerCase().includes('@ltdblend') ?? false
        }
      : false,
  )

  const [startTime, setStartTime] = useState<string>(() => {
    if (dataSession && !!dataSession.start) {
      const start = new Date(dataSession.start)
      return `${start.getHours()}:${start.getMinutes() == 0 ? '00' : start.getMinutes()}`
    } else return ''
  })

  const [endTime, setEndTime] = useState<string>(() => {
    if (dataSession && !!dataSession.end) {
      const end = new Date(dataSession.end)
      return `${end.getHours()}:${end.getMinutes() == 0 ? '00' : end.getMinutes()}`
    } else return ''
  })

  const availability = useMemo<string>(
    () => dataCoaches?.coaches.find((coach) => coach.id === sessionState.coachId)?.availability ?? '',
    [dataCoaches, sessionState.coachId],
  )

  const timeOptions = useMemo<string[]>(() => {
    const options = new Array<string>()
    let hours, minutes
    const from8 = 480
    const to19 = 1140
    const step = 30
    for (let i = from8; i <= to19; i += step) {
      hours = Math.floor(i / 60)
      minutes = i % 60
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      options.push(hours + ':' + minutes)
    }
    return options
  }, [])

  // Mutation
  const mutation = useMutation<unknown, Error, boolean>({
    mutationFn: (createOutlookEvent) =>
      sessionId
        ? updateSession(context, sessionState, createOutlookEvent)
        : createSession(context, sessionState, createOutlookEvent),
    onError: async (error) => await dialog.fetchErrorDialog(JSON.parse(error.message)),
    onSuccess: async (data) => {
      try {
        await queryClient.invalidateQueries(['getEventsForData'])
        if (props.isPastSession) {
          await queryClient.invalidateQueries(['getCoachSessionHistoryData', sessionState.coachId])
        }
        await queryClient.invalidateQueries(['getClientData', sessionState.clientId])
        await queryClient.invalidateQueries(['getContractData', sessionState.contractId])
        if (coacheeId || sessionState.coacheeId) await queryClient.invalidateQueries(['getCoacheeData', coacheeId])
        if (sessionId) await queryClient.setQueryData(['getSessionData', sessionId], data as GetSessionRes)
        navigate(-1)
      } catch (error: unknown) {
        console.error(error)
      }
    },
  })

  const { mutate: mutateDelete } = useMutation<unknown, Error>({
    mutationFn: () => deleteSession(context, sessionId),
    onError: (error) => console.error(error),
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries(['getEventsForData'])
        if (props.isPastSession) {
          await queryClient.invalidateQueries(['getCoachSessionHistoryData', sessionState.coachId])
        }
        await queryClient.invalidateQueries(['getClientData', sessionState.clientId])
        await queryClient.invalidateQueries(['getContractData', sessionState.contractId])
        if (coacheeId || sessionState.coacheeId) await queryClient.invalidateQueries(['getCoacheeData', coacheeId])
        navigate(-1)
      } catch (error: unknown) {
        console.error(error)
      }
    },
  })

  // Handlers
  const handleSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target
    setSessionState({ type: 'STRING', field: name, payload: value })
    if (name === 'coachId') {
      const coach = dataCoaches?.coaches.find((c) => c.id == value)
      if (coach) {
        setCoachHasBlendEmailState(coach.email.toLowerCase().includes('@ltdblend'))
      }
    }
  }

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setSessionState({ type: 'STRING', field: name, payload: value })
  }

  const handleDateChange = (date: Date | null) => {
    setSessionState({ type: 'DATE', field: '', payload: date })
  }

  const handleTimeSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target
    setSessionState({ type: 'TIME', field: name, payload: value })
    if (name === 'startTime') setStartTime(value as string)
    else setEndTime(value as string)
  }

  const handleChekboxChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSessionState({ type: 'CHECKBOX', field: e.target.name, payload: checked })
  }

  const handleConfirmClick = async (createOutlookEvent: boolean) => {
    const sessionValidation = sessionValidator({
      ...sessionState,
      createOutlookEvent,
    })
    if (sessionValidation.length > 0) {
      await dialog.openDialog(sessionValidation, 3)
    } else {
      await dialog.openConfirmDialog(
        createOutlookEvent
          ? 'Are you sure you want to save this event and send invites via Outlook?'
          : 'Are you sure you want to save this event?',
        async () => {
          mutation.mutate(createOutlookEvent)
        },
      )
    }
  }
  const handleConfirmAndSaveClick = () => handleConfirmClick(false)
  const handleConfirmAndSendInvitesClick = () => handleConfirmClick(true)

  const handleDelete = () => {
    dialog.openDeleteDialog(
      `this session for ${sessionState.coacheeName} on ${useDateString(sessionState.start)}`,
      () => {
        mutateDelete()
      },
    )
  }

  const breadcrumbs: BlendBreadcrumb[] = !sessionId
    ? [
        { label: 'Calendar', link: '/calendar' },
        {
          label: !props.isPastSession
            ? sessionId
              ? 'Edit Booking'
              : 'New Booking'
            : sessionId
            ? 'Edit Session'
            : 'Add Past Session',
        },
      ]
    : [
        { label: 'Calendar', link: '/calendar' },
        { label: sessionState.clientName ?? 'Client', link: `/clients/profile?id=${sessionState.clientId}` },
        { label: sessionState.contractHandle ?? 'Contract', link: `/contracts/profile?id=${sessionState.contractId}` },
        { label: sessionState.coacheeName ?? 'Coachee', link: `/coachees/profile?id=${sessionState.coacheeId}` },
        {
          label: !props.isPastSession
            ? sessionId
              ? 'Edit Booking'
              : 'New Booking'
            : sessionId
            ? 'Edit Session'
            : 'Add Past Session',
        },
      ]

  return !isFetchingSession &&
    !isFetchingCoaches &&
    !isFetchingClients &&
    !isFetchingContracts &&
    !isFetchingCoachees &&
    !isFetchingCoachee ? (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      sx={{ minHeight: '100%', width: '100%' }}
    >
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" sx={{ width: '100%' }}>
        <BlendBreadcrumbs breadcrumbs={breadcrumbs} />
        <Grid item sx={{ marginBottom: '20px' }}>
          <Typography variant="h2">{!props.isPastSession && <>{sessionId ? 'Edit' : 'New'} Booking</>}</Typography>
          <Typography variant="h2">{props.isPastSession && <>{sessionId ? 'Edit' : 'Add Past'} Session</>}</Typography>
        </Grid>
        <Grid item sx={{ marginBottom: '20px' }}>
          <Typography variant="h6">Coach</Typography>
        </Grid>
        <Grid container item direction="row">
          <Grid item md={4} sx={{ paddingRight: '20px' }}>
            {!props.isPastSession && (
              <BlendSelect
                width="100%"
                required={true}
                label="Coach name"
                name="coachId"
                value={sessionState.coachId ?? ''}
                renderValue={(selected) => {
                  if ((selected as string).length === 0) {
                    return <>Select Coach</>
                  }
                  const coach = dataCoaches && dataCoaches?.coaches.find((c) => c.id == selected)
                  return (
                    <>
                      {coach?.firstName} {coach?.lastName}
                    </>
                  )
                }}
                onChange={handleSelectChange}
              >
                {dataCoaches &&
                  dataCoaches?.coaches.map((coach, index) => (
                    <BlendMenuItem key={`coach-${index}`} value={coach.id}>
                      {coach.firstName} {coach.lastName}
                    </BlendMenuItem>
                  ))}
              </BlendSelect>
            )}
            {props.isPastSession && (
              <BlendTextField readOnly={true} name="coachId" label="Coach name" value={sessionState.coachName} />
            )}
          </Grid>
          <Grid item md={6}>
            <BlendTextField
              width="100%"
              readOnly={true}
              label="Availability"
              name="availability"
              placeholder="Availability information will appear once a Coach is selected"
              value={availability}
            />
          </Grid>
        </Grid>
        <BlendDivider sx={{ marginTop: '40px' }} />
        <Grid item sx={{ marginY: '20px' }}>
          <Typography variant="h6">Contract</Typography>
        </Grid>
        <Grid container item direction="row" spacing="20px" md={12} lg={8}>
          <Grid item md={4}>
            {(!props.isPastSession || props.mode === 'create') && (
              <BlendSelect
                label="Client"
                width="100%"
                required={true}
                name="clientId"
                value={sessionState.clientId ?? ''}
                renderValue={(selected: unknown) => {
                  if ((selected as string).length === 0) {
                    return 'Select Client'
                  }
                  return dataClients?.clients.find((c) => c.id == selected)?.name ?? ''
                }}
                onChange={handleSelectChange}
              >
                {dataClients &&
                  dataClients?.clients.map((client, index) => (
                    <BlendMenuItem key={`client-${index}`} value={client.id}>
                      {client.name}
                    </BlendMenuItem>
                  ))}
              </BlendSelect>
            )}
            {props.isPastSession && props.mode === 'edit' && (
              <BlendTextField label="Client" readOnly={true} name="clientId" value={sessionState.clientName} />
            )}
          </Grid>
          <Grid item md={4}>
            {(!props.isPastSession || props.mode === 'create') && (
              <BlendSelect
                label="Contract"
                width="100%"
                required={true}
                name="contractId"
                value={sessionState.contractId ?? ''}
                renderValue={(selected: unknown) => {
                  if ((selected as string).length === 0) {
                    return 'Select Contract'
                  }
                  const contract = dataContracts?.contracts.find((c) => c.id == selected)
                  return (
                    contract && (
                      <>
                        {contract.expired && <BlendWarningIcon color="warning" />} {contract.contractHandle}
                      </>
                    )
                  )
                }}
                onChange={handleSelectChange}
              >
                {dataContracts &&
                  dataContracts?.contracts.map((contract, index) => (
                    <BlendMenuItem key={`contract-${index}`} value={contract.id}>
                      {contract.expired && <BlendWarningIcon color="warning" />} {contract.contractHandle}
                    </BlendMenuItem>
                  ))}
              </BlendSelect>
            )}
            {props.isPastSession && props.mode === 'edit' && (
              <BlendTextField label="Contract" readOnly={true} name="contractId" value={sessionState.contractHandle} />
            )}
          </Grid>
          <Grid item md={4}>
            {(!props.isPastSession || props.mode === 'create') && (
              <BlendSelect
                label="Coachee"
                width="100%"
                required={true}
                name="coacheeId"
                value={sessionState.coacheeId ?? ''}
                renderValue={(selected: unknown) => {
                  if ((selected as string).length === 0) {
                    return 'Select Coachee'
                  }
                  return (dataCoachees?.coachees && dataCoachees?.coachees.find((c) => c.id == selected)?.name) ?? ''
                }}
                onChange={handleSelectChange}
              >
                {dataCoachees?.coachees &&
                  dataCoachees?.coachees.map((coachee, index) => (
                    <BlendMenuItem key={`coachee-${index}`} value={coachee.id}>
                      {coachee.name}
                    </BlendMenuItem>
                  ))}
              </BlendSelect>
            )}
            {props.isPastSession && props.mode === 'edit' && (
              <BlendTextField label="Coachee" readOnly={true} name="coacheeId" value={sessionState.coacheeName} />
            )}
          </Grid>
        </Grid>
        <BlendDivider sx={{ marginTop: '40px' }} />
        <Grid item sx={{ marginY: '20px' }}>
          <Typography variant="h6">Date & Time</Typography>
        </Grid>
        <Grid container item direction="row" pb={props.isPastSession ? '60px' : '200px'}>
          <Grid item md={3} sx={{ marginRight: '20px' }}>
            <BlendDatePicker
              label="Date"
              required={true}
              name="start"
              placeholder="Select Option"
              value={sessionState.start}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item md={2} sx={{ marginRight: '20px' }}>
            <BlendSelect
              label="Start Time"
              required={true}
              name="startTime"
              value={startTime}
              renderValue={(selected) => ((selected as string).length === 0 ? 'Start Time' : (selected as string))}
              onChange={handleTimeSelectChange}
            >
              {timeOptions.map((option, index) => (
                <BlendMenuItem key={`startTime-${index}`} value={option}>
                  {option}
                </BlendMenuItem>
              ))}
            </BlendSelect>
          </Grid>
          <Grid item md={2}>
            <BlendSelect
              label="End Time"
              required={true}
              name="endTime"
              value={endTime}
              renderValue={(selected) => ((selected as string).length === 0 ? 'End Time' : (selected as string))}
              onChange={handleTimeSelectChange}
            >
              {timeOptions.map((option, index) => (
                <BlendMenuItem key={`endTime-${index}`} value={option}>
                  {option}
                </BlendMenuItem>
              ))}
            </BlendSelect>
          </Grid>
        </Grid>
        {props.isPastSession && (
          <>
            <BlendDivider sx={{ marginTop: '20px' }} />
            <Grid item sx={{ marginY: '20px' }}>
              <Typography variant="h6">Session</Typography>
            </Grid>
            <Grid container item direction="row">
              <Grid item md={4} sx={{ paddingRight: '20px' }}>
                <BlendSelect
                  width="100%"
                  required={true}
                  label="Session Status"
                  name="status"
                  value={sessionState.status}
                  renderValue={(selected: unknown) =>
                    !selected || (selected as string).length === 0
                      ? 'Select Status'
                      : Object.values(SessionStatus)[Object.keys(SessionStatus).indexOf(sessionState.status)]
                  }
                  onChange={handleSelectChange}
                >
                  {Object.keys(SessionStatus).map((key, index) => (
                    <BlendMenuItem key={`status-${index}`} value={key}>
                      {SessionStatus[key as keyof typeof SessionStatus].valueOf()}
                    </BlendMenuItem>
                  ))}
                </BlendSelect>
              </Grid>
              <Grid item md={3} sx={{ paddingRight: '20px' }}>
                <FormControlLabel
                  sx={{
                    mt: '33px',
                  }}
                  control={<BlendCheckbox checked={sessionState.isPaid} name="isPaid" onChange={handleChekboxChange} />}
                  label="Coach paid"
                />
              </Grid>
              <Grid item md={3}>
                <BlendTextField
                  name="invoiceReference"
                  label="Invoice reference"
                  placeholder="Invoice reference"
                  value={sessionState.invoiceReference}
                  onChange={handleTextFieldChange}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container item direction="row" pb="20px" justifyContent="center">
        <Grid item pr="20px">
          <BlendSecondaryButton variant="contained" onClick={() => navigate(-1)}>
            Cancel
          </BlendSecondaryButton>
        </Grid>
        {props.mode === 'edit' && (
          <Grid item pr="20px">
            <BlendDestructiveButton onClick={handleDelete}>Delete</BlendDestructiveButton>
          </Grid>
        )}
        <Grid item pr="20px">
          <BlendPrimaryButton variant="contained" onClick={handleConfirmAndSaveClick}>
            {props.isPastSession ? 'Save' : 'Confirm & Save'}
          </BlendPrimaryButton>
        </Grid>
        {!props.isPastSession && (
          <Grid item>
            <BlendPrimaryButton
              variant="contained"
              disabled={!coachHasBlendEmailState}
              onClick={handleConfirmAndSendInvitesClick}
            >
              Confirm & Send Invites
            </BlendPrimaryButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  ) : (
    <BlendFullpageSpinner isBigLoad />
  )
}
