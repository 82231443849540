import { Box, Grid, InputAdornment, Typography } from '@mui/material'
import React, { useEffect, useReducer } from 'react'
import { CoachEditProps } from '../..'
import { coachReducer } from '../../../../../reducers/coachReducer'
import { Coach } from '../../../../../types/coach_types'
import { BlendDatePicker } from '../../../../../components/Common/DatePicker'
import { BlendTextField } from '../../../../../components/Common/TextField'
import { BlendDivider } from '../../../../../components/Common/Divider'

export const EditLegalInsurance: React.FC<CoachEditProps> = (props: CoachEditProps) => {
  const [coachState, setCoachState] = useReducer(coachReducer, props as Coach)

  useEffect(() => {
    props.callBack(coachState)
  }, [coachState])

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCoachState({
      type: 'STRING',
      field: e.target.name,
      payload: e.target.value,
    })
  }

  const handleDateChange = (date: Date | null, field: string) => {
    if (date)
      setCoachState({
        type: 'DATE',
        field: field,
        payload: date,
      })
  }

  return (
    <Box>
      <form>
        <Typography variant={'h6'}>Associate Contract</Typography>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ paddingTop: 2 }}>
          <Grid item>
            <Box>
              <BlendDatePicker
                label="Date signed"
                name="associateContractSigned"
                value={coachState.associateContractSigned}
                onChange={handleDateChange}
              />
            </Box>
          </Grid>
          <Grid item sx={{ marginLeft: 2 }}>
            <Box>
              <BlendDatePicker
                required
                label="Expiry date"
                name="associateContractExpiry"
                value={coachState.associateContractExpiry}
                onChange={handleDateChange}
              />
            </Box>
          </Grid>
        </Grid>
        <BlendDivider sx={{ paddingTop: '35px', marginBottom: '20px' }} />
        <Typography variant={'h6'}>Data Protection Agreement</Typography>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ paddingTop: 2 }}>
          <Grid item>
            <Box>
              <BlendDatePicker
                label="Date signed"
                name="dataProtectionAgreementSigned"
                value={coachState.dataProtectionAgreementSigned}
                onChange={handleDateChange}
              />
            </Box>
          </Grid>
          <Grid item sx={{ marginLeft: 2 }}>
            <Box>
              <BlendDatePicker
                required
                label="Expiry date"
                name="dataProtectionAgreementExpiry"
                value={coachState.dataProtectionAgreementExpiry}
                onChange={handleDateChange}
              />
            </Box>
          </Grid>
        </Grid>
        <BlendDivider sx={{ paddingTop: '35px', marginBottom: '20px' }} />
        <Typography variant={'h6'}>Identity Check</Typography>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ paddingTop: '20px' }}>
          <Grid item>
            <Box>
              <BlendDatePicker
                label="Date signed"
                name="identityCheckSigned"
                value={coachState.identityCheckSigned}
                onChange={handleDateChange}
              />
            </Box>
          </Grid>
        </Grid>
        <BlendDivider sx={{ paddingTop: '35px', marginBottom: '20px' }} />
        <Typography variant={'h6'}>Insurance details</Typography>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ paddingTop: '20px' }}>
          <Grid item>
            <Box>
              <BlendTextField
                label="Insurance provider"
                type="text"
                name="insuranceProvider"
                value={coachState.insuranceProvider}
                onChange={handleTextChange}
              />
            </Box>
          </Grid>
          <Grid item sx={{ marginLeft: 2 }}>
            <Box>
              <BlendTextField
                label="Insurance value"
                type="number"
                name="insuranceValue"
                value={coachState.insuranceValue}
                onChange={handleTextChange}
                InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }}
              />
            </Box>
          </Grid>
          <Grid item sx={{ marginLeft: 2 }}>
            <Box>
              <BlendDatePicker
                label="Expiry date"
                name="insuranceExpiry"
                value={coachState.insuranceExpiry}
                onChange={handleDateChange}
              />
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}
