import React from 'react'
import { Box, Grid, styled, Typography } from '@mui/material'
import { useQuery } from 'react-query'
import { useUserContext } from '../../providers/User'
import { BlendFullpageSpinner } from '../../components/Common/Spinner'
import { MetricSquare } from '../../components/Common/MetricSquare'
import { getDashboard } from '../../queries'

const Title = styled(Typography)({
  margin: '20px',
})

interface GetDashboardRes {
  upcomingSessions: number
  firstToBeBooked: number
  coachesNoAvailability: number
  activeContracts: number
  completedQuestionnaire: number
  fiftyPercentRemaining: number
  twentyFivePercentRemaining: number
  outstandingQuestionnaires: number
  activeCoachees: number
}

export const Dashboard: React.FC = () => {
  const context = useUserContext()

  const { isLoading, data } = useQuery<GetDashboardRes>({
    queryFn: () => getDashboard(context),
    queryKey: 'getDashboardData',
    enabled: !!context?.token,
    onError: (err) => {
      console.error(err)
    },
  })

  return !isLoading && data ? (
    <Box>
      <Title variant="h2">Welcome, {context.accountInfo && context.accountInfo.name}</Title>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing="20px">
        <Grid item sm={6} md={4}>
          <MetricSquare bgcolor="#1763AF" number={data.upcomingSessions} tagline="sessions upcoming this week" />
        </Grid>
        <Grid item sm={6} md={4}>
          <MetricSquare
            bgcolor="#1763AF"
            number={data.firstToBeBooked}
            tagline="outstanding first sessions to be booked"
          />
        </Grid>
        <Grid item sm={6} md={4}>
          <MetricSquare
            bgcolor="#1763AF"
            number={data.coachesNoAvailability}
            tagline="coaches without availability provided"
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <MetricSquare bgcolor="#00A79D" number={data.activeContracts} tagline="active contracts" />
        </Grid>
        <Grid item sm={12} md={6}>
          <MetricSquare
            bgcolor="#9CCB3B"
            number={data.completedQuestionnaire}
            tagline="completed questionnaires require action"
          />
        </Grid>
        <Grid item sm={6} md={3}>
          <MetricSquare
            bgcolor="#00A79D"
            number={data.fiftyPercentRemaining}
            tagline="contract quotas with 50% remaining"
          />
        </Grid>
        <Grid item sm={6} md={3}>
          <MetricSquare
            bgcolor="#00A79D"
            number={data.twentyFivePercentRemaining}
            tagline="contract quotas with 25% remaining"
          />
        </Grid>
        <Grid item sm={6} md={3}>
          <MetricSquare
            bgcolor="#9CCB3B"
            number={data.outstandingQuestionnaires}
            tagline="outstanding questionnaires"
          />
        </Grid>
        <Grid item sm={6} md={3}>
          <MetricSquare bgcolor="#9CCB3B" number={data.activeCoachees} tagline="active coachees" />
        </Grid>
      </Grid>
      <Box
        component="img"
        sx={{
          height: 108,
          position: 'fixed',
          right: 0,
          bottom: 0,
          zIndex: -100,
        }}
        alt="Blend Logo"
        src="/blend-corner.png"
      />
    </Box>
  ) : (
    <BlendFullpageSpinner />
  )
}
