import { Box, Grid, LinearProgress, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { ContractProps } from '..'
import { LinkedReferrals } from './tabs/linkedReferrals'
import { LinkedCoachees } from './tabs/linkedCoachees'
import { ContractStatus, getContractStatus, useDateString } from '../../../../hooks/useDateTime'
import { BlendPrimaryButton } from '../../../../components/Common/Buttons'
import { BlendTab, BlendTabsGroup } from '../../../../components/Common/Tabs'
import { BlendBreadcrumb, BlendBreadcrumbs } from '../../../../components/Common/Breadcrumbs'
import { BlendDivider } from '../../../../components/Common/Divider'
import { BlendContactCard } from '../../../../components/Contact'
import { LinkedSessions } from './tabs/linkedSessions'
import theme from '../../../../theme'
import { ContractCoachee } from '../../../../types/contract_type'
import { Coachee } from '../../../../types/coachee_type'

export const ViewContract: React.FC<ContractProps> = (props) => {
  const contract = props.contract
  const [tabState, setTabState] = useState(0)

  const contractStatus = useMemo(() => getContractStatus(contract.startDate, contract.expirationDate), [contract])

  const sessionsBookedOrCompleted = useMemo(() => {
    let count = 0
    contract.coachees?.map((coachee: Coachee | ContractCoachee) => (count += coachee.sessions?.length ?? 0))
    return count
  }, [contract.coachees])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabState(newValue)
  }

  const breadcrumbs: BlendBreadcrumb[] = [
    { label: 'Contract Manager', link: '/contracts' },
    { label: contract.client?.name ?? 'Client', link: `/clients/profile?id=${contract.clientId}` },
    { label: contract.contractName },
  ]

  return (
    <Box>
      <BlendBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', margin: '20px 20px 20px 0' }}>
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Typography variant="h2" display="flex">
              {contract.contractName}
            </Typography>
            <Typography
              variant="body1"
              color={
                contractStatus === ContractStatus.expired || contractStatus === ContractStatus.something_wrong
                  ? theme.blend.error
                  : theme.blend.green
              }
              display="flex"
              ml="40px"
            >
              {contract.startDate && contract.expirationDate && contractStatus.valueOf()}
            </Typography>
          </Box>
          <Typography variant="h6" color={theme.blend.lightGrey}>{`${contract.contractHandle} | ${
            contract.client?.name ?? ''
          }`}</Typography>
        </Box>
        <Box sx={{ display: 'flex', direction: 'row' }}>
          <BlendPrimaryButton onClick={props.callback}>Edit</BlendPrimaryButton>
        </Box>
      </Box>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {contract.contacts
          ?.sort((a, b) => (a.isContractPrimary ? -1 : 1))
          .map((contact) => {
            return (
              <Grid key={contact.id} item sm={6} md={3}>
                <BlendContactCard
                  bgcolor={contact.isContractPrimary ? 'rgba(23, 99, 175, 0.15)' : 'rgba(0, 167, 157, 0.15)'}
                  name={`${contact.firstName} ${contact.lastName}`}
                  email={contact.email ?? ''}
                  mobile={contact.mobile ?? ''}
                />
              </Grid>
            )
          })}
      </Grid>
      <BlendDivider sx={{ paddingTop: '20px', marginBottom: '20px' }} />
      {/* status bars */}
      {contract.sessionQuota && contract.coacheesQuota ? (
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={6} sx={{ paddingX: '20px' }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Typography>Sessions booked or completed</Typography>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress
                  variant="determinate"
                  color="success"
                  value={Math.round((sessionsBookedOrCompleted / contract.sessionQuota) * 100)}
                />
              </Box>
              <Box sx={{ display: 'flex', minWidth: '200px', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="body1">{`${sessionsBookedOrCompleted} / ${contract.sessionQuota} sessions`}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ paddingX: '20px' }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Typography>Coachees referred</Typography>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress
                  variant="determinate"
                  color="success"
                  value={Math.round(
                    (((contract.coachees && contract.coachees?.length) ?? 0) / contract.coacheesQuota) * 100,
                  )}
                />
              </Box>
              <Box sx={{ display: 'flex', minWidth: '200px', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="body1">{`${(contract.coachees && contract.coachees?.length) ?? 0} / ${
                  contract.coacheesQuota
                } coachees`}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : null}
      {/* contract info */}
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '20px' }}>
        <Grid item>
          <Typography variant="body1">
            <strong>Session length:</strong> {contract.sessionLength} mins
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            <strong>Coaching type:</strong> {contract.isOneToOne ? '1:1' : contract.isGroup ? 'Group' : 'N/A'}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            <strong>Start date:</strong> {useDateString(contract.startDate)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            <strong>Expiration date:</strong> {useDateString(contract.expirationDate)}
          </Typography>
        </Grid>
      </Grid>
      <BlendTabsGroup
        sx={{ marginBottom: '20px' }}
        value={tabState}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="fullWidth"
      >
        <BlendTab label="Linked Referrals" />
        <BlendTab label="Linked Coachees" />
        <BlendTab label="Linked Sessions" />
      </BlendTabsGroup>
      {
        {
          0: (
            <LinkedReferrals
              contract={contract}
              expired={contractStatus == ContractStatus.expired || contractStatus == ContractStatus.something_wrong}
            />
          ),
          1: <LinkedCoachees contract={contract} />,
          2: (
            <LinkedSessions
              contract={contract}
              expired={contractStatus == ContractStatus.expired || contractStatus == ContractStatus.something_wrong}
            />
          ),
        }[tabState]
      }
    </Box>
  )
}
