import { Button, CircularProgress, Collapse, Grid, IconButton, styled, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Table } from '../../../components/Table/table'
import { useUserContext } from '../../../providers/User'
import theme from '../../../theme'
import { AttributeBody, AttributeType, Coach } from '../../../types/coach_types'
import { GetMatchingTableRes } from '../../../types/coacheeQuestionnaire_type'
import { BlendTickIcon, BlendViewIcon } from '../../../components/Common/Icons'
import { BlendFullpageSpinner } from '../../../components/Common/Spinner'
import {
  BlendPrimaryButton,
  BlendSecondaryButton,
  BlendSmallPrimaryButton,
  BlendSmallSecondaryButton,
} from '../../../components/Common/Buttons'
import { useDialogContext } from '../../../providers/Dialog'
import { getMatchingTable, putCoacheeMatch } from '../../../queries'
import { BlendAttributeButton, BlendSecondaryAttributeButton } from '../../../components/Coach'
import { Cell } from 'react-table'
import { BlendDivider } from '../../../components/Common/Divider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'

interface TableProps {
  coachId: string
  coachName: string
  attributes: AttributeBody[] | undefined
}

const colors = [
  theme.multiColor.blue,
  theme.multiColor.teal,
  theme.multiColor.lightGreen,
  theme.multiColor.darkTeal,
  theme.multiColor.dullBlue,
  theme.multiColor.lightBlue,
  theme.multiColor.green,
]

const attributesCell = (attributes: AttributeBody[]) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box>
      {Object.values(AttributeType).flatMap((title, index) =>
        (title === 'Professional experience' || title === 'Coaching style') &&
        attributes.some((attribute) => Object.keys(AttributeType).indexOf(attribute.type) === index) ? (
          <Box sx={{ marginBottom: '5px' }}>
            <Typography variant="caption">{title}</Typography>
            <Grid container spacing="10px">
              {attributes
                .filter(
                  (attribute) => Object.keys(AttributeType).indexOf(attribute.type) === index && !!!attribute.isCustom,
                )
                .sort((a, b) => a.text.localeCompare(b.text))
                .map((attribute, attrindex) => (
                  <Grid item key={`${title}-${attrindex}`}>
                    <BlendAttributeButton disabled primaryColor={colors[index]}>
                      {attribute.text}
                    </BlendAttributeButton>
                  </Grid>
                ))
                .concat(
                  attributes
                    .filter(
                      (attribute) =>
                        Object.keys(AttributeType).indexOf(attribute.type) === index && !!attribute.isCustom,
                    )
                    .sort((a, b) => a.text.localeCompare(b.text))
                    .map((attribute, attrindex) => (
                      <Grid item key={`${title}-${attrindex}`}>
                        <BlendSecondaryAttributeButton disabled primaryColor={colors[index]}>
                          {attribute.text}
                        </BlendSecondaryAttributeButton>
                      </Grid>
                    )),
                )}
            </Grid>
          </Box>
        ) : null,
      )}
      <BlendDivider sx={{ paddingTop: '10px', marginBottom: '10px' }} />
      <BlendSmallSecondaryButton
        startIcon={<FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} />}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? 'Close characteristics' : 'Show extra characteristics'}
      </BlendSmallSecondaryButton>
      <Collapse in={isOpen}>
        <Box sx={{ marginTop: '20px' }}>
          {Object.values(AttributeType).flatMap((title, index) =>
            title !== 'Professional experience' &&
            title !== 'Coaching style' &&
            attributes.some((attribute) => Object.keys(AttributeType).indexOf(attribute.type) === index) ? (
              <Box sx={{ marginBottom: '5px' }}>
                <Typography variant="caption">{title}</Typography>
                <Grid container spacing="10px">
                  {attributes
                    .filter(
                      (attribute) =>
                        Object.keys(AttributeType).indexOf(attribute.type) === index && !!!attribute.isCustom,
                    )
                    .sort((a, b) => a.text.localeCompare(b.text))
                    .map((attribute, attrindex) => (
                      <Grid item key={`${title}-${attrindex}`}>
                        <BlendAttributeButton disabled primaryColor={colors[index]}>
                          {attribute.text}
                        </BlendAttributeButton>
                      </Grid>
                    ))
                    .concat(
                      attributes
                        .filter(
                          (attribute) =>
                            Object.keys(AttributeType).indexOf(attribute.type) === index && !!attribute.isCustom,
                        )
                        .sort((a, b) => a.text.localeCompare(b.text))
                        .map((attribute, attrindex) => (
                          <Grid item key={`${title}-${attrindex}`}>
                            <BlendSecondaryAttributeButton disabled primaryColor={colors[index]}>
                              {attribute.text}
                            </BlendSecondaryAttributeButton>
                          </Grid>
                        )),
                    )}
                </Grid>
              </Box>
            ) : null,
          )}
        </Box>
      </Collapse>
    </Box>
  )
}

export const MatchingTable: React.FC = () => {
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  const id: string | null = urlParams.get('id')
  const context = useUserContext()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const dialog = useDialogContext()
  const [selectedCoachState, setSelectedCoachState] = useState<Partial<Coach>>()

  const getCoacheeName = (data: GetMatchingTableRes) => {
    const contact = data.coacheeContacts.filter((contact) => contact.isDelegate)[0]
    if (contact) return `${contact.firstName} ${contact.lastName}`
    else return `${data.coacheeContacts[0].firstName} ${data.coacheeContacts[0].lastName}`
  }

  const { isFetching, error, data } = useQuery<GetMatchingTableRes>({
    queryFn: () => getMatchingTable(context, id),
    queryKey: ['getMatchingTableData', id],
    enabled: !!context.token,
    refetchOnMount: true,
  })

  const mutation = useMutation<unknown, Error>({
    mutationFn: () => putCoacheeMatch(context, data?.coachee.id, selectedCoachState?.id),
    onError: async (error) => {
      await dialog.fetchErrorDialog(JSON.parse(error.message))
    },
    onSuccess: async () => {
      await dialog.openDialog(['Match was successful!'], 2)
      await queryClient.invalidateQueries(['getCoacheeData', data?.coachee.id])
      navigate(`/coachees/profile?id=${data?.coachee.id}`)
    },
  })

  const tableData: TableProps[] | undefined = useMemo<TableProps[] | undefined>(() => {
    return data
      ? data.coaches.map<TableProps>((coach) => {
          return {
            coachId: coach.id ?? 'N/A',
            coachName: `${coach.firstName} ${coach.lastName}`,
            attributes: coach.attributes,
          }
        })
      : undefined
  }, [data, selectedCoachState])

  const columns = useMemo(
    () => [
      {
        Header: 'Coaches',
        columns: [
          {
            Header: 'Coach',
            id: 'coachName',
            accessor: 'coachName',
            Cell: ({ row }: Cell<TableProps>) => (
              <Box sx={{ width: '200px' }}>
                <Typography sx={{ fontSize: '12pt' }}>{row.original.coachName}</Typography>
              </Box>
            ),
          },
          {
            Header: 'Matching characteristics',
            id: 'attributes',
            accessor: 'attributes',
            Cell: ({ row }: Cell<TableProps>) =>
              row.original.attributes ? attributesCell(row.original.attributes) : null,
          },
          {
            Header: '',
            id: 'actions',
            accessor: 'coachId',
            Cell: ({ row }: Cell<TableProps>) => {
              return (
                <Box sx={{ float: 'right', width: '80px' }}>
                  <IconButton
                    onClick={() => {
                      setSelectedCoachState(data?.coaches.filter((coach) => coach.id === row.values.actions)[0])
                    }}
                  >
                    <BlendTickIcon color={'green'} />
                  </IconButton>
                  <IconButton onClick={() => navigate(`/coaches/profile?id=${row.values.actions}`)}>
                    <BlendViewIcon />
                  </IconButton>
                </Box>
              )
            },
          },
        ],
      },
    ],
    [data?.coaches],
  )

  return data && !isFetching ? (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant="h2">{getCoacheeName(data)}</Typography>
          <Typography variant="body1">Suitable coach matches</Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h4">
          Selected Coach:{' '}
          {selectedCoachState ? `${selectedCoachState?.firstName} ${selectedCoachState?.lastName}` : 'None'}
        </Typography>
        {selectedCoachState && (
          <BlendPrimaryButton sx={{ marginTop: '20px' }} onClick={() => mutation.mutate()}>
            Confirm Selection
          </BlendPrimaryButton>
        )}
      </Box>
      {error ? <h1>Could not fetch Coaches</h1> : null}
      {!error && tableData && !tableData.length ? <h1>There are no Coaches</h1> : null}
      {!isFetching && tableData && tableData.length ? (
        <Table columns={columns} data={tableData} searchLabel="Search Coaches" />
      ) : !error && !data ? (
        <BlendFullpageSpinner />
      ) : null}
    </Box>
  ) : (
    <BlendFullpageSpinner />
  )
}
