import { Box, Grid, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { BlendCheckbox } from '../../../../../components/Common/Checkbox'
import { BlendDivider } from '../../../../../components/Common/Divider'
import { useDateString, useIsWithinDate } from '../../../../../hooks/useDateTime'
import { Coach } from '../../../../../types/coach_types'

export const ViewLegalInsurance: React.FC<Coach> = (props) => {
  return (
    <Box>
      <Typography variant={'h6'}>Associate Contract</Typography>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ paddingTop: 2 }}
        sm={12}
        lg={8}
        xl={6}
      >
        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Box sx={{ display: 'inline-flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
            <Typography>Associate contract signed</Typography>
            <BlendCheckbox
              sx={{ paddingLeft: '24px' }}
              disabled
              checked={useIsWithinDate(props.associateContractSigned, props.associateContractExpiry)}
            />
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Box>
            <Typography fontWeight={'fontWeightBold'}>Date signed</Typography>
            <Typography sx={{ paddingTop: '10px' }}>{useDateString(props.associateContractSigned)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Box>
            <Typography fontWeight={'fontWeightBold'}>Expiry date</Typography>
            <Typography sx={{ paddingTop: '10px' }}>{useDateString(props.associateContractExpiry)}</Typography>
          </Box>
        </Grid>
      </Grid>
      <BlendDivider sx={{ paddingTop: '20px', marginBottom: '20px' }} />
      <Typography variant={'h6'}>Data Protection Agreement</Typography>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ paddingTop: 2 }}
        sm={12}
        lg={8}
        xl={6}
      >
        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Box sx={{ display: 'inline-flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
            <Typography>Data protection agreement signed</Typography>
            <BlendCheckbox
              sx={{ paddingLeft: '24px' }}
              disabled
              checked={useIsWithinDate(props.dataProtectionAgreementSigned, props.dataProtectionAgreementExpiry)}
            />
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Box>
            <Typography fontWeight={'fontWeightBold'}>Date signed</Typography>
            <Typography sx={{ paddingTop: '10px' }}>{useDateString(props.dataProtectionAgreementSigned)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Box>
            <Typography fontWeight={'fontWeightBold'}>Expiry date</Typography>
            <Typography sx={{ paddingTop: '10px' }}>{useDateString(props.dataProtectionAgreementExpiry)}</Typography>
          </Box>
        </Grid>
      </Grid>
      <BlendDivider sx={{ paddingTop: '20px', marginBottom: '20px' }} />
      <Typography variant={'h6'}>Identity Check</Typography>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ paddingTop: '20px' }}
        sm={12}
        lg={8}
        xl={6}
      >
        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Box sx={{ display: 'inline-flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
            <Typography>Identity check complete</Typography>
            <BlendCheckbox sx={{ paddingLeft: '24px' }} disabled checked={!!props.identityCheckSigned} />
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Box>
            <Typography fontWeight={'fontWeightBold'}>Date signed</Typography>
            <Typography sx={{ paddingTop: '10px' }}>{useDateString(props.identityCheckSigned)}</Typography>
          </Box>
        </Grid>
      </Grid>
      <BlendDivider sx={{ paddingTop: '20px', marginBottom: '20px' }} />
      <Typography variant={'h6'}>Insurance details</Typography>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ paddingTop: '20px' }}
        sm={12}
        lg={8}
        xl={6}
      >
        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
          <Box>
            <Typography fontWeight={'fontWeightBold'}>Insurance provider</Typography>
            <Typography sx={{ paddingTop: '10px' }}>
              {props.insuranceProvider ? props.insuranceProvider : 'N/A'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Box>
            <Typography fontWeight={'fontWeightBold'}>Insurance value</Typography>
            <Typography sx={{ paddingTop: '10px' }}>
              {props.insuranceValue ? `£${props.insuranceValue}` : 'N/A'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Box>
            <Typography fontWeight={'fontWeightBold'}>Expiry date</Typography>
            <Typography sx={{ paddingTop: '10px' }}>{useDateString(props.insuranceExpiry)}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
