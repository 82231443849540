import { Box, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { LinkedContracts } from './tabs/linkedContracts'
import { LinkedCoachees } from './tabs/linkedCoachees'
import { ClientProps } from '..'
import { useUserContext } from '../../../../providers/User'
import { useMutation, useQueryClient } from 'react-query'
import { BlendPrimaryButton, BlendSecondaryButton } from '../../../../components/Common/Buttons'
import { BlendTab, BlendTabsGroup } from '../../../../components/Common/Tabs'
import { BlendBreadcrumbs } from '../../../../components/Common/Breadcrumbs'
import { BlendContactCard } from '../../../../components/Contact'
import { BlendFullpageSpinner } from '../../../../components/Common/Spinner'
import { useDialogContext } from '../../../../providers/Dialog'
import { putIsActiveClient } from '../../../../queries'
import theme from '../../../../theme'

export const ViewClient: React.FC<ClientProps> = ({ client, callback }) => {
  const context = useUserContext()
  const queryClient = useQueryClient()
  const dialog = useDialogContext()
  const [tabState, setTabState] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabState(newValue)
  }

  const mutation = useMutation<unknown, Error>({
    mutationFn: () => putIsActiveClient(context, client?.id ?? null, !client?.isActive),
    onError: async (error) => {
      await dialog.fetchErrorDialog(JSON.parse(error.message))
    },
    onSuccess: async () => {
      if (client) await queryClient.refetchQueries(['getClientData', client.id])
      await queryClient.invalidateQueries('getAllClientsData')
    },
  })

  const handleSetActive = () => {
    dialog.openConfirmDialog(
      `Are you sure you want to set the client to ${client?.isActive ? 'inactive' : 'active'}?`,
      async () => {
        mutation.mutateAsync()
      },
    )
  }

  return client ? (
    <Box pb="50px">
      <BlendBreadcrumbs breadcrumbs={[{ label: 'Client Manager', link: '/clients' }, { label: client.name }]} />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', margin: '20px 20px 20px 0' }}>
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Typography variant="h2" display="flex">
              {client.name}
            </Typography>
            <Typography
              variant="body1"
              color={client.isActive ? theme.blend.green : theme.blend.error}
              display="flex"
              ml="40px"
            >
              {client.isActive ? 'Active' : 'Inactive'}
            </Typography>
          </Box>
          <Typography variant="h6" color={theme.blend.lightGrey}>
            {client.parentClient?.name ?? ''}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', direction: 'row' }}>
          <BlendPrimaryButton onClick={callback}>Edit</BlendPrimaryButton>
          <BlendSecondaryButton sx={{ marginLeft: '10px' }} onClick={handleSetActive}>
            {client.isActive ? 'Make Inactive' : 'Make Active'}
          </BlendSecondaryButton>
        </Box>
      </Box>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {client.contacts
          ?.sort((a, b) => (a.isPrimary ? -1 : 1))
          .map((contact) => {
            return (
              <Grid key={contact.id} item sm={6} md={3}>
                <BlendContactCard
                  bgcolor={contact.isPrimary ? 'rgba(23, 99, 175, 0.15)' : 'rgba(0, 167, 157, 0.15)'}
                  name={`${contact.firstName} ${contact.lastName}`}
                  email={contact.email ?? ''}
                  mobile={contact.mobile ?? ''}
                />
              </Grid>
            )
          })}
      </Grid>
      <BlendTabsGroup value={tabState} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
        <BlendTab label="Linked Contracts" />
        <BlendTab label="Linked Coachees" />
      </BlendTabsGroup>
      {
        {
          0: <LinkedContracts {...client} />,
          1: <LinkedCoachees {...client} />,
        }[tabState]
      }
    </Box>
  ) : (
    <BlendFullpageSpinner />
  )
}
