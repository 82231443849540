import { Coach } from '../types/coach_types'
import { isAfterYear, isEmail, isGreaterThan, isGreaterThanZero, isRequired } from './validations'

export const coachValidator = (coach: Coach) => {
  const errors: string[] = []
  errors.push(isRequired('First name', coach.firstName))
  errors.push(isRequired('Last name', coach.lastName))
  errors.push(isRequired('Email', coach.email))
  errors.push(isEmail('Email', coach.email))
  errors.push(isRequired('Gender', coach.gender))
  errors.push(isRequired('Ethnicity', coach.ethnicity))
  errors.push(isRequired('Age range', coach.ageRange))
  errors.push(isRequired('Disability', coach.disabled))
  errors.push(isRequired('Caring responsibilities', coach.caringResponsibilities))
  errors.push(isAfterYear('Associate contract expiry', coach.associateContractExpiry, 2010))
  errors.push(isGreaterThan('Associate contract dates', coach.associateContractSigned, coach.associateContractExpiry))
  errors.push(isAfterYear('Identity check signed', coach.identityCheckSigned, 2010))
  errors.push(isGreaterThanZero('Insurance value', coach.insuranceValue))
  errors.push(isAfterYear('Insurance expiry', coach.insuranceExpiry, 2010))

  return errors.filter((error) => error.length > 1)
}
