import { Box } from '@mui/material'
import React from 'react'
import { SignInButton } from './signInButton'

export const Authentication: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        component="img"
        sx={{
          height: 108,
          width: 108,
        }}
        alt="Blend Logo"
        src="/blendLogo.png"
      />
      <SignInButton />
    </Box>
  )
}
