import { Grid } from '@mui/material'
import React from 'react'
import { BlendTextField } from '../../../../components/Common/TextField'

interface ContractProps {
  clientName?: string
  contractHandle?: string
  status?: number
}

export const ContractView: React.FC<ContractProps> = (props) => {
  return (
    <Grid container direction="row" pb="39px">
      <Grid item md={4} pr="11px">
        <BlendTextField
          disabled
          label="Client"
          placeholder="Client"
          name="client"
          width="100%"
          value={props.clientName}
        />
      </Grid>
      <Grid item md={4} pl="11px" pr="11px">
        <BlendTextField
          disabled
          label="Contract"
          placeholder="Contract"
          name="contract"
          width="100%"
          value={props.contractHandle}
        />
      </Grid>
      <Grid item md={4} pl="11px">
        <BlendTextField
          disabled
          label="Status"
          placeholder="Status"
          name="status"
          width="100%"
          value={(props.status ?? 0) > 1 ? 'Coachee' : 'Referral'}
        />
      </Grid>
    </Grid>
  )
}
