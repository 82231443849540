import { FilterParameterType, ReportProps } from '../types/report_types'

export interface ReportAction {
  type: 'SET_FILTER_PARAMETER' | 'SET_FILTERED' | 'SET_IS_LOADING'
  index?: number
  parameterType?: FilterParameterType
  payload: string | ReportProps[]
}

export const reportReducer = (state: ReportProps[], action: ReportAction): ReportProps[] => {
  switch (action.type) {
    case 'SET_FILTER_PARAMETER':
      const reports = [...state]
      if (action.index != undefined) {
        const report = {
          ...reports[action.index],
          filterParameterId: action.payload,
          filterParameterType: action.parameterType,
        }
        reports[action.index] = report as ReportProps
      }
      return [...reports]
    case 'SET_FILTERED':
      return [...(action.payload as ReportProps[])]
    case 'SET_IS_LOADING':
      const loadReports = [...state]
      if (action.index != undefined) {
        const report = {
          ...loadReports[action.index],
          isLoading: !loadReports[action.index].isLoading ?? true,
        }
        loadReports[action.index] = report as ReportProps
      }
      return [...loadReports]
    default:
      return [...state]
  }
}
